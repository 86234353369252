import {
  TccRequestStatus,
  IProductHier,
  IGlobalProduct,
  IColor,
  IMarketingMaterial,
  IDecorationTech,
  IDesign,
  IChannel,
  IFlavorScent,
  ILicensor,
  ILicensedProperty,
  ITccRegionComposition,
  ITccRequestHistory,
  IRequestStatusReason,
  ICountry,
  IMarket,
  IApprovalLevel,
} from "../../types/data.interface";
import { Action } from "../../types/state/action";

export type CreateTccState = {
  tccId: number | null;
  requestStatus: TccRequestStatus;
  requestId: string;
  revision: number;
  requestDate: string;
  requester: string;
  approvedDate: string;
  approver: string;
  currentApprover: string;
  currentApprovalLevel: IApprovalLevel | null;

  category: IProductHier | null;
  subCategory: IProductHier | null;
  productLine: IProductHier | null;
  globalProduct: IGlobalProduct | null;
  tccSuffix: string;
  tccName: string;

  productColor: IColor | null;
  materialMarketing: IMarketingMaterial | null;
  decorationTechnique: IDecorationTech | null;
  artwork: IDesign | null;
  channel: IChannel | null;
  pcsSellingUnits: number;
  consumerFacingName: string;
  flavorScent: IFlavorScent | null;
  licensor: ILicensor | null;
  licensedProperty: ILicensedProperty | null;
  productionLifecycleStatus: string;
  salesLifecycleStatus: string;
  upcCode: string;
  gtinCode: string;
  color1: IColor | null;
  color2: IColor | null;

  fromUseDate: string;
  toUseDate: string;
  tccReason: string;
  otherTccReason: string;
  markets: IMarket[];
  economicalQuantities: string;
  projectId: string;
  units: string;
  sales: string;
  profit: string;
  salesResults: string;

  requestorMarket: ICountry | null;

  tccRegionCompositions: ITccRegionComposition[];

  requestHistory: ITccRequestHistory[];

  submitComment: string;
  approveComment: string;
  rejectComment: string;
  rejectReason: IRequestStatusReason | null;

  hasUnsavedChanges: boolean;
};

export enum CreateTccActionTypes {
  SET_CATEGORY = "createTcc/setCategory",
  SET_SUB_CATEGORY = "createTcc/setSubCategory",
  SET_PRODUCT_LINE = "createTcc/setProductLine",
  SET_GLOBAL_PRODUCT = "createTcc/setGlobalProduct",
  SET_TCC_SUFFIX = "createTcc/setTccSuffix",
  SET_TCC_NAME = "createTcc/setTccName",
  SET_PRODUCT_COLOR = "createTcc/setProductColor",
  SET_MATERIAL_MARKETING = "createTcc/setMaterialMarketing",
  SET_DECORATION_TECHNIQUE = "createTcc/setDecorationTechnique",
  SET_ARTWORK = "createTcc/setArtwork",
  SET_CHANNEL = "createTcc/setChannel",
  SET_PCS_SELLING_UNITS = "createTcc/setPcsSellingUnits",
  SET_CONSUMER_FACING_NAME = "createTcc/setConsumerFacingName",
  SET_FLAVOR_SCENT = "createTcc/setFlavorScent",
  SET_LICENSOR = "createTcc/setLicensor",
  SET_LICENSED_PROPERTY = "createTcc/setLicensedProperty",
  SET_PRODUCTION_LIFECYCLE_STATUS = "createTcc/setProductionLifecycleStatus",
  SET_SALES_LIFECYCLE_STATUS = "createTcc/setSalesLifecycleStatus",
  SET_UPC_CODE = "createTcc/setUpcCode",
  SET_GTIN_CODE = "createTcc/setGtinCode",
  SET_COLOR1 = "createTcc/setColor1",
  SET_COLOR2 = "createTcc/setColor2",
  SET_FROM_USE_DATE = "createTcc/setFromUseDate",
  SET_TO_USE_DATE = "createTcc/setToUseDate",
  SET_TCC_REASON = "createTcc/setTccReason",
  SET_OTHER_TCC_REASON = "createTcc/setOtherTccReason",
  SET_MARKETS = "createTcc/setMarkets",
  SET_ECONOMICAL_QUANTITIES = "createTcc/setEconomicalQuantities",
  SET_UNITS = "createTcc/setUnits",
  SET_SALES = "createTcc/setSales",
  SET_PROFIT = "createTcc/setProfit",
  SET_SALES_RESULTS = "createTcc/setSalesResults",

  SET_REQUESTOR_MARKET = "createTcc/setRequestorMarket",

  SET_REQUEST_STATUS = "createTcc/setRequestStatus",
  SET_REQUEST_ID = "createTcc/setRequestId",
  SET_REVISION = "createTcc/setRevision",
  SET_REQUEST_DATE = "createTcc/setRequestDate",
  SET_REQUESTER = "createTcc/setRequester",
  SET_APPROVED_DATE = "createTcc/setApprovedDate",
  SET_APPROVER = "createTcc/setApprover",

  ADD_REGION_COMPOSITION = "createTcc/addRegionComposition",
  DELETE_REGION_COMPOSITION = "createTcc/deleteRegionComposition",
  UPDATE_REGION_COMPOSITION = "createTcc/updateRegionComposition",

  ADD_REQUEST_HISTORY = "createTcc/addRequestHistory",

  SET_SUBMIT_COMMENT = "createTcc/setSubmitComment",
  SET_APPROVE_COMMENT = "createTcc/setApproveComment",
  SET_REJECT_COMMENT = "createTcc/setRejectComment",
  SET_REJECT_REASON = "createTcc/setRejectReason",

  SET_REQUEST_SPECIFIC_DETAILS = "createTcc/setRequestSpecificDetails",
  SET_REQUEST_DATA = "createTcc/setRequestData",
  SET_REQUEST_DETAILS_AFTER_ACTION = "createTcc/setRequestDetailsAfterAction",
  RESET = "createTcc/reset",
  SET_UNSAVED_CHANGES = "createTcc/serUnsavedChanges",

  SET_TCC_ID = "createTcc/setTccId",
}

export type CreateTccActionType = Action<
  CreateTccActionTypes,
  Partial<CreateTccState>
>;
