import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./components/navigation/Router";
import { Predicates } from "libraries/predicates/predicates";

const VERSION = "version";

const lastVersion = localStorage.getItem(VERSION);

const currentVersion = process.env.REACT_APP_VERSION as string;

if (
  Predicates.isNotNullAndNotUndefined(currentVersion) &&
  lastVersion !== currentVersion
) {
  localStorage.clear();
  localStorage.setItem(VERSION, currentVersion);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  process.env.NODE_ENV === "development" ? (
    <React.StrictMode>
      <Router />
    </React.StrictMode>
  ) : (
    <Router />
  ),
);
