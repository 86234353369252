import { useContext } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import OptionFormatter from "../../../../components/OptionFormatter";
import { useColorContext } from "../../../../context/ColorContext";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { useDecorationTechContext } from "../../../../context/DecorationTechContext";
import { useMaterialContext } from "../../../../context/MaterialContext";
import { useMoldContext } from "../../../../context/MoldContext";
import { useNonMoldContext } from "../../../../context/NonMoldContext";
import {
  componentTypeOptions,
  filterNonDigits,
  formatComponentTypeIntoOption,
} from "../../../../helpers";
import { getColors } from "../../../../helpers/color.helper";
import { getDecorTechs } from "../../../../helpers/decor-tech.helper";
import { getDesigns } from "../../../../helpers/design.helper";
import { getMaterials } from "../../../../helpers/material.helper";
import { getMolds } from "../../../../helpers/mold.helper";
import { fetchColor } from "../../../../hooks/useColor";
import useDesign, { fetchDesign } from "../../../../hooks/useDesign";
import { fetchMaterial } from "../../../../hooks/useMaterial";
import { fetchMold } from "../../../../hooks/useMold";
import { fetchNonMold } from "../../../../hooks/useNonMold";
import { Predicates } from "../../../../libraries/predicates/predicates";
import {
  IColor,
  ICompositionComponent,
  IDecorationTech,
  IDesign,
  IMold,
  ISkuComposition,
  ITccComposition,
  TccCompositionRegionInfo,
} from "../../../../types/data.interface";
import useAxios from "../../../../utils/useAxios";
import {
  checkComponentIsValid,
  checkIfNewComponentAlreadyExists,
  formatIntoOption,
  getUniqueItemsByProperties,
} from "../../features/sku-create/sku-create.helper";

const AddEditSkuCompositionComponentForm = ({
  handleAddComponents,
  component,
  setComponent,
  savedSkuComposition,
  selectedTccCompositionRegion,
  tccComposition,
}: {
  handleAddComponents: (
    moldedComponents: ICompositionComponent[],
    nonMoldedComponents: ICompositionComponent[],
    nodeId: number,
    isNewComponent: boolean,
  ) => void;
  component: ICompositionComponent | null;
  setComponent: any;
  savedSkuComposition: ISkuComposition;
  selectedTccCompositionRegion: TccCompositionRegionInfo | null;
  tccComposition: ITccComposition[];
}) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);

  const { data: molds, isLoading: isMoldsLoading } = useMoldContext();
  const { data: nonMolds, isLoading: isNonMoldsLoading } = useNonMoldContext();
  const { data: materials, isLoading: isMaterialsLoading } =
    useMaterialContext();
  const { data: colors, isLoading: isColorsLoading } = useColorContext();
  const { data: decorationTechs, isLoading: isDecoTechsLoading } =
    useDecorationTechContext();
  const { data: artworks, isLoading: isArtworksLoading } = useDesign({
    decorationTechniqueId: String(component?.decoration_technique_id ?? ""),
  });

  const handleComponentType = (e: any) => {
    setComponent({
      ...component,
      type: e.value,
      mold_id: null,
      mold_description: null,
      is_tps: null,
    });
  };

  const handleMoldChange = (e: any) => {
    setComponent({
      ...component,
      mold_id: e?.value.id ?? null,
      mold_description: e?.label ?? null,
    });
  };

  const loadMoldOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchMold({
      search,
      axios,
    });

    callback(getMolds(possiblyRestrictedMolds(response, true)));
  };

  const possiblyRestrictedMolds = (
    molds: IMold[],
    isSearch: boolean = false,
  ) => {
    const tccCompositionMolds: IMold[] = tccComposition
      .map((composition) => composition.mold)
      .filter(Predicates.isNotNullAndNotUndefined)
      .sort((mold1, mold2) => Number(mold1.id) - Number(mold2.id));

    if (
      selectedTccCompositionRegion?.is_restrictive &&
      tccCompositionMolds.length > 0
    ) {
      if (!isSearch) {
        return getUniqueItemsByProperties(tccCompositionMolds, ["id"]);
      } else {
        const moldIds: string[] = tccCompositionMolds.map((mold) => mold.id);
        return molds.filter((mold) => moldIds.includes(mold.id));
      }
    } else {
      return molds;
    }
  };

  const loadNonMoldOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchNonMold({
      search,
      axios,
    });

    callback(getMolds(possiblyRestrictedNonMolds(response, true)));
  };

  const possiblyRestrictedNonMolds = (
    nonMolds: IMold[],
    isSearch: boolean = false,
  ) => {
    const tccCompositionNonMolds: IMold[] = tccComposition
      .map((composition) => composition.nonMold)
      .filter(Predicates.isNotNullAndNotUndefined)
      .sort((nonMold1, nonMold2) => Number(nonMold1.id) - Number(nonMold2.id));

    if (
      selectedTccCompositionRegion?.is_restrictive &&
      tccCompositionNonMolds.length > 0
    ) {
      if (!isSearch) {
        return getUniqueItemsByProperties(tccCompositionNonMolds, ["id"]);
      } else {
        const nonMoldIds: string[] = tccCompositionNonMolds.map(
          (nonMold) => nonMold.id,
        );
        return nonMolds.filter((nonMold) => nonMoldIds.includes(nonMold.id));
      }
    } else {
      return nonMolds;
    }
  };

  const handleMaterialChange = (e: any) => {
    setComponent({
      ...component,
      material_id: e?.value.id ?? null,
      material_description: e?.label ?? null,
    });
  };

  const loadMaterialOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchMaterial({
      search,
      axios,
    });

    callback(getMaterials(response));
  };

  const handleColorChange = (e: any) => {
    setComponent({
      ...component,
      color_id: e?.value.id ?? null,
      color_description: e?.label ?? null,
    });
  };

  const loadColorOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchColor({
      search,
      axios,
    });

    callback(getColors(possiblyRestrictedColors(response, true)));
  };

  const possiblyRestrictedColors = (
    colors: IColor[],
    isSearch: boolean = false,
  ) => {
    const tccCompositionColors: IColor[] = tccComposition
      .map((composition) => composition.color)
      .filter(Predicates.isNotNullAndNotUndefined)
      .sort((color1, color2) => Number(color1.id) - Number(color2.id));

    if (
      selectedTccCompositionRegion?.is_restrictive &&
      tccCompositionColors.length > 0
    ) {
      if (!isSearch) {
        return getUniqueItemsByProperties(tccCompositionColors, ["id"]);
      } else {
        const colorIds: string[] = tccCompositionColors.map(
          (color) => color.id,
        );
        return colors.filter((color) => colorIds.includes(color.id));
      }
    } else {
      return colors;
    }
  };

  const handleDecorationTechniqueChange = (e: any) => {
    setComponent({
      ...component,
      decoration_technique_id: e?.value.id ?? null,
      decoration_technique_description: e?.label ?? null,
      artwork_id: null,
      artwork_description: null,
    });
  };

  const possiblyRestrictedDecorTechs = (decorTechs: IDecorationTech[]) => {
    const tccCompositionDecor: IDecorationTech[] = tccComposition
      .map((composition) => composition.decorTech)
      .filter(Predicates.isNotNullAndNotUndefined)
      .sort((deco1, deco2) => Number(deco1.id) - Number(deco2.id));

    if (
      selectedTccCompositionRegion?.is_restrictive &&
      tccCompositionDecor.length > 0
    ) {
      return getUniqueItemsByProperties(tccCompositionDecor, ["id"]);
    } else {
      return decorTechs;
    }
  };

  const handleArtworkChange = (e: any) => {
    setComponent({
      ...component,
      artwork_id: e?.value.id ?? null,
      artwork_description: e?.label ?? null,
    });
  };

  const loadDesignOptions = async (search: string, callback: any) => {
    if (
      !component?.decoration_technique_id &&
      (Predicates.isNullOrUndefined(search) || search.length < 3)
    )
      return [];
    const response = await fetchDesign({
      decorationTechniqueId: String(component?.decoration_technique_id),
      search,
      axios,
    });

    callback(getDesigns(possiblyRestrictedDesigns(response, true)));
  };

  const possiblyRestrictedDesigns = (
    designs: IDesign[],
    isSearch: boolean = false,
  ) => {
    const tccCompositionArtworks: IDesign[] = tccComposition
      .map((composition) => composition.artwork)
      .filter(Predicates.isNotNullAndNotUndefined)
      .filter(
        (artwork) =>
          artwork.deco_tech_id ===
          String(component?.decoration_technique_id ?? -1),
      )
      .sort((artwork1, artwork2) => Number(artwork1.id) - Number(artwork2.id));

    if (
      selectedTccCompositionRegion?.is_restrictive &&
      tccCompositionArtworks.length > 0
    ) {
      if (!isSearch) {
        return getUniqueItemsByProperties(tccCompositionArtworks, [
          "unique_id",
        ]);
      } else {
        const arworkIds: number[] = tccCompositionArtworks.map(
          (artwork) => artwork.unique_id,
        );
        return designs.filter((artwork) =>
          arworkIds.includes(artwork.unique_id),
        );
      }
    } else {
      return designs;
    }
  };

  const handlePiecesChange = (e: any) => {
    setComponent({
      ...component,
      nr_pieces:
        filterNonDigits(e.target.value).length > 0
          ? parseInt(filterNonDigits(e.target.value))
          : undefined,
    });
  };

  const handleIndexChange = (e: any) => {
    setComponent({
      ...component,
      mold_index:
        filterNonDigits(e.target.value).length > 0
          ? parseInt(filterNonDigits(e.target.value))
          : undefined,
    });
  };

  const handleTpsChange = (e: any) => {
    setComponent({ ...component, is_tps: e.target.checked });
  };

  const moldIndexIsUnique = (isNewComponent: boolean) => {
    return !isNewComponent && component
      ? !savedSkuComposition.non_molded_components.some(
          (nonMold) =>
            nonMold.node_id !== component.node_id &&
            nonMold.mold_index === component.mold_index,
        ) &&
          !savedSkuComposition.molded_components.some(
            (mold) =>
              mold.node_id !== component.node_id &&
              mold.mold_index === component.mold_index,
          )
      : !savedSkuComposition.molded_components
          .map((mold) => mold.mold_index)
          .includes(component?.mold_index) &&
          !savedSkuComposition.non_molded_components
            .map((nonMold) => nonMold.mold_index)
            .includes(component?.mold_index);
  };

  const saveComponent = (isNew: boolean) => {
    if (
      Predicates.isNotNullAndNotUndefined(component?.mold_index) &&
      !moldIndexIsUnique(isNew)
    ) {
      toast.error(`Mold Index ${component?.mold_index} is already in use.`);
      return;
    }
    if (
      component &&
      (checkIfNewComponentAlreadyExists(
        component,
        savedSkuComposition.molded_components,
        isNew,
      ) ||
        checkIfNewComponentAlreadyExists(
          component,
          savedSkuComposition.non_molded_components,
          isNew,
        ))
    ) {
      toast.error(
        `You're trying to add a ${component.type === "NonMolded" ? "Non-Molded" : "Molded"} component that already exists. Please change something on the new component or alter the quantity of the existing one`,
      );
      return;
    }
    const nodeId = component?.node_id && !isNew ? component.node_id : -1;
    if (component?.type === "NonMolded") {
      handleAddComponents(
        [],
        [{ ...component, quantity: 1, type: "NonMolded", node_id: nodeId }],
        nodeId,
        isNew,
      );
    } else if (component?.type === "Molded") {
      handleAddComponents(
        [{ ...component, quantity: 1, type: "Molded", node_id: nodeId }],
        [],
        nodeId,
        isNew,
      );
    }
    if (isNew) {
      setComponent({ ...component, node_id: -1 });
    } else {
      cleanAllFields();
    }
  };

  const cleanAllFields = () => {
    setComponent(null);
  };

  return (
    <div className="row mb-2">
      <div className="col-md-3 form-group">
        <label className="form-label">
          Type <span className="red-text fw-bold">*</span>
        </label>
        <Select
          options={componentTypeOptions}
          onChange={handleComponentType}
          value={formatComponentTypeIntoOption(component?.type ?? "")}
          placeholder=""
          formatOptionLabel={OptionFormatter}
          classNamePrefix="react-select"
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>

      <div className="col-md-3 form-group">
        <label className="form-label">
          {component?.type === "NonMolded" ? "Sequence" : "Mold"}{" "}
          <span className="red-text fw-bold">*</span>
        </label>
        <AsyncSelect
          cacheOptions
          loadOptions={(input, callback) => {
            if (component?.type === "NonMolded") {
              searchDebounce(
                loadNonMoldOptions,
                input,
                getMolds(possiblyRestrictedNonMolds(nonMolds)),
                callback,
              );
            } else {
              searchDebounce(
                loadMoldOptions,
                input,
                getMolds(possiblyRestrictedMolds(molds)),
                callback,
              );
            }
          }}
          defaultOptions={getMolds(
            component?.type === "NonMolded"
              ? possiblyRestrictedNonMolds(nonMolds)
              : possiblyRestrictedMolds(molds),
          )}
          onChange={handleMoldChange}
          value={formatIntoOption(
            component?.mold_id,
            component?.mold_description ?? "",
          )}
          placeholder="(min 3 characters)"
          formatOptionLabel={OptionFormatter}
          isLoading={isMoldsLoading || isNonMoldsLoading}
          classNamePrefix="react-select"
          isClearable
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>

      <div className="col-md-3 form-group">
        <label className="form-label">
          Material <span className="red-text fw-bold">*</span>
        </label>
        <AsyncSelect
          cacheOptions
          loadOptions={(input, callback) => {
            searchDebounce(
              loadMaterialOptions,
              input,
              getMaterials(materials),
              callback,
            );
          }}
          defaultOptions={getMaterials(materials)}
          onChange={handleMaterialChange}
          value={formatIntoOption(
            component?.material_id,
            component?.material_description ?? "",
          )}
          placeholder="(min 3 characters)"
          formatOptionLabel={OptionFormatter}
          classNamePrefix="react-select"
          isClearable
          isLoading={isMaterialsLoading}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>

      <div className="col-md-3 form-group">
        <label className="form-label">
          Color <span className="red-text fw-bold">*</span>
        </label>
        <AsyncSelect
          cacheOptions
          loadOptions={(input, callback) => {
            searchDebounce(
              loadColorOptions,
              input,
              getColors(possiblyRestrictedColors(colors)),
              callback,
            );
          }}
          defaultOptions={getColors(possiblyRestrictedColors(colors))}
          onChange={handleColorChange}
          value={formatIntoOption(
            component?.color_id,
            component?.color_description ?? "",
          )}
          placeholder="(min 3 characters)"
          formatOptionLabel={OptionFormatter}
          classNamePrefix="react-select"
          isClearable
          isLoading={isColorsLoading}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>

      <div className="col-md-3 form-group">
        <label className="form-label">Decoration Technique</label>
        <Select
          options={getDecorTechs(possiblyRestrictedDecorTechs(decorationTechs))}
          onChange={handleDecorationTechniqueChange}
          value={formatIntoOption(
            component?.decoration_technique_id,
            component?.decoration_technique_description ?? "",
          )}
          placeholder=""
          formatOptionLabel={OptionFormatter}
          classNamePrefix="react-select"
          isLoading={isDecoTechsLoading}
          isClearable
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>

      <div className="col-md-3 form-group">
        <label className="form-label">Artwork</label>
        <AsyncSelect
          isDisabled={
            !component?.decoration_technique_id ||
            component?.decoration_technique_id <= 0
          }
          loadOptions={(input, callback) => {
            searchDebounce(
              loadDesignOptions,
              input,
              getDesigns(possiblyRestrictedDesigns(artworks)),
              callback,
            );
          }}
          defaultOptions={getDesigns(possiblyRestrictedDesigns(artworks))}
          onChange={handleArtworkChange}
          value={formatIntoOption(
            component?.artwork_id,
            component?.artwork_description ?? "",
          )}
          placeholder="(min 3 characters)"
          formatOptionLabel={OptionFormatter}
          isLoading={isArtworksLoading}
          classNamePrefix="react-select"
          isClearable
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>

      <div className="col-1 form-group">
        <label className="form-label">
          Pcs/Sets <span className="red-text fw-bold">*</span>
        </label>
        <input
          value={component?.nr_pieces ?? ""}
          onChange={handlePiecesChange}
          className="form-control"
          type="text"
        />
      </div>

      <div className="col-1 form-group">
        <label className="form-label">Mold Index</label>
        <input
          value={component?.mold_index ?? ""}
          onChange={handleIndexChange}
          className="form-control"
          type="text"
        />
      </div>

      {component?.type !== "NonMolded" ? (
        <div className="col-md-1 form-group d-flex flex-column align-items-center">
          <label className="form-label">TPS</label>
          <div className="fixed-checkbox-height">
            <input
              onChange={handleTpsChange}
              name="discontinued"
              checked={component?.is_tps}
              type="checkbox"
              className="checkbox"
            />
          </div>
        </div>
      ) : (
        <div className="col-md-1"></div>
      )}
      <div className="col-md-3 form-group align-self-end text-end pr-1">
        <input
          type="submit"
          className="btn btn-primary me-3"
          onClick={() => {
            saveComponent(false);
          }}
          disabled={
            !checkComponentIsValid(component) ||
            Predicates.isNullOrUndefined(component?.node_id) ||
            (component?.node_id ?? 0) <= 0
          }
          value={"Save Edit"}
        />
        <input
          type="submit"
          className="btn btn-primary"
          onClick={() => {
            saveComponent(true);
          }}
          disabled={!checkComponentIsValid(component)}
          value={"Add New"}
        />
      </div>
    </div>
  );
};

export default AddEditSkuCompositionComponentForm;
