import { emptyValueFormat } from "../../helpers";

export const PipPackingInformation = ({ data }: { data: any }) => {
  return (
    <>
      <div className="py-2 px-2 bg-white">
        <div className="card shadow-sm bg-white rounded table-responsive mb-2">
          <div className="table-title fw-bold py-1 pl-3">
            Primary Packing Materials
          </div>

          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th scope="col">Material Description</th>
                <th scope="col">WW Code</th>
                <th scope="col">Quantity</th>
                <th scope="col">Sub-Type</th>
                <th scope="col">Sustainable?</th>
                <th scope="col">Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {!!data?.primary_packing_materials &&
              data?.primary_packing_materials.length > 0 ? (
                data?.primary_packing_materials.map(
                  (material_info: any, index: number) => {
                    return (
                      <tr>
                        <td>
                          {material_info.material.id},{" "}
                          {material_info.material.description}
                        </td>
                        <td>
                          {emptyValueFormat(material_info.material.wwCode)}
                        </td>
                        <td>{emptyValueFormat(material_info.quantity)}</td>
                        <td>
                          {emptyValueFormat(material_info.material.subType)}
                        </td>
                        <td>
                          {emptyValueFormat(material_info.material.sustainable)}
                        </td>
                        <td>
                          {emptyValueFormat(material_info.material.netWeight)}
                        </td>
                      </tr>
                    );
                  },
                )
              ) : (
                <tr className="text-center fw-bold fst-italic">
                  <td colSpan={8}>No Primary Packing Materials Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="card shadow-sm bg-white rounded table-responsive mb-2">
          <div className="table-title fw-bold py-1 pl-3">
            Carton Information
          </div>

          <table className="table table-striped text-center">
            <thead>
              <tr>
                <th rowSpan={2}>Standard Pack</th>
                <th rowSpan={2}>Standard Pack Gross Weight (kg)*</th>
                <th rowSpan={2}>Standard Pack Net Weight (kg)</th>
                <th colSpan={3}>Standard Pack Size (mm)</th>
              </tr>
              <tr>
                <th>L</th>
                <th>W</th>
                <th>H</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              <tr>
                <td>{emptyValueFormat(data?.stdpk)}</td>
                <td>{emptyValueFormat(data?.stdpk_weight)}</td>
                <td>{emptyValueFormat(data?.stdpk_net_weight)}</td>
                <td>{emptyValueFormat(data?.stdpk_length)}</td>
                <td>{emptyValueFormat(data?.stdpk_width)}</td>
                <td>{emptyValueFormat(data?.stdpk_height)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="d-flex gap-2">
          <div className="card shadow-sm bg-white rounded table-responsive w-50">
            <div className="table-title fw-bold py-1 pl-3">
              Product Dimensions (mm)
            </div>

            <table className="table table-striped text-center">
              <thead>
                <tr>
                  <th scope="col">L</th>
                  <th scope="col">W</th>
                  <th scope="col">H</th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                <tr>
                  <td>{emptyValueFormat(data?.product_length)}</td>
                  <td>{emptyValueFormat(data?.product_width)}</td>
                  <td>{emptyValueFormat(data?.product_height)}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="card shadow-sm bg-white rounded table-responsive w-50">
            <div className="table-title fw-bold py-1 pl-3">
              Product Weights (g)
            </div>

            <table className="table table-striped text-center">
              <thead>
                <tr>
                  <th scope="col">Product Gross Weight</th>
                  <th scope="col">Product Net Weight</th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                <tr>
                  <td>{emptyValueFormat(data?.product_gross_weight)}</td>
                  <td>{emptyValueFormat(data?.product_net_weight)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
