import CharLimitCounter from "./CharLimitCounter";

const TextFieldWithLimit = ({
  label,
  limit,
  onChange,
  value,
  invalidMessage,
  spacing,
  isRequired,
}: {
  label: string;
  value: string;
  onChange: (item: any) => void;
  limit: number;
  invalidMessage: string;
  spacing: string;
  isRequired?: boolean;
}) => {
  const isInvalid: boolean = value.length > limit;
  return (
    <div className={`${spacing} form-group`}>
      <label className="form-label">
        {label}{" "}
        {isRequired ? <span className="red-text fw-bold">*</span> : null}
      </label>
      <textarea
        value={value}
        rows={1}
        className={`form-control ${isInvalid ? "is-invalid" : ""}`}
        onChange={onChange}
      />
      <div
        className={`d-flex justify-content-between ${isInvalid ? "invalid-feedback" : "only-counter-feedback"}`}
      >
        {isInvalid && `${invalidMessage}`}
        <CharLimitCounter currentValue={value} limit={limit} />
      </div>
    </div>
  );
};

export default TextFieldWithLimit;
