import { Predicates } from "../libraries/predicates/predicates";
import { ITCCReason } from "../types/data.interface";

export const formatTCCReasonIntoOption = (reason: ITCCReason | null) => {
  return Predicates.isNotNullAndNotUndefined(reason)
    ? { label: reason.name, value: reason.name }
    : null;
};

export const getTCCReasons = (reason: ITCCReason[]) =>
  reason
    .map(formatTCCReasonIntoOption)
    .filter(Predicates.isNotNullAndNotUndefined)
    .sort((a, b) => {
      if (a.value === "Other") return 1;
      if (b.value === "Other") return -1;
      return 0;
    });

export const findTCCReasonByName = (name: string, reasons: ITCCReason[]) => {
  return reasons.find((reason) => reason.name === name) || null;
};
