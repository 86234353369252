import {
  IProductHier,
  IGlobalProduct,
  IColor,
  IMarketingMaterial,
  IDecorationTech,
  IDesign,
  IChannel,
  IFlavorScent,
  ILicensor,
  ILicensedProperty,
  ITcc,
  IMold,
  IMaterial,
  IBrand,
  ISupplierCountry,
  IResPisTpsType,
  IBuyer,
} from "../../types/data.interface";
import { Action } from "../../types/state/action";

type ValueOrNull<T> = T | null;

export type Option<T> = { value: T; label: string };

export type PmsSearchState = {
  globalProduct: ValueOrNull<IGlobalProduct>;
  category: ValueOrNull<IProductHier>;
  subCategory: ValueOrNull<IProductHier>;
  productLine: ValueOrNull<IProductHier>;
  productNumber: string;
  productDescription: string;
  tcc: ValueOrNull<ITcc>;
  molds: ValueOrNull<IMold[]>;
  nonMolds: ValueOrNull<IMold[]>;
  materials: ValueOrNull<IMaterial[]>;
  colors: ValueOrNull<IColor[]>;
  decoTech: ValueOrNull<IDecorationTech>;
  artwork: ValueOrNull<IDesign>;
  productionLifecycleStatus: ValueOrNull<Option<string>>;
  salesLifecycleStatus: ValueOrNull<Option<string>>;
  linePlanningStatus: ValueOrNull<Option<string>>;

  alwaysOn: ValueOrNull<Option<string>>;
  brand: ValueOrNull<IBrand>;
  productCapacity: string;
  productCapacityUom: ValueOrNull<Option<string>>;
  gpProductionLifecycleStatus: ValueOrNull<Option<string>>;
  gpSalesLifecycleStatus: ValueOrNull<Option<string>>;
  gpLinePlanningStatus: ValueOrNull<Option<string>>;

  productColor: ValueOrNull<IColor>;
  marketingMaterial: ValueOrNull<IMarketingMaterial>;
  tccArtwork: ValueOrNull<IDesign>;
  channel: ValueOrNull<IChannel>;
  piecesSellingUnitsOperator: Option<string>;
  piecesSellingUnits: string;
  consumerFacingName: string;
  licensor: ValueOrNull<ILicensor>;
  licensedProperty: ValueOrNull<ILicensedProperty>;
  royaltyItem: ValueOrNull<Option<string>>;
  flavorScent: ValueOrNull<IFlavorScent>;
  globalStandardCost: string;
  globalMsrp: string;
  upc: string;
  gtin: string;
  tccProductionLifecycleStatus: ValueOrNull<Option<string>>;
  tccSalesLifecycleStatus: ValueOrNull<Option<string>>;
  tccLinePlanningStatus: ValueOrNull<Option<string>>;
  itemNumber: string;

  sourcingType: ValueOrNull<Option<string>>;
  vendor: ValueOrNull<ISupplierCountry>;
  tpsType: ValueOrNull<IResPisTpsType>;
  discontinued: ValueOrNull<Option<string>>;
  buyer: ValueOrNull<IBuyer>;
  releaseDateOperator: Option<string>;
  releaseDate: string;
  supplierCountry: ValueOrNull<ISupplierCountry>;
  stockReported: ValueOrNull<Option<string>>;
};

export enum PmsSearchActionTypes {
  SET_GLOBAL_PRODUCT = "pmsSearch/setGlobalProduct",
  SET_CATEGORY = "pmsSearch/setCategory",
  SET_SUB_CATEGORY = "pmsSearch/setSubCategory",
  SET_PRODUCT_LINE = "pmsSearch/setProductLine",
  SET_PRODUCT_NUMBER = "pmsSearch/setProductNumber",
  SET_PRODUCT_DESCRIPTION = "pmsSearch/setProductDescription",
  SET_TCC = "pmsSearch/setTcc",
  SET_MOLDS = "pmsSearch/setMolds",
  SET_NON_MOLDS = "pmsSearch/setNonMolds",
  SET_MATERIALS = "pmsSearch/setMaterials",
  SET_COLORS = "pmsSearch/setColors",
  SET_DECO_TECH = "pmsSearch/setDecoTech",
  SET_ARTWORK = "pmsSearch/setArtwork",
  SET_PRODUCTION_LIFECYCLE_STATUS = "pmsSearch/setProductionLifecycleStatus",
  SET_SALES_LIFECYCLE_STATUS = "pmsSearch/setSalesLifecycleStatus",
  SET_LINE_PLANNING_STATUS = "pmsSearch/setLinePlanningStatus",

  SET_ALWAYS_ON = "pmsSearch/setAlwaysOn",
  SET_BRAND = "pmsSearch/setBrand",
  SET_PRODUCT_CAPACITY = "pmsSearch/setProductCapacity",
  SET_PRODUCT_CAPACITY_UOM = "pmsSearch/setProductCapacityUom",
  SET_GP_PRODUCTION_LIFECYCLE_STATUS = "pmsSearch/setGpProductionLifecycleStatus",
  SET_GP_SALES_LIFECYCLE_STATUS = "pmsSearch/setGpSalesLifecycleStatus",
  SET_GP_LINE_PLANNING_STATUS = "pmsSearch/setGpLinePlanningStatus",

  SET_PRODUCT_COLOR = "pmsSearch/setProductColor",
  SET_MARKETING_MATERIAL = "pmsSearch/setMarketingMaterial",
  SET_TCC_ARTWORK = "pmsSearch/setTccArtwork",
  SET_CHANNEL = "pmsSearch/setChannel",
  SET_PIECES_SELLING_UNITS_OPERATOR = "pmsSearch/setPiecesSellingUnitsOperator",
  SET_PIECES_SELLING_UNITS = "pmsSearch/setPiecesSellingUnits",
  SET_CONSUMER_FACING_NAME = "pmsSearch/setConsumerFacingName",
  SET_LICENSOR = "pmsSearch/setLicensor",
  SET_LICENSED_PROPERTY = "pmsSearch/setLicensedProperty",
  SET_ROYALTY_ITEM = "pmsSearch/setRoyaltyItem",
  SET_FLAVOR_SCENT = "pmsSearch/setFlavorScent",
  SET_GLOBAL_STANDARD_COST = "pmsSearch/setGlobalStandardCost",
  SET_GLOBAL_MSRP = "pmsSearch/setGlobalMsrp",
  SET_UPC = "pmsSearch/setUpc",
  SET_GTIN = "pmsSearch/setGtin",
  SET_TCC_PRODUCTION_LIFECYCLE_STATUS = "pmsSearch/setTccProductionLifecycleStatus",
  SET_TCC_SALES_LIFECYCLE_STATUS = "pmsSearch/setTccSalesLifecycleStatus",
  SET_TCC_LINE_PLANNING_STATUS = "pmsSearch/setTccLinePlanningStatus",
  SET_ITEM_NUMBER = "pmsSearch/setItemNumber",

  SET_SOURCING_TYPE = "pmsSearch/setSourcingType",
  SET_VENDOR = "pmsSearch/setVendor",
  SET_TPS_TYPE = "pmsSearch/setTpsType",
  SET_DISCONTINUED = "pmsSearch/setDiscontinued",
  SET_BUYER = "pmsSearch/setBuyer",
  SET_RELEASE_DATE_OPERATOR = "pmsSearch/setReleaseDateOperator",
  SET_RELEASE_DATE = "pmsSearch/setReleaseDate",
  SET_SUPPLIER_COUNTRY = "pmsSearch/setSupplierCountry",
  SET_STOCK_REPORTED = "pmsSearch/setStockReported",

  SET_FILTERS_FROM_STORAGE = "pmsSearch/setFiltersFromStorage",
  RESET = "pmsSearch/reset",
}

export type PmsSearchActionType = Action<
  PmsSearchActionTypes,
  Partial<PmsSearchState>
>;
