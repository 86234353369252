const CharLimitCounter = ({
  limit,
  currentValue,
}: {
  limit: number;
  currentValue: string;
}) => {
  return (
    <>
      <span className={`d-flex justify-content-end mb-2 ml-auto`}>
        {`${currentValue.length}/${limit} Characters`}
      </span>
    </>
  );
};

export default CharLimitCounter;
