import { ILinePlanningStatusCondition } from "types/data.interface";

export const calculateLinePlanningStatus = (
  lineStatusConditions: ILinePlanningStatusCondition[],
  productionLifecycleStatus: string | null,
  salesLifecycleStatus: string | null,
): string => {
  const rightLineStatus = lineStatusConditions.find(
    (condition) =>
      condition.production_lifecycle_status === productionLifecycleStatus &&
      condition.sales_lifecycle_status === salesLifecycleStatus,
  );
  return rightLineStatus?.line_planning_status ?? "";
};

export const getLinePlanningStatusNames = (
  lineStatusConditions: ILinePlanningStatusCondition[],
) => lineStatusConditions.map((condition) => condition.line_planning_status);

export const getLinePlanningStatusOptions = (
  lineStatusConditions: ILinePlanningStatusCondition[],
) =>
  getLinePlanningStatusNames(lineStatusConditions).map((status) => {
    return { label: status, value: status };
  });
