import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function PictureModal({
  handleCloseModal,
  product,
}: {
  handleCloseModal: any;
  product: any;
}) {
  return (
    <div className="p-2">
      <div className="d-flex justify-content-between mb-1">
        <div className="col-4"></div>
        <h3 className="h5 text-center col-md-4">
          <strong>Picture - SKU nº 11 {product.id}</strong>
        </h3>
        <div className="col-md-4 d-flex justify-content-end">
          <button
            onClick={handleCloseModal}
            className="icon-button close-cross"
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
      </div>
      <div className="d-flex flex-column align-items-center px-3 mx-3 ">
        <img
          src={product?.image_url ? product?.image_url : product?.image_file}
          className="mb-3 d-block"
          height={300}
          alt="product"
        />
        <div className="d-flex w-100 justify-content-between">
          <div className="col-4"></div>
          <p className="text-center col-4">Not to Scale</p>
          <p className="text-right col-4">{`Source: ${
            product?.image_url ? "Bynder" : "Moldapp"
          }`}</p>
        </div>
      </div>
    </div>
  );
}

export default PictureModal;
