import { mapSingleValue } from "helpers";
import { Predicates } from "libraries/predicates/predicates";
import { TccSearchesState } from "states/tcc-searches/data.type";
import { retrieveObjectFromLocalStorage } from "./local-storage.helper";
import {
  SearchTccPersistentData,
  SearchTccRequestPersistentData,
} from "types/persistent-page-filters";

export const buildTccSearchFilterObject = (state: TccSearchesState) => {
  return {
    item_number: state.itemNumber ? state.itemNumber.toString() : null,
    global_product_id: Predicates.isNotNullAndNotUndefined(state.globalProduct)
      ? (state.globalProduct.id ?? "").toString()
      : null,
    tcc_id: Predicates.isNotNullAndNotUndefined(state.tcc)
      ? (state.tcc.id ?? "").toString()
      : null,
    category: state.category ? state.category.id.toString() : null,
    sub_category: state.subCategory ? state.subCategory.id.toString() : null,
    product_line: state.productLine ? state.productLine.id.toString() : null,
    decoration_tech: state.decoTech ? state.decoTech.id.toString() : null,
    product_color_id: state.productColor
      ? state.productColor.id.toString()
      : null,

    // pieces in selling units key is based on operator
    [state.piecesSellingUnitsOperator.value]: state.piecesSellingUnits
      ? state.piecesSellingUnits
      : null,
    royaltyitem: mapSingleValue(state.royaltyItem),
    artwork__unique_id: state.artwork
      ? state.artwork.unique_id.toString()
      : null,
    consumer_facing_name: state.consumerFacingName
      ? state.consumerFacingName
      : null,
    production_status: mapSingleValue(state.productionStatus),
    sales_status: mapSingleValue(state.salesStatus),
    line_planning_status: mapSingleValue(state.linePlanningStatus),
    global_standard_cost: state.globalStandardCost
      ? state.globalStandardCost
      : null,
    global_manufacturer_price: state.globalMsrp ? state.globalMsrp : null,
    marketing_material: state.marketingMaterial
      ? state.marketingMaterial.id?.toString()
      : null,
    channel: state.channel ? state.channel.id?.toString() : null,
    licensor: state.licensor ? state.licensor.id?.toString() : null,
    licensed_property: state.licensedProperty
      ? state.licensedProperty.id?.toString()
      : null,
    flavor_scent: state.flavorScent ? state.flavorScent.id?.toString() : null,

    requestor: state.requestor ? state.requestor : null,
    approver: state.approver ? state.approver : null,
    requestDate_gt: state.requestDateFrom ? state.requestDateFrom : null,
    requestDate_lt: state.requestDateTo ? state.requestDateTo : null,
    approvedDate_gt: state.approvedDateFrom ? state.approvedDateFrom : null,
    approvedDate_lt: state.approvedDateTo ? state.approvedDateTo : null,

    request_id: state.requestId ? state.requestId : null,
  };
};

export const buildTccRequestSearchFilterObject = (state: TccSearchesState) => {
  return {
    id: state.requestId ? state.requestId : null,
    status__status: state.requestStatus ? state.requestStatus.value : null,
    created_at__gte: state.requestDateFrom ? state.requestDateFrom : null,
    created_at__lte: state.requestDateTo ? state.requestDateTo : null,
    created_by__id: state.requestor ? state.requestor : null,
    approved_date__gte: state.approvedDateFrom ? state.approvedDateFrom : null,
    approved_date__lte: state.approvedDateTo ? state.approvedDateTo : null,
    approver: state.approver ? state.approver : null,
    tcc__id: Predicates.isNotNullAndNotUndefined(state.tcc)
      ? (state.tcc.id ?? "").toString()
      : null,
    global_product__id: Predicates.isNotNullAndNotUndefined(state.globalProduct)
      ? (state.globalProduct.id ?? "").toString()
      : null,
    category__id: state.category ? state.category.id.toString() : null,
    sub_category__id: state.subCategory
      ? state.subCategory.id.toString()
      : null,
    product_line__id: state.productLine
      ? state.productLine.id.toString()
      : null,
    product_color__id: state.productColor
      ? state.productColor.id.toString()
      : null,
    marketing_material__id: state.marketingMaterial
      ? state.marketingMaterial.id?.toString()
      : null,
    decoration_technique__id: state.decoTech
      ? state.decoTech.id.toString()
      : null,
    artwork__id: state.artwork ? state.artwork.unique_id.toString() : null,
    channel__id: state.channel ? state.channel.id?.toString() : null,
    [state.piecesSellingUnitsOperator.value]: state.piecesSellingUnits
      ? state.piecesSellingUnits
      : null,
    consumer_facing_name: state.consumerFacingName
      ? state.consumerFacingName
      : null,
    royalty_item: state.royaltyItem
      ? state.royaltyItem.value === "1"
        ? [true]
        : [false]
      : null,
    licensor__id: state.licensor ? state.licensor.id?.toString() : null,
    licensed_property__id: state.licensedProperty
      ? state.licensedProperty.id?.toString()
      : null,
    flavor_scent__id: state.flavorScent
      ? state.flavorScent.id?.toString()
      : null,
  };
};

export const getOperatorAndValue = (storedFilters: any, keyPrefix: string) => {
  for (let key in storedFilters) {
    if (key.includes(keyPrefix)) {
      const releaseDateKeySplit = key.split("__");
      let label = "";
      switch (releaseDateKeySplit[1]) {
        case "gt":
          label = ">";
          break;
        case "gte":
          label = ">=";
          break;
        case "lt":
          label = "<";
          break;
        case "lte":
          label = "<=";
          break;
        default:
          label = "=";
          break;
      }
      return {
        operator: { label: label, value: key },
        value: storedFilters[key] ?? "",
      };
    }
  }
};

export const buildTccRequestTextFiltersBasedOnLocalStorage = (
  prefix:
    | SearchTccPersistentData["prefix"]
    | SearchTccRequestPersistentData["prefix"],
): Partial<TccSearchesState> => {
  const storedFilters = retrieveObjectFromLocalStorage<
    SearchTccPersistentData | SearchTccRequestPersistentData
  >(prefix, "lastFilters");
  if (storedFilters) {
    const piecesSellingUnitsValues = getOperatorAndValue(
      storedFilters,
      "pieces_selling_units",
    );
    return {
      piecesSellingUnitsOperator: piecesSellingUnitsValues?.operator ?? {
        value: "pieces_selling_units",
        label: "=",
      },
      piecesSellingUnits: piecesSellingUnitsValues?.value ?? "",
      consumerFacingName: storedFilters.consumer_facing_name ?? "",
      requestDateFrom: storedFilters.created_at__gte ?? "",
      requestDateTo: storedFilters.created_at__lte ?? "",
      requestor: storedFilters.created_by__id ?? "",
      approver: storedFilters.approver ?? "",
      approvedDateFrom: storedFilters.approved_date__gte ?? "",
      approvedDateTo: storedFilters.approved_date__lte ?? "",
      requestId: storedFilters.id ?? "",
    };
  } else {
    return {};
  }
};

export const buildTccTextFiltersBasedOnLocalStorage = (
  prefix:
    | SearchTccPersistentData["prefix"]
    | SearchTccRequestPersistentData["prefix"],
): Partial<TccSearchesState> => {
  const storedFilters = retrieveObjectFromLocalStorage<
    SearchTccPersistentData | SearchTccRequestPersistentData
  >(prefix, "lastFilters");
  if (storedFilters) {
    const piecesSellingUnitsValues = getOperatorAndValue(
      storedFilters,
      "pieces_selling_units",
    );
    return {
      piecesSellingUnitsOperator: piecesSellingUnitsValues?.operator ?? {
        value: "pieces_selling_units",
        label: "=",
      },
      piecesSellingUnits: piecesSellingUnitsValues?.value ?? "",
      itemNumber: storedFilters.item_number ?? "",
      consumerFacingName: storedFilters.consumer_facing_name ?? "",
      requestDateFrom: storedFilters.requestDate_gt ?? "",
      requestDateTo: storedFilters.requestDate_lt ?? "",
      requestor: storedFilters.requestor ?? "",
      approver: storedFilters.approver ?? "",
      approvedDateFrom: storedFilters.approvedDate_gt ?? "",
      approvedDateTo: storedFilters.approvedDate_lt ?? "",
      globalStandardCost: storedFilters.global_standard_cost ?? "",
      globalMsrp: storedFilters.global_manufacturer_price ?? "",
    };
  } else {
    return {};
  }
};

export const buildDropdownFiltersBasedOnLocalStorage = (
  prefix:
    | SearchTccPersistentData["prefix"]
    | SearchTccRequestPersistentData["prefix"],
): Partial<TccSearchesState> => {
  const storedDropdownFilters = retrieveObjectFromLocalStorage<
    SearchTccPersistentData | SearchTccRequestPersistentData
  >(prefix, "lastDropdownFilters");
  if (storedDropdownFilters) {
    return {
      globalProduct: storedDropdownFilters.globalProduct ?? null,
      tcc: storedDropdownFilters.tcc ?? null,
      category: storedDropdownFilters.category ?? null,
      subCategory: storedDropdownFilters.subCategory ?? null,
      productLine: storedDropdownFilters.productLine ?? null,
      productColor: storedDropdownFilters.product_color_id ?? null,
      marketingMaterial: storedDropdownFilters.marketingMaterial ?? null,
      channel: storedDropdownFilters.channel ?? null,
      decoTech: storedDropdownFilters.decoration_tech ?? null,
      artwork: storedDropdownFilters.tccArtwork ?? null,
      royaltyItem: storedDropdownFilters.royaltyitem ?? null,
      productionStatus: storedDropdownFilters.productionStatus ?? null,
      salesStatus: storedDropdownFilters.salesStatus ?? null,
      licensor: storedDropdownFilters.licensor ?? null,
      licensedProperty: storedDropdownFilters.licensedProperty ?? null,
      flavorScent: storedDropdownFilters.flavorScent ?? null,
      requestStatus: storedDropdownFilters.requestStatus ?? null,
    };
  } else {
    return {};
  }
};
