import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "axios";
import Spinner from "../components/Spinner";
import { Predicates } from "../libraries/predicates/predicates";
import { deleteAllValuesOfPrefix } from "helpers/local-storage.helper";
import { PMSPersistentData } from "types/persistent-page-filters";

interface AppContextInterface {
  authTokens: { refresh: string; access: string } | null;
  setAuthTokens?: any;
  user: any;
  username: string | null;
  setUser?: any;
  loginUser?: any;
  logoutUser?: any;
  hasPermission?: any;
  cookieSessionId: string | null;
}

const AuthContext = createContext<AppContextInterface | null>(null);

export default AuthContext;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const shouldNotClearLocalStorage = searchParams.get("no_clear");

  const cookieSessionId = useMemo(() => {
    const sharedCookie = document.cookie.replace("; ", "&");
    return new URLSearchParams(sharedCookie).get(
      (process.env.REACT_APP_BASE_NAME ?? "test")?.search("test") >= 0
        ? "t_sharedsession"
        : "sharedsession",
    );
  }, []);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() => {
    const tokens = localStorage.getItem("authTokens");
    return tokens ? JSON.parse(tokens) : null;
  });

  const [user, setUser] = useState(() => {
    const tokens = localStorage.getItem("authTokens");
    return tokens ? jwt_decode(tokens) : null;
  });

  const [username, setUsername] = useState(() => {
    const username = localStorage.getItem("username");
    return username ? JSON.parse(username) : null;
  });

  const [permissions, setPermissions] = useState(() => {
    const permissions = localStorage.getItem("permissions");
    return permissions ? permissions : null;
  });

  const hasPermission = useCallback(
    (permisssionToCheck: string) => {
      return permissions?.includes(permisssionToCheck);
    },
    [permissions],
  );

  const loginUser = useCallback(() => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/token/session/`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        sessid: cookieSessionId,
      }),
    })
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setAuthTokens(data);
          setUser(jwt_decode(data.access));
          setPermissions(data.permissions);
          setUsername(data.username);
          localStorage.setItem("authTokens", JSON.stringify(data));
          localStorage.setItem("permissions", JSON.stringify(data.permissions));
          localStorage.setItem("username", JSON.stringify(data.username));
          setLoading(false);
        } else {
          logoutUser();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        logoutUser();
        setLoading(false);
      });
  }, [cookieSessionId]);

  const logoutUser = useCallback(
    (reroute = true) => {
      setAuthTokens(null);
      setUser(null);
      setUsername(null);
      setPermissions(null);
      localStorage.removeItem("authTokens");
      localStorage.removeItem("permissions");
      localStorage.removeItem("username");
      if (reroute) {
        navigate("/no-access");
      }
    },
    [navigate],
  );

  const contextData: AppContextInterface = useMemo(
    () => ({
      authTokens,
      setAuthTokens,
      user,
      username,
      setUser,
      loginUser,
      logoutUser,
      hasPermission,
      cookieSessionId,
    }),
    [
      authTokens,
      hasPermission,
      loginUser,
      logoutUser,
      user,
      username,
      cookieSessionId,
    ],
  );

  useEffect(() => {
    if (Predicates.isNotNullAndNotUndefinedAndNotEmpty(cookieSessionId)) {
      if (!shouldNotClearLocalStorage) {
        deleteAllValuesOfPrefix<PMSPersistentData>("pms");
      }
      searchParams.delete("no_clear");
      setSearchParams(searchParams);
      loginUser();
    } else {
      logoutUser();
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};
