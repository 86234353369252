import Spinner from "components/Spinner";
import { fetchTccById } from "hooks/useTcc";
import { Predicates } from "libraries/predicates/predicates";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ITcc } from "types/data.interface";
import useAxios from "utils/useAxios";
import {
  TccViewAttributes,
  TccViewBusinessCaseFields,
  TccViewComposition,
  TccViewHierarchy,
} from "../components/tcc-view";
import AuthContext from "context/AuthContext";
import TccViewHistorySection from "../components/tcc-view/TccViewHistorySection";
import ProductContext, {
  ProductContextInterface,
} from "../../../context/ProductContext";

const TccView = () => {
  const axios = useAxios();
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tccId: string | null = useMemo(
    () => searchParams.get("id"),
    [searchParams],
  );
  const [isTccInformationLoading, setIsTccInformationLoading] =
    useState<boolean>(false);
  const [tcc, setTcc] = useState<ITcc | null>(null);
  const hasGlobalApproverPermission: boolean = hasPermission(
    "api.global_approver_tcc_request",
  );

  const handleEditTcc = () => {
    navigate(`/tccs/create?request_id=${tcc?.request_id}&is_edit=true`);
  };

  const { setUnsavedChanges } =
    useContext<ProductContextInterface>(ProductContext);

  useEffect(() => {
    const loadTcc = async () => {
      if (Predicates.isNotNullAndNotUndefinedAndNotEmpty(tccId)) {
        setIsTccInformationLoading(true);
        const tccData: any = await fetchTccById({
          id: tccId,
          axios,
        });

        setTcc(tccData);
        setIsTccInformationLoading(false);
      }
    };
    loadTcc();
    setUnsavedChanges({
      existsUnsavedChanges: false,
    });
  }, []);

  return (
    <>
      {isTccInformationLoading || Predicates.isNullOrUndefined(tcc) ? (
        <Spinner />
      ) : (
        <>
          <TccViewHierarchy tccData={tcc} />
          <TccViewAttributes tccData={tcc} />
          <TccViewBusinessCaseFields tccData={tcc} />
          <TccViewComposition tccData={tcc} />
          <TccViewHistorySection tccData={tcc} />

          {hasGlobalApproverPermission && (
            <div className="d-flex justify-items-end mt-3">
              <button
                className="ms-auto btn btn-primary"
                onClick={handleEditTcc}
              >
                Edit TCC
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TccView;
