import { memo } from "react";
import ButtonIconModal from "../ButtonIconModal";
import PictureModal from "../modals/PictureModal";

const PictureCellRenderer = memo(
  (prop: { id: number; url: string; sourceType: string }) => (
    <ButtonIconModal
      buttonIconClass="d-flex justify-content-between"
      description="PICTURE"
      title={`Picture - SKU nº 11 ${prop.id}`}
      iconClass="me-2"
    >
      <PictureModal url={prop.url} sourceType={prop.sourceType} />
    </ButtonIconModal>
  ),
);

export default PictureCellRenderer;
