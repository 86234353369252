import Spinner from "../../../../components/Spinner";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import {
  approveRequest,
  editRequest,
  reOpenRequest,
  rejectRequest,
  saveRequest,
  selectRequest,
  submitRequest,
} from "../../../../helpers/create-tcc.helper";
import CreateTccActionButton, {
  CreateTccActionButtonProps,
} from "./CreateTccActionButton";
import {
  SubmitConfirmationModal,
  ApproveConfirmationModal,
  RejectConfirmationModal,
  EditConfirmationModal,
} from "../confirmation-modals";
import useAxios from "utils/useAxios";
import { useNavigate } from "react-router-dom";
import SelectForApprovalModal from "../../../../components/SelectForApprovalModal";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { ListingModalRef } from "components/ButtonModal";
import { useRef } from "react";

type CreateTccActionButtonsGroupProps = {
  step: number;
  isReadOnlyRequest: boolean;
  isEdit: boolean;
  isSaveOrSubmitInProgress: boolean;
  username: string;
  hasRegionalApproverPermission: boolean;
  hasGlobalMarketApproverPermission: boolean;
  hasGlobalApproverPermission: boolean;
  onPrevious: () => void;
  onNext: (specificStep?: number) => void;
  isStepDisabled: (step: number) => boolean;
  setIsSaveOrSubmitInProgress: (isLoading: boolean) => void;
};

const CreateTccActionButtonsGroup = ({
  step,
  isReadOnlyRequest,
  isEdit,
  isSaveOrSubmitInProgress,
  username,
  hasRegionalApproverPermission,
  hasGlobalMarketApproverPermission,
  hasGlobalApproverPermission,
  onPrevious,
  onNext,
  isStepDisabled,
  setIsSaveOrSubmitInProgress,
}: CreateTccActionButtonsGroupProps) => {
  const { state, dispatch } = useCreateTccStore();
  const navigate = useNavigate();
  const axios = useAxios();
  const modalRef = useRef<ListingModalRef>(null);

  const hasAnyApproverPermission =
    hasRegionalApproverPermission ||
    hasGlobalMarketApproverPermission ||
    hasGlobalApproverPermission;

  const actionButtons: { [key: string]: CreateTccActionButtonProps } = {
    save: {
      show: !isReadOnlyRequest && !isEdit,
      disabled: isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-primary",
      description: "Save",
      className: "ms-auto",
      clickHandler: () =>
        saveRequest(
          axios,
          state,
          username,
          dispatch,
          setIsSaveOrSubmitInProgress,
        ),
      isButtonModal: false,
    },
    next: {
      show: step < 2,
      disabled: isStepDisabled(step) || isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-primary",
      description: "Next",
      className: "ms-auto",
      clickHandler: () => onNext(),
      isButtonModal: false,
    },
    previous: {
      show: step > 0 && !isReadOnlyRequest,
      disabled: step === 0 || isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-outline-primary",
      description: `Previous`,
      className: "ms-auto",
      clickHandler: () => onPrevious(),
      isButtonModal: false,
    },
    submit: {
      show:
        step === 2 &&
        state.requestStatus === "CREATED" &&
        !hasGlobalApproverPermission &&
        !isReadOnlyRequest,
      disabled: isStepDisabled(step) || isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-primary",
      description: "Submit for Approval",
      className: "ms-auto",
      clickHandler: () => {},
      isButtonModal: true,
      modalTitle: "Confirm TCC Request Submission for Approval",
      modal: (
        <SubmitConfirmationModal
          onConfirm={() =>
            submitRequest(
              axios,
              state,
              username,
              dispatch,
              setIsSaveOrSubmitInProgress,
              navigate,
            )
          }
          parentModalRef={modalRef}
        />
      ),
      modalRef: modalRef,
    },
    approve: {
      show:
        step === 2 &&
        !isReadOnlyRequest &&
        ((state.requestStatus === "SUBMITTED" &&
          state.currentApprover === username &&
          hasAnyApproverPermission) ||
          (state.requestStatus === "CREATED" && hasGlobalApproverPermission)),
      disabled: isStepDisabled(step) || isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-primary",
      description: hasGlobalApproverPermission ? "Create TCC" : "Approve",
      className: "ms-auto",
      clickHandler: () => {},
      isButtonModal: true,
      modalTitle: hasGlobalApproverPermission
        ? "Confirm TCC Creation"
        : "Confirm TCC Request Approval",
      modal: (
        <ApproveConfirmationModal
          onConfirm={() =>
            approveRequest(
              axios,
              state,
              username,
              dispatch,
              setIsSaveOrSubmitInProgress,
              navigate,
            )
          }
          parentModalRef={modalRef}
        />
      ),
      modalRef: modalRef,
    },
    reject: {
      show:
        !isReadOnlyRequest &&
        state.requestStatus === "SUBMITTED" &&
        hasAnyApproverPermission &&
        state.currentApprover === username,
      disabled: isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-danger",
      description: "Reject",
      className: "ms-auto",
      clickHandler: () => {},
      isButtonModal: true,
      modalTitle: "Confirm TCC Request Rejection",
      modal: (
        <RejectConfirmationModal
          onConfirm={() =>
            rejectRequest(
              axios,
              state,
              username,
              dispatch,
              setIsSaveOrSubmitInProgress,
              navigate,
            )
          }
          parentModalRef={modalRef}
        />
      ),
      modalRef: modalRef,
    },
    select: {
      show:
        step === 2 &&
        state.requestStatus === "SUBMITTED" &&
        ((state.currentApprovalLevel?.description === "REGIONAL" &&
          hasRegionalApproverPermission) ||
          (state.currentApprovalLevel?.description === "GLOBAL MKT" &&
            hasGlobalMarketApproverPermission) ||
          (state.currentApprovalLevel?.description === "GLOBAL" &&
            hasGlobalApproverPermission)) &&
        Predicates.isNullOrUndefinedOrEmpty(state.currentApprover) &&
        isReadOnlyRequest,
      disabled: isSaveOrSubmitInProgress && isStepDisabled(step),
      buttonStyleClass: "btn-primary",
      description: `Select for Approval`,
      className: "ms-auto",
      clickHandler: () => {},
      isButtonModal: true,
      modalTitle: "",
      modal: (
        <SelectForApprovalModal
          isProcessingSelect={isSaveOrSubmitInProgress}
          message={`Are you sure you want to be assigned as the approver for request ${state.requestId}?`}
          confirm={() =>
            selectRequest(
              axios,
              state.requestId,
              username,
              state.requestorMarket,
              setIsSaveOrSubmitInProgress,
              navigate,
            )
          }
          parentModalRef={modalRef}
        />
      ),
      modalRef: modalRef,
    },
    continueReview: {
      show:
        step === 2 &&
        state.requestStatus === "SUBMITTED" &&
        isReadOnlyRequest &&
        hasAnyApproverPermission &&
        state.currentApprover === username,
      disabled: isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-primary",
      description: "Continue Review",
      className: "ms-auto",
      clickHandler: () =>
        navigate(`/tccs/create?request_id=${state.requestId}`),
      isButtonModal: false,
    },
    reOpen: {
      show: step === 2 && state.requestStatus === "REJECTED",
      disabled: isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-primary",
      description: "Correct Request",
      className: "ms-auto",
      clickHandler: () =>
        reOpenRequest(
          axios,
          state,
          username,
          dispatch,
          setIsSaveOrSubmitInProgress,
          navigate,
        ),
      isButtonModal: false,
    },
    edit: {
      show:
        isEdit &&
        step === 2 &&
        state.requestStatus === "APPROVED" &&
        hasGlobalApproverPermission,
      disabled: isSaveOrSubmitInProgress,
      buttonStyleClass: "btn-primary",
      description: "Finish Edit",
      className: "ms-auto",
      clickHandler: () => {},
      isButtonModal: true,
      modalTitle: "Confirm TCC Edition",
      modal: (
        <EditConfirmationModal
          onConfirm={() =>
            editRequest(
              axios,
              state,
              username,
              dispatch,
              setIsSaveOrSubmitInProgress,
              navigate,
            )
          }
          parentModalRef={modalRef}
        />
      ),
      modalRef: modalRef,
    },
  };

  return (
    <div className="d-flex justify-content-end mt-3">
      <div className="d-flex gap-3 me-auto">
        <CreateTccActionButton {...actionButtons.save} />

        <CreateTccActionButton {...actionButtons.reject} />
      </div>

      <div className="d-flex gap-3" style={{ height: 38 }}>
        {isSaveOrSubmitInProgress && (
          <Spinner hideLabel={true} mediumSpinner={true} />
        )}

        <CreateTccActionButton {...actionButtons.previous} />

        <CreateTccActionButton {...actionButtons.next} />

        <CreateTccActionButton {...actionButtons.submit} />

        <CreateTccActionButton {...actionButtons.approve} />

        <CreateTccActionButton {...actionButtons.select} />

        <CreateTccActionButton {...actionButtons.continueReview} />

        <CreateTccActionButton {...actionButtons.reOpen} />

        <CreateTccActionButton {...actionButtons.edit} />
      </div>
    </div>
  );
};

export default CreateTccActionButtonsGroup;
