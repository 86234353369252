import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useContext, useEffect, useMemo, useRef } from "react";
import { usePisRequestTable } from "../../../../hooks/usePisRequest";
import { DateTimeFormat } from "../../../../libraries/date-time-format/date-time-format";
import { Predicates } from "../../../../libraries/predicates/predicates";
import {
  PisRequestOrderByType,
  PisRequestStatus,
  PisRequestTable,
} from "../../../../types/data.interface";
import ApprovalQueueActionLinks from "./ApprovalQueueActionLinks";
import { Link } from "react-router-dom";
import { usePageableTable } from "../../../../context/PageableTableContext";
import { PageableTableActions } from "../../../../states/pageable-table";
import { PageableTable } from "../../../../components/table";
import AuthContext from "../../../../context/AuthContext";

export default function ApprovalQueueTable({
  limit,
  lastDays,
  search,
  skuSearch,
  requestorSearch,
  status,
  orderBy,
}: Readonly<{
  limit: number;
  lastDays?: number;
  search?: string;
  skuSearch?: string;
  requestorSearch?: string;
  status?: Array<PisRequestStatus>;
  orderBy?: PisRequestOrderByType;
}>) {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const gridRef = useRef<AgGridReact<Array<PisRequestTable>>>(null);
  const {
    state: { offset },
    dispatch,
  } = usePageableTable();

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: "pis_request_id",
        headerName: "ID",
        flex: 0.8,
      },
      {
        field: "sku_id",
        headerName: "SKU nº",
        flex: 1,
        cellRenderer: (params: any) => {
          const pathToNavigate =
            params.data.sku_id && params.data.sku_id.length
              ? `/product-information/?productnumber=${String(
                  params.data.sku_id,
                ).replace(
                  /^0+/,
                  "",
                )}`
              : "";
          return (
            <>
              {params.data.sku_id && params.data.sku_id.length ? (
                <Link
                  className="text-decoration-underline fw-bold"
                  to={pathToNavigate}
                >
                  {`11 ${params.data.sku_id}`}
                </Link>
              ) : (
                <span></span>
              )}
            </>
          );
        },
      },
      {
        field: "sku_description",
        headerName: "SKU description",
        flex: 2,
      },
      {
        field: "request_date",
        headerName: "Request Date",
        valueGetter: ({ data }) => DateTimeFormat.formatDate(data.request_date),
        flex: 1.1,
      },
      {
        field: "requestor",
        headerName: "Requestor",
      },
      {
        field: "requestor_location",
        headerName: "Requestor Location",
        cellClass: "text-uppercase",
        flex: 1.3,
      },
      {
        field: "approved_date",
        headerName: "Approved / Rejected Date",
        valueGetter: ({ data }) =>
          data.status === "APPROVED" || data.status === "REJECTED"
            ? DateTimeFormat.formatDate(data.approved_date)
            : "",
        flex: 1.1,
      },
      {
        field: "approver",
        headerName: "Approver",
        valueGetter: ({ data }) =>
          data.status === "APPROVED" || data.status === "REJECTED"
            ? data.approver
            : "",
        flex: 1.1,
      },
      {
        field: "release_date",
        headerName: "Released Date",
        valueGetter: ({ data }) =>
          data.release_date ? DateTimeFormat.formatDate(data.release_date) : "",
        flex: 1.1,
      },
      {
        field: "revision",
        headerName: "Revision nº",
        flex: 0.8,
      },
      {
        field: "status",
        headerName: "Status",
      },
      {
        field: "status",
        headerName: "Actions",
        cellRenderer: ApprovalQueueActionLinks,
        hide: !hasPermission("product_master.add_pisrequest"),
      },
    ],
    [],
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      headerClass: "text-wrap",
      sortable: true,
      resizable: true,
      flex: 1,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    }),
    [],
  );

  const pisRequestData = usePisRequestTable({
    limit,
    offset,
    lastDays,
    search,
    skuSearch,
    requestorSearch,
    status,
    orderBy,
  });

  const tableProp = useMemo(
    () => ({
      gridRef,
      rowData: pisRequestData.data?.data,
      columnDefs,
      defaultColDef,
    }),
    [gridRef, pisRequestData.data, columnDefs, defaultColDef],
  );

  useEffect(() => {
    dispatch(PageableTableActions.setLoading(pisRequestData.isLoading));
    if (Predicates.isNotNullAndNotUndefined(pisRequestData.data)) {
      dispatch(PageableTableActions.setTotal(pisRequestData.data.total));
    }
  }, [pisRequestData, dispatch]);

  useEffect(() => {
    dispatch(PageableTableActions.setFirstRender(false));
  }, []);

  return <PageableTable tableProp={tableProp} />;
}
