import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  ICompositionComponent,
  IGlobalProduct,
  ISkuComposition,
  ITcc,
  ITccComposition,
  TccCompositionRegionInfo,
} from "../../../../types/data.interface";
import { HierarchyInfoSection } from "../../components/hierarchy-info";
import { AddSkuCompositionModal } from "../../components/sku-composition-modal";
import {
  COMPOSITION_OPTIONS,
  CompositionType,
  EditCompositionParams,
} from "../../components/sku-composition-modal/AddSkuCompositionModal";
import {
  AddEditSkuCompositionComponentForm,
  SkuCompositionTree,
} from "../../components/sku-composition-tree";
import {
  checkIfAtLeastOneComponentIsTheSameInList,
  checkIfSkuIdIsIncludedInNode,
  findNodeIndexByNodeId,
  generateNodeId,
  getNodeByNodeId,
  getUpdatedComponentsList,
  isSkuCompositionEmpty,
} from "./sku-create.helper";
import { ListingModalRef } from "components/ButtonModal";
import ButtonIconModal from "components/ButtonIconModal";
import Modal from "components/Modal";

export type CompositionProps = {
  selectedGP: IGlobalProduct | null;
  selectedTcc: ITcc | null;
  savedSkuComposition: ISkuComposition;
  saveSkuComposition: (composition: ISkuComposition) => void;
  setUnsavedChangesToTrue: () => void;
  selectedTccCompositionRegion: TccCompositionRegionInfo | null;
  skuIdInRevision?: string;
  tccComposition: ITccComposition[];
};

const Composition = ({
  selectedGP,
  selectedTcc,
  savedSkuComposition,
  saveSkuComposition,
  setUnsavedChangesToTrue,
  selectedTccCompositionRegion,
  skuIdInRevision,
  tccComposition,
}: CompositionProps) => {
  const [showSkuCompositionModal, setShowSkuCompositionModal] = useState(false);
  const modalRef = useRef<ListingModalRef>(null);

  const [skuComposition, setSkuComposition] =
    useState<ISkuComposition>(savedSkuComposition);
  const [editModalParams, setEditModalParams] =
    useState<EditCompositionParams | null>();

  const [component, setComponent] = useState<ICompositionComponent | null>(
    null,
  );

  const startEditProcess = (
    compositionEditType: CompositionType,
    compositionEditObject: ICompositionComponent | ISkuComposition,
  ) => {
    if (compositionEditType === "sku") {
      setEditModalParams({
        compositionEditType: compositionEditType,
        compositionEditObject: compositionEditObject,
      });

      setShowSkuCompositionModal(true);
    } else {
      setComponent(compositionEditObject as ICompositionComponent);
    }
  };

  const addSkuToComposition = (sku: ISkuComposition | null, nodeId: number) => {
    let updatedSkuList: ISkuComposition[] = skuComposition.skus;

    if (
      sku &&
      skuIdInRevision &&
      checkIfSkuIdIsIncludedInNode(sku, skuIdInRevision)
    ) {
      toast.error(
        "You can't include the same SKU you are revising to the composition",
      );
      return;
    }

    if (nodeId == -1 && sku) {
      updatedSkuList = [
        ...updatedSkuList,
        { ...sku, node_id: generateNodeId() },
      ];
      toast.success(
        `Successfully added SKU 11 ${sku.id.padStart(6, "0")} to composition`,
      );
    } else if (nodeId !== -1) {
      const oldNodeIndex: number = findNodeIndexByNodeId(
        nodeId,
        updatedSkuList,
      );

      if (sku) {
        updatedSkuList.splice(oldNodeIndex, 1, sku);
      } else {
        updatedSkuList.splice(oldNodeIndex, 1);
      }
    }

    setSkuComposition({ ...skuComposition, skus: updatedSkuList });
    setUnsavedChangesToTrue();
  };

  const duplicateSku = (nodeId: number) => {
    const duplicatedSku: ISkuComposition | undefined = skuComposition.skus.at(
      findNodeIndexByNodeId(nodeId, skuComposition.skus),
    );
    if (duplicatedSku) addSkuToComposition(duplicatedSku, -1);
  };

  const handleAdditionOfComponents = (
    moldedComponents: ICompositionComponent[],
    nonMoldedComponents: ICompositionComponent[],
    nodeId: number,
    isNewComponent: boolean,
  ) => {
    if (
      checkIfAtLeastOneComponentIsTheSameInList(
        moldedComponents,
        skuComposition.molded_components,
        isNewComponent,
      )
    ) {
      toast.error(
        `You're trying to add at least one Molded component that already exists. Please change something on the new component or alter the quantity of the existing one`,
      );
      return;
    }

    if (
      checkIfAtLeastOneComponentIsTheSameInList(
        nonMoldedComponents,
        skuComposition.non_molded_components,
        isNewComponent,
      )
    ) {
      toast.error(
        `You're trying to add at least one Non-Molded component that already exists. Please change something on the new component or alter the quantity of the existing one`,
      );
      return;
    }

    setSkuComposition({
      ...skuComposition,
      molded_components: getUpdatedComponentsList(
        skuComposition,
        "Molded",
        moldedComponents,
        nodeId,
      ),
      non_molded_components: getUpdatedComponentsList(
        skuComposition,
        "NonMolded",
        nonMoldedComponents,
        nodeId,
      ),
    });
    setUnsavedChangesToTrue();

    if (editModalParams) {
      closeModal();
      toast.success("Successfully edited selected component");
    } else {
      toast.success(`Successfully added components to composition`);
    }
  };

  const changeComponentQuantity = (nodeId: number, newQuantity: number) => {
    const componentToChange: ICompositionComponent | undefined =
      getNodeByNodeId(nodeId, skuComposition) as ICompositionComponent;
    if (componentToChange) {
      const updatedList = getUpdatedComponentsList(
        skuComposition,
        componentToChange.type,
        [{ ...componentToChange, quantity: newQuantity }],
        nodeId,
      );
      if (componentToChange.type === "Molded") {
        setSkuComposition({
          ...skuComposition,
          molded_components: updatedList,
        });
      } else {
        setSkuComposition({
          ...skuComposition,
          non_molded_components: updatedList,
        });
      }
      setUnsavedChangesToTrue();
    }
  };

  const deleteCompositionNode = (
    node_id: number,
    compositionType: CompositionType,
  ) => {
    const filteredSkus: ISkuComposition[] = skuComposition.skus.filter(
      (node: ISkuComposition) => node.node_id != node_id,
    );
    const filteredMoldedComponents: ICompositionComponent[] =
      skuComposition.molded_components.filter(
        (node: ICompositionComponent) => node.node_id != node_id,
      );
    const filteredNonMoldedComponents: ICompositionComponent[] =
      skuComposition.non_molded_components.filter(
        (node: ICompositionComponent) => node.node_id != node_id,
      );

    setSkuComposition({
      ...skuComposition,
      skus: filteredSkus,
      molded_components: filteredMoldedComponents,
      non_molded_components: filteredNonMoldedComponents,
    });
    setUnsavedChangesToTrue();

    toast.success(
      `Successfully removed ${
        compositionType === COMPOSITION_OPTIONS.SKU ? "SKU" : "Component"
      } from composition`,
    );
  };

  const closeModal = () => {
    setShowSkuCompositionModal(false);
    setEditModalParams(null);
  };

  useEffect(() => {
    saveSkuComposition(skuComposition);
  }, [skuComposition]);

  return (
    <>
      <Modal
        showModal={showSkuCompositionModal}
        onClose={closeModal}
        title={`${
          editModalParams
            ? `Edit ${
                editModalParams.compositionEditType === "sku"
                  ? `SKU 11 ${editModalParams.compositionEditObject.id} - ${editModalParams.compositionEditObject.name}`
                  : `Component ${editModalParams.compositionEditObject.mold_description}`
              }`
            : ""
        }`}
        customStyle={"mold-comps"}
      >
        <AddSkuCompositionModal
          parentModalRef={modalRef}
          handleAddSku={addSkuToComposition}
          handleAddComponents={handleAdditionOfComponents}
          editModalParams={editModalParams}
          handleCloseModal={closeModal}
          confirmLabel="Save"
          informativeMessage="If the SKU is selected all of its composition is added. If it is
                not selected, the user can select the components to add"
        />
      </Modal>

      <HierarchyInfoSection tcc={selectedTcc} gp={selectedGP} />

      <div className="card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">SKU Composition</h6>
      </div>

      <div className="d-flex justify-content-between mb-2">
        <p className="font-italic">
          {`${
            isSkuCompositionEmpty(skuComposition)
              ? "Your SKU has no components associated to it."
              : ""
          } Please manage the composition below ${selectedTccCompositionRegion?.is_restrictive ? "" : 'or click on "Add SKU" to add an SKU'}`}
        </p>

        {!selectedTccCompositionRegion?.is_restrictive && (
          <ButtonIconModal
            buttonIconClass="d-inline-block"
            description="Add SKU"
            title={""}
            buttonStyleClass="btn-primary"
            ref={modalRef}
          >
            <AddSkuCompositionModal
              parentModalRef={modalRef}
              handleAddSku={addSkuToComposition}
              handleAddComponents={handleAdditionOfComponents}
              editModalParams={editModalParams}
              confirmLabel="Add to SKU"
              informativeMessage="If the SKU is selected all of its composition is added. If it is
                not selected, the user can select the components to add"
            />
          </ButtonIconModal>
        )}
      </div>

      <AddEditSkuCompositionComponentForm
        handleAddComponents={handleAdditionOfComponents}
        component={component}
        setComponent={setComponent}
        savedSkuComposition={savedSkuComposition}
        selectedTccCompositionRegion={selectedTccCompositionRegion}
        tccComposition={tccComposition}
      />

      <SkuCompositionTree
        skuComposition={skuComposition}
        isMainPage={true}
        duplicateActionHandler={duplicateSku}
        changeQuantityActionHandler={changeComponentQuantity}
        handleRemoveNode={deleteCompositionNode}
        openEditModal={startEditProcess}
        shouldIncludeSku={false}
        manageSkuInclusion={undefined}
        manageComponentInclusion={undefined}
        assertComponentIsIncluded={undefined}
      />
    </>
  );
};

export default Composition;
