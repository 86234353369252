import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";
import { ISkuComposition } from "types/data.interface";

type CompareSkuColorsWithTccColorParams = {
  tccId: number;
  skuColorsIds: string[];
  shouldCheck: boolean;
};

type CheckSimilarTccsResponse = {
  are_different: boolean;
};

export const compareSkuColorsWithTccColor = async ({
  skuColorsIds,
  shouldCheck,
  axios,
  tccId,
}: CompareSkuColorsWithTccColorParams & { axios: AxiosInstance }) => {
  if (shouldCheck && skuColorsIds.length > 0) {
    try {
      const searchParams = URL.createSearchParams({
        tccId,
        skuColors: skuColorsIds,
      });

      const response = await axios.get<CheckSimilarTccsResponse>(
        `/compare-sku-colors-with-tcc/?${searchParams.toString()}`,
      );
      return Predicates.parsePageableReponseToType(response).are_different;
    } catch (err) {
      console.error(err);
    }
  }
  return false;
};

export default function useCompareSkuColorsWithTccColor({
  tccId,
  skuColorsIds,
  shouldCheck,
}: CompareSkuColorsWithTccColorParams) {
  const axios = useAxios();
  const [data, setData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await compareSkuColorsWithTccColor({
        skuColorsIds,
        shouldCheck,
        axios,
        tccId,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [tccId, shouldCheck]);

  return { data, isLoading };
}

export const getAllSkuComponentColors = (skuComposition: ISkuComposition) => {
  const moldedColors = skuComposition.molded_components
    .map((component) => component.color_id)
    .filter(Predicates.isNotNullAndNotUndefinedAndNotEmpty);
  const nonMoldedColors = skuComposition.non_molded_components
    .map((component) => component.color_id)
    .filter(Predicates.isNotNullAndNotUndefinedAndNotEmpty);
  return Array.from(new Set([...moldedColors, ...nonMoldedColors]));
};
