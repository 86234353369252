import { emptyValueFormat } from "../../helpers";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import ReactModal from "react-modal";
import { useContext, useMemo, useRef, useState } from "react";
import PictureModal from "../PictureModal";
import ButtonIconModal from "../ButtonIconModal";
import {
  GlobalProductTemplate,
  TCCTemplate,
} from "../../routes/skus/components/attributes-modal";
import { formatGlobalProductIntoOption } from "../../helpers/global-product.helper";
import { formatTccIntoOption } from "../../helpers/tcc.helper";
import { Predicates } from "../../libraries/predicates/predicates";
import ButtonModal, { ListingModalRef } from "components/ButtonModal";
import FTPDestinationModal from "components/modals/FTPDestinationModal";
import { useFTPDestination } from "hooks/useFTPDestination";
import { toast } from "react-toastify";
import useAxios from "utils/useAxios";
import {
  LIFECYCLE_STATUS_LABEL,
  PRODUCTION_STATUS_LABEL,
  SALES_STATUS_LABEL,
} from "constants/labels";
import AuthContext from "context/AuthContext";

export const PipHeader = ({ data }: { data: any }) => {
  const [showPictureModal, setShowPictureModal] = useState(false);
  const destinationModalRef = useRef<ListingModalRef>(null);
  const { data: ftpDestinations, error } = useFTPDestination();
  const axios = useAxios();
  const authContext = useContext(AuthContext);

  const closePictureModal = () => {
    setShowPictureModal(false);
  };

  const sendT25Handler = async () => {
    const endpoint = "/send-t25/";
    const result = await axios.post(endpoint, {
      sessid: authContext?.cookieSessionId,
      product_id: data?.id.replace(/^0+/, ""),
      location: data?.t25_area,
    });
    if (result.status === 200) {
      toast.success("Successfully sent T25");
    } else {
      toast.error("An internal error occurred. T25 not sent");
    }
  };

  const shouldAllowSendT25 = useMemo(
    () =>
      Predicates.isNotNullAndNotUndefined(data?.tcc) ||
      Predicates.isNotNullAndNotUndefined(data?.global_product) ||
      (Predicates.isNotNullAndNotUndefinedAndNotEmpty(data?.categ) &&
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(data?.sub_categ) &&
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(data?.prd_line)) ||
      (Predicates.isNotNullAndNotUndefinedAndNotEmpty(data?.old_categ) &&
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(data?.old_class) &&
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(data?.old_brand_name) &&
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(data?.old_brand_group)),
    [
      data?.tcc,
      data?.global_product,
      data?.categ,
      data?.sub_categ,
      data?.prd_line,
      data?.old_categ,
      data?.old_class,
      data?.old_brand_name,
      data?.old_brand_group,
    ],
  );

  return (
    <>
      <ReactModal
        isOpen={showPictureModal}
        className="custom-modal mold-comps"
        overlayClassName="custom-overlay"
        onRequestClose={closePictureModal}
      >
        <PictureModal handleCloseModal={closePictureModal} product={data} />
      </ReactModal>
      <div
        className={`main-container card shadow-sm gap-2 mb-2 d-flex flex-row justify-content-between align-items-start ${
          showPictureModal ? "unset-position" : ""
        }`}
      >
        <div className="left-section gap-1 d-flex flex-column">
          <div className="first-header-row d-flex flex-row justify-content-between">
            <div className="global-fields d-flex align-items-end w-100">
              <div className="d-flex flex-column field-container col-md-12 ">
                <div>
                  <label className="form-label mb-0">Global Product</label>
                  {(data?.tcc?.global_product || data?.global_product) && (
                    <ButtonIconModal
                      buttonIconClass="mx-3 d-inline-block"
                      description="show attributes"
                      title={`Global Product ${
                        formatGlobalProductIntoOption(
                          data?.tcc?.global_product ?? data.global_product,
                        ).label
                      }`}
                      icon={faCirclePlus}
                      iconClass="me-2"
                    >
                      <GlobalProductTemplate
                        model={data?.tcc?.global_product ?? data.global_product}
                      />
                    </ButtonIconModal>
                  )}
                </div>
                <textarea
                  value={emptyValueFormat(
                    data?.tcc?.global_product || data?.global_product
                      ? formatGlobalProductIntoOption(
                          data?.tcc?.global_product ?? data.global_product,
                        ).label
                      : "",
                  )}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>
            </div>

            <div className="tcc-fields d-flex align-items-end w-100">
              <div className="d-flex flex-column field-container col-md-12 ">
                <div>
                  <label className="form-label mb-0">TCC</label>
                  {data?.tcc && (
                    <ButtonIconModal
                      buttonIconClass="mx-3 d-inline-block"
                      description="show attributes"
                      title={`TCC ${formatTccIntoOption(data.tcc).label}`}
                      icon={faCirclePlus}
                      iconClass="me-2"
                    >
                      <TCCTemplate tccId={data.tcc?.id} />
                    </ButtonIconModal>
                  )}
                </div>
                <textarea
                  value={emptyValueFormat(
                    data?.tcc ? formatTccIntoOption(data.tcc).label : "",
                  )}
                  disabled={true}
                  rows={1}
                  className="form-control d-inline no-resize horizontal-scroll"
                />
              </div>
            </div>
          </div>

          <div className="header-row d-flex flex-row justify-content-between">
            <div className="d-flex flex-column field-container col-md-4 ">
              <Tooltip
                id="old-categ"
                className="tooltip-text-box"
                place="top-start"
                noArrow={true}
              />
              <label className="form-label mb-0">
                Category
                {data?.old_categ && (
                  <span
                    className="tooltip-span"
                    data-tooltip-id="old-categ"
                    data-tooltip-html={`Old Category: <strong>${data?.old_categ} - ${data?.old_categ_desc}</strong>`}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="tooltip-icon"
                    />
                  </span>
                )}
              </label>

              <textarea
                value={emptyValueFormat(
                  data?.global_product
                    ? `${data.global_product?.sub_category?.parent?.code} - ${data.global_product?.sub_category?.parent?.name}`
                    : data?.categ,
                )}
                disabled={true}
                rows={1}
                className="form-control no-resize horizontal-scroll"
              />
            </div>

            <div className="d-flex flex-column field-container col-md-4 ">
              <Tooltip
                id="old-class"
                className="tooltip-text-box"
                place="top-start"
                noArrow={true}
              />
              <label className="second-header-field form-label mr-3 mb-0">
                Sub-Category
                {data?.old_class && (
                  <span
                    className="tooltip-span"
                    data-tooltip-id="old-class"
                    data-tooltip-html={`Old Class: <strong>${data?.old_class} - ${data?.old_class_desc}</strong>`}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="tooltip-icon"
                    />
                  </span>
                )}
              </label>
              <textarea
                value={emptyValueFormat(
                  data?.global_product
                    ? `${data.global_product?.sub_category?.code} - ${data.global_product?.sub_category?.name}`
                    : data?.sub_categ,
                )}
                disabled={true}
                rows={1}
                className="form-control no-resize horizontal-scroll"
              />
            </div>

            <div className="d-flex flex-column field-container col-md-4 ">
              <Tooltip
                id="old-brand"
                className="tooltip-text-box"
                place="top-start"
                noArrow={true}
              />
              <label
                className={`form-label ${
                  data?.old_brand_name && data?.old_brand_group ? "mr-3" : null
                } mb-0`}
              >
                Product Line
                {data?.old_brand_name && data?.old_brand_group && (
                  <span
                    className="tooltip-span"
                    data-tooltip-id="old-brand"
                    data-tooltip-html={`Old Brand Group: <strong>${data?.old_brand_group}</strong> <br/> Old Brand Name: <strong>${data?.old_brand_name} - ${data?.old_brand_name_desc}</strong>`}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="tooltip-icon"
                    />
                  </span>
                )}
              </label>
              <textarea
                value={emptyValueFormat(
                  data?.global_product
                    ? `${data.global_product?.product_line?.code} - ${data.global_product?.product_line?.name}`
                    : data?.prd_line,
                )}
                disabled={true}
                rows={1}
                className="form-control no-resize horizontal-scroll"
              />
            </div>
          </div>

          <div className="header-row d-flex flex-row justify-content-between">
            <div className="d-flex flex-column field-container col-md-8">
              <label className="form-label mb-0">
                SKU Number / Description
              </label>
              <textarea
                value={"11 " + data?.id + " - " + data?.description}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="d-flex flex-column field-container col-md-4">
              <label className="form-label mb-0">TCC Item Number</label>
              <textarea
                value={data?.tcc?.item_number}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>

          <div className="header-row d-flex flex-row justify-content-between">
            <div className="d-flex flex-column field-container col-md-4">
              <Tooltip
                id="gp-line-status"
                className="tooltip-text-box"
                place="top-start"
                noArrow={true}
              />
              <label className="form-label mb-0">
                {`Global Product ${LIFECYCLE_STATUS_LABEL}`}{" "}
                {data?.global_product?.line_planning_status && (
                  <span
                    className="tooltip-span"
                    data-tooltip-id="gp-line-status"
                    data-tooltip-html={`<b>${PRODUCTION_STATUS_LABEL}: </b>${data?.global_product?.production_lifecycle_status} <br/> <b>${SALES_STATUS_LABEL}: </b>${data?.global_product?.sales_lifecycle_status}`}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="tooltip-icon"
                    />
                  </span>
                )}
              </label>

              <textarea
                value={emptyValueFormat(
                  data?.global_product?.line_planning_status,
                )}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="d-flex flex-column field-container col-md-4">
              <Tooltip
                id="tcc-line-status"
                className="tooltip-text-box"
                place="top-start"
                noArrow={true}
              />
              <label className="form-label mb-0">
                {`TCC ${LIFECYCLE_STATUS_LABEL}`}{" "}
                {data?.tcc?.line_planning_status && (
                  <span
                    className="tooltip-span"
                    data-tooltip-id="tcc-line-status"
                    data-tooltip-html={`<b>${PRODUCTION_STATUS_LABEL}: </b>${data?.tcc?.production_lifecycle_status} <br/> <b>${SALES_STATUS_LABEL}: </b>${data?.tcc?.sales_lifecycle_status}`}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="tooltip-icon"
                    />
                  </span>
                )}
              </label>

              <textarea
                value={emptyValueFormat(data?.tcc?.line_planning_status)}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>

            <div className="d-flex flex-column field-container col-md-4">
              <Tooltip
                id="sku-line-status"
                className="tooltip-text-box"
                place="top-start"
                noArrow={true}
              />
              <label className="form-label mb-0">
                {`SKU ${LIFECYCLE_STATUS_LABEL}`}{" "}
                {data?.line_planning_status && (
                  <span
                    className="tooltip-span"
                    data-tooltip-id="sku-line-status"
                    data-tooltip-html={`<b>${PRODUCTION_STATUS_LABEL}: </b>${data?.production_lifecycle_status} <br/> <b>${SALES_STATUS_LABEL}: </b>${data?.sales_lifecycle_status}`}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="tooltip-icon"
                    />
                  </span>
                )}
              </label>

              <textarea
                value={emptyValueFormat(data?.line_planning_status)}
                disabled={true}
                rows={1}
                className="form-control d-inline no-resize horizontal-scroll"
              />
            </div>
          </div>
        </div>

        <div className="right-section gap-1 d-flex flex-column">
          {data?.image_url || data?.image_file ? (
            <div className="img-container d-flex">
              <img
                className="d-flex"
                onClick={() => setShowPictureModal(true)}
                src={data?.image_url ? data?.image_url : data?.image_file}
                alt="Image of the product"
              />
            </div>
          ) : (
            <div className="img-placeholder d-flex justify-content-center align-items-center">
              <span className="fw-bold text-center text-gray-800">
                No Picture Available <br /> For This Product
              </span>
            </div>
          )}
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-row gap-4 header-buttons-container">
              {data?.send_t25_rights && (
                <span
                  className="d-inline-block"
                  tabIndex={0}
                  data-bs-toggle="tooltip"
                  data-bs-title="This SKU does not have any categorization, T25 cannot be send as this information is missing."
                >
                  {Predicates.isNotNullAndNotUndefined(ftpDestinations) &&
                  Predicates.isNullOrUndefined(error) &&
                  ftpDestinations?.find(
                    (item) => item.belongs_user_location,
                  ) ? (
                    <>
                      <ButtonModal
                        modalTitle="Send T25"
                        buttonDescription="Send T25"
                        disabled={Predicates.isNullOrUndefined(data?.tcc)}
                        buttonClass="btn btn-primary send-t25-button"
                        customModalStyle="popup"
                        ref={destinationModalRef}
                      >
                        <FTPDestinationModal
                          product_id={data?.id.replace(/^0+/, "")}
                          listingModalRef={destinationModalRef}
                        />
                      </ButtonModal>
                      <span className="send-t25-button-tooltip">
                        <span
                          className={`send-t25-button-tooltip ${
                            Predicates.isNullOrUndefined(data?.tcc)
                              ? "ms-2"
                              : "d-none"
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="tooltip-icon"
                          />
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-primary send-t25-button"
                        onClick={sendT25Handler}
                        disabled={!shouldAllowSendT25}
                      >
                        Send T25
                      </button>
                      <span className="send-t25-button-tooltip">
                        <span
                          className={`send-t25-button-tooltip ${
                            shouldAllowSendT25 ? "d-none" : "ms-2"
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="tooltip-icon"
                          />
                        </span>
                      </span>
                    </>
                  )}
                  <Tooltip
                    anchorSelect=".send-t25-button-tooltip"
                    className="tooltip-text-box"
                    place="top-start"
                    content="This SKU does not have any categorization, T25 cannot be send as this information is missing."
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
