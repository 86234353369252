import { Tab, Tabs } from "react-bootstrap";
import { PipHeader } from "../PipHeader";
import Spinner from "../../Spinner";
import { PipComponentsListing } from "../PipComponentsListing";
import { PipPackingInformation } from "../PipPackingInformation";
import { PipProductInformation } from "../PipProductInformation";
import PipLocalDescription from "../PipLocalDescription";
import PipBarcode from "../PipBarcode";
import { PipRelatedItems } from "../PipRelatedItems";
import { ProductInventoryTable } from "components/product-inventory/ProductInventoryTable";

export const PipTabsLayout = ({
  prodInfoData,
  compListData,
  packingInfoData,
  relatedItemsData,
  isPipLoading,
  areOtherTabsLoading,
  isGITabsLoading,
  productNumber,
  globalInfo,
  submitChanges,
  infoSavePending,
  setInfoSavePending,
  barcodes,
  barcodeTypes,
  barcodeSavePending,
  setBarcodeSavePending,
}: {
  prodInfoData: any;
  compListData: any;
  packingInfoData: any;
  relatedItemsData: any;
  isPipLoading: boolean;
  areOtherTabsLoading: boolean;
  isGITabsLoading: boolean;
  productNumber: string;
  globalInfo: any;
  submitChanges: any;
  infoSavePending: any;
  setInfoSavePending: any;
  barcodes: any;
  barcodeTypes: any;
  barcodeSavePending: any;
  setBarcodeSavePending: any;
}) => {
  return (
    <div className="product-information-page-container mt-1">
      <PipHeader data={prodInfoData} />

      <div className="product-information-tabs-container shadow-sm rounded mb-2">
        {true && (
          <Tabs
            defaultActiveKey="components-listing"
            id="productinfoTabs"
            className="pip-tabs-header rounded-top border-0 px-4 pt-2"
          >
            <Tab eventKey="components-listing" title="Components Listing">
              {isPipLoading ? (
                <Spinner />
              ) : (
                <PipComponentsListing
                  prodId={productNumber}
                  data={compListData}
                />
              )}
            </Tab>

            <Tab eventKey="packing-information" title="Packing Information">
              {areOtherTabsLoading ? (
                <Spinner />
              ) : (
                <PipPackingInformation data={packingInfoData} />
              )}
            </Tab>

            <Tab eventKey="product-information" title="Product Information">
              <PipProductInformation data={prodInfoData} />
            </Tab>

            <Tab
              eventKey="global-information-local-description"
              title="Local Description"
            >
              {isGITabsLoading ? (
                <Spinner />
              ) : (
                <PipLocalDescription
                  data={globalInfo}
                  submitChanges={submitChanges}
                  savePending={infoSavePending}
                  setSavePending={setInfoSavePending}
                />
              )}
            </Tab>

            <Tab eventKey="global-information-barcode" title="Barcode">
              <PipBarcode
                data={barcodes}
                barcodeTypes={barcodeTypes}
                productNumber={productNumber}
                submitChanges={submitChanges}
                savePending={barcodeSavePending}
                setSavePending={setBarcodeSavePending}
              />
            </Tab>

            {(prodInfoData?.tcc?.name ||
              prodInfoData?.global_product?.name) && (
              <Tab eventKey="related-items" title="Related Items">
                {areOtherTabsLoading ? (
                  <Spinner />
                ) : (
                  <PipRelatedItems
                    data={relatedItemsData ?? []}
                    globalProductName={prodInfoData?.global_product?.name}
                    tccName={prodInfoData?.tcc?.name}
                  />
                )}
              </Tab>
            )}

            <Tab
              eventKey="product-inventory"
              title="Product Inventory"
              className="accordion-body"
            >
              <ProductInventoryTable
                productId={prodInfoData?.id.replace(/^0+/, "")}
                isInModal={true}
                hasPermission={true}
              />
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
};
