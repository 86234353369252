import { emptyValueFormat } from "helpers";
import { ProductInventoryTable } from "./ProductInventoryTable";

const ProductInventoryModal = ({
  productId,
  productName,
  productStdpk,
}: {
  productId: string;
  productName: string;
  productStdpk: string;
}) => {
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div className="col-2 form-group">
          <label className="form-label">SKU nº</label>
          <input
            value={productId ? `11 ${productId}` : emptyValueFormat(productId)}
            disabled={true}
            type="text"
            className="form-control"
          />
        </div>

        <div className="col-8 form-group">
          <label className="form-label">SKU Description</label>
          <textarea
            value={emptyValueFormat(productName)}
            disabled={true}
            rows={1}
            className="form-control no-resize horizontal-scroll"
          />
        </div>

        <div className="col-2 form-group">
          <label className="form-label">Standard Pack</label>
          <input
            value={productStdpk ? productStdpk : "1"}
            disabled={true}
            type="text"
            className="form-control"
          />
        </div>
      </div>

      <div className="col-12 table-responsive product-inventory-modal-table-container">
        <ProductInventoryTable
          productId={productId}
          hasPermission={true}
          isInModal={true}
        ></ProductInventoryTable>
      </div>
    </>
  );
};

export default ProductInventoryModal;
