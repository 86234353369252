import { filterNonDigits, filterNonDigitsDecimal } from "../../helpers";
import {
  PmsSearchActionType,
  PmsSearchActionTypes,
  PmsSearchState,
} from "./data.type";

const initialState: PmsSearchState = {
  globalProduct: null,
  category: null,
  subCategory: null,
  productLine: null,
  productNumber: "",
  productDescription: "",
  tcc: null,
  molds: null,
  nonMolds: null,
  materials: null,
  colors: null,
  decoTech: null,
  artwork: null,
  productionLifecycleStatus: null,
  salesLifecycleStatus: null,
  linePlanningStatus: null,
  alwaysOn: null,
  brand: null,
  productCapacity: "",
  productCapacityUom: null,
  gpProductionLifecycleStatus: null,
  gpSalesLifecycleStatus: null,
  gpLinePlanningStatus: null,
  productColor: null,
  marketingMaterial: null,
  tccArtwork: null,
  channel: null,
  piecesSellingUnitsOperator: {
    value: "pieces_selling_units",
    label: "=",
  },
  piecesSellingUnits: "",
  consumerFacingName: "",
  licensor: null,
  licensedProperty: null,
  royaltyItem: null,
  flavorScent: null,
  globalStandardCost: "",
  globalMsrp: "",
  upc: "",
  gtin: "",
  tccProductionLifecycleStatus: null,
  tccSalesLifecycleStatus: null,
  tccLinePlanningStatus: null,
  itemNumber: "",
  sourcingType: null,
  vendor: null,
  tpsType: null,
  discontinued: null,
  buyer: null,
  releaseDateOperator: {
    value: "release_date",
    label: "=",
  },
  releaseDate: "",
  supplierCountry: null,
  stockReported: null,
};

function reducer(
  state: PmsSearchState,
  action: PmsSearchActionType,
): PmsSearchState {
  switch (action.type) {
    case PmsSearchActionTypes.SET_GLOBAL_PRODUCT:
      return {
        ...state,
        globalProduct: action.payload.globalProduct ?? null,
        category: action.payload.globalProduct?.sub_category?.parent ?? null,
        subCategory: action.payload.globalProduct?.sub_category ?? null,
        productLine: action.payload.globalProduct?.product_line ?? null,
        tcc: null,
      };
    case PmsSearchActionTypes.SET_CATEGORY:
      return {
        ...state,
        category: action.payload.category ?? null,
        subCategory: null,
        globalProduct: null,
        tcc: null,
      };
    case PmsSearchActionTypes.SET_SUB_CATEGORY:
      return {
        ...state,
        subCategory: action.payload.subCategory ?? null,
        category: action.payload.subCategory?.parent ?? null,
        globalProduct: null,
        tcc: null,
      };
    case PmsSearchActionTypes.SET_PRODUCT_LINE:
      return {
        ...state,
        productLine: action.payload.productLine ?? null,
        globalProduct: null,
        tcc: null,
      };
    case PmsSearchActionTypes.SET_PRODUCT_NUMBER:
      return {
        ...state,
        productNumber: filterNonDigits(action.payload.productNumber ?? ""),
      };
    case PmsSearchActionTypes.SET_PRODUCT_DESCRIPTION:
      return {
        ...state,
        productDescription: action.payload.productDescription ?? "",
      };
    case PmsSearchActionTypes.SET_TCC:
      return {
        ...state,
        tcc: action.payload.tcc ?? null,
        category:
          action.payload.tcc?.global_product?.sub_category?.parent ?? null,
        subCategory: action.payload.tcc?.global_product?.sub_category ?? null,
        productLine: action.payload.tcc?.global_product?.product_line ?? null,
        globalProduct: action.payload.tcc?.global_product ?? null,
      };
    case PmsSearchActionTypes.SET_MOLDS:
      return {
        ...state,
        molds: action.payload.molds ?? null,
      };
    case PmsSearchActionTypes.SET_NON_MOLDS:
      return {
        ...state,
        nonMolds: action.payload.nonMolds ?? null,
      };
    case PmsSearchActionTypes.SET_MATERIALS:
      return {
        ...state,
        materials: action.payload.materials ?? null,
      };
    case PmsSearchActionTypes.SET_COLORS:
      return {
        ...state,
        colors: action.payload.colors ?? null,
      };
    case PmsSearchActionTypes.SET_DECO_TECH:
      return {
        ...state,
        decoTech: action.payload.decoTech ?? null,
        artwork: null,
      };
    case PmsSearchActionTypes.SET_ARTWORK:
      return {
        ...state,
        artwork: action.payload.artwork ?? null,
      };
    case PmsSearchActionTypes.SET_PRODUCTION_LIFECYCLE_STATUS:
      return {
        ...state,
        productionLifecycleStatus:
          action.payload.productionLifecycleStatus ?? null,
      };
    case PmsSearchActionTypes.SET_SALES_LIFECYCLE_STATUS:
      return {
        ...state,
        salesLifecycleStatus: action.payload.salesLifecycleStatus ?? null,
      };
    case PmsSearchActionTypes.SET_LINE_PLANNING_STATUS:
      return {
        ...state,
        linePlanningStatus: action.payload.linePlanningStatus ?? null,
      };
    case PmsSearchActionTypes.SET_ALWAYS_ON:
      return {
        ...state,
        alwaysOn: action.payload.alwaysOn ?? null,
      };
    case PmsSearchActionTypes.SET_BRAND:
      return {
        ...state,
        brand: action.payload.brand ?? null,
      };
    case PmsSearchActionTypes.SET_PRODUCT_CAPACITY:
      return {
        ...state,
        productCapacity: filterNonDigitsDecimal(
          state.productCapacity,
          action.payload.productCapacity ?? "",
          5,
          3,
        ),
      };
    case PmsSearchActionTypes.SET_PRODUCT_CAPACITY_UOM:
      return {
        ...state,
        productCapacityUom: action.payload.productCapacityUom ?? null,
      };
    case PmsSearchActionTypes.SET_GP_PRODUCTION_LIFECYCLE_STATUS:
      return {
        ...state,
        gpProductionLifecycleStatus:
          action.payload.gpProductionLifecycleStatus ?? null,
      };
    case PmsSearchActionTypes.SET_GP_SALES_LIFECYCLE_STATUS:
      return {
        ...state,
        gpSalesLifecycleStatus: action.payload.gpSalesLifecycleStatus ?? null,
      };
    case PmsSearchActionTypes.SET_GP_LINE_PLANNING_STATUS:
      return {
        ...state,
        gpLinePlanningStatus: action.payload.gpLinePlanningStatus ?? null,
      };
    case PmsSearchActionTypes.SET_PRODUCT_COLOR:
      return {
        ...state,
        productColor: action.payload.productColor ?? null,
      };
    case PmsSearchActionTypes.SET_MARKETING_MATERIAL:
      return {
        ...state,
        marketingMaterial: action.payload.marketingMaterial ?? null,
      };
    case PmsSearchActionTypes.SET_TCC_ARTWORK:
      return {
        ...state,
        tccArtwork: action.payload.tccArtwork ?? null,
      };
    case PmsSearchActionTypes.SET_CHANNEL:
      return {
        ...state,
        channel: action.payload.channel ?? null,
      };
    case PmsSearchActionTypes.SET_PIECES_SELLING_UNITS_OPERATOR:
      return {
        ...state,
        piecesSellingUnitsOperator:
          action.payload.piecesSellingUnitsOperator ??
          initialState.piecesSellingUnitsOperator,
      };
    case PmsSearchActionTypes.SET_PIECES_SELLING_UNITS:
      return {
        ...state,
        piecesSellingUnits: filterNonDigits(
          action.payload.piecesSellingUnits ?? "",
        ),
      };
    case PmsSearchActionTypes.SET_CONSUMER_FACING_NAME:
      return {
        ...state,
        consumerFacingName: action.payload.consumerFacingName ?? "",
      };
    case PmsSearchActionTypes.SET_LICENSOR:
      return {
        ...state,
        licensor: action.payload.licensor ?? null,
        licensedProperty: null,
      };
    case PmsSearchActionTypes.SET_LICENSED_PROPERTY:
      return {
        ...state,
        licensedProperty: action.payload.licensedProperty ?? null,
        licensor: action.payload.licensedProperty?.licensor ?? null,
      };
    case PmsSearchActionTypes.SET_ROYALTY_ITEM:
      return {
        ...state,
        royaltyItem: action.payload.royaltyItem ?? null,
      };
    case PmsSearchActionTypes.SET_FLAVOR_SCENT:
      return {
        ...state,
        flavorScent: action.payload.flavorScent ?? null,
      };
    case PmsSearchActionTypes.SET_GLOBAL_STANDARD_COST:
      return {
        ...state,
        globalStandardCost: filterNonDigitsDecimal(
          state.globalStandardCost,
          action.payload.globalStandardCost ?? "",
          10,
          2,
        ),
      };
    case PmsSearchActionTypes.SET_GLOBAL_MSRP:
      return {
        ...state,
        globalMsrp: filterNonDigitsDecimal(
          state.globalMsrp,
          action.payload.globalMsrp ?? "",
          10,
          2,
        ),
      };
    case PmsSearchActionTypes.SET_UPC:
      return {
        ...state,
        upc: action.payload.upc ?? "",
      };
    case PmsSearchActionTypes.SET_GTIN:
      return {
        ...state,
        gtin: action.payload.gtin ?? "",
      };
    case PmsSearchActionTypes.SET_TCC_PRODUCTION_LIFECYCLE_STATUS:
      return {
        ...state,
        tccProductionLifecycleStatus:
          action.payload.tccProductionLifecycleStatus ?? null,
      };
    case PmsSearchActionTypes.SET_TCC_SALES_LIFECYCLE_STATUS:
      return {
        ...state,
        tccSalesLifecycleStatus: action.payload.tccSalesLifecycleStatus ?? null,
      };
    case PmsSearchActionTypes.SET_TCC_LINE_PLANNING_STATUS:
      return {
        ...state,
        tccLinePlanningStatus: action.payload.tccLinePlanningStatus ?? null,
      };
    case PmsSearchActionTypes.SET_ITEM_NUMBER:
      return {
        ...state,
        itemNumber: filterNonDigits(action.payload.itemNumber ?? ""),
      };
    case PmsSearchActionTypes.SET_SOURCING_TYPE:
      return {
        ...state,
        sourcingType: action.payload.sourcingType ?? null,
      };
    case PmsSearchActionTypes.SET_VENDOR:
      return {
        ...state,
        vendor: action.payload.vendor ?? null,
      };
    case PmsSearchActionTypes.SET_TPS_TYPE:
      return {
        ...state,
        tpsType: action.payload.tpsType ?? null,
      };
    case PmsSearchActionTypes.SET_DISCONTINUED:
      return {
        ...state,
        discontinued: action.payload.discontinued ?? null,
      };
    case PmsSearchActionTypes.SET_BUYER:
      return {
        ...state,
        buyer: action.payload.buyer ?? null,
      };
    case PmsSearchActionTypes.SET_RELEASE_DATE_OPERATOR:
      return {
        ...state,
        releaseDateOperator:
          action.payload.releaseDateOperator ??
          initialState.releaseDateOperator,
      };
    case PmsSearchActionTypes.SET_RELEASE_DATE:
      return {
        ...state,
        releaseDate: action.payload.releaseDate ?? "",
      };
    case PmsSearchActionTypes.SET_SUPPLIER_COUNTRY:
      return {
        ...state,
        supplierCountry: action.payload.supplierCountry ?? null,
      };
    case PmsSearchActionTypes.SET_STOCK_REPORTED:
      return {
        ...state,
        stockReported: action.payload.stockReported ?? null,
      };
    case PmsSearchActionTypes.SET_FILTERS_FROM_STORAGE:
      return { ...state, ...action.payload };
    case PmsSearchActionTypes.RESET:
      return initialState;
    default:
      throw Error("Unknown action: " + action.type);
  }
}

export const isStateEqualToInitialState = (state: PmsSearchState): boolean => {
  return Object.keys(initialState).every(
    (key) =>
      initialState[key as keyof PmsSearchState] ===
      state[key as keyof PmsSearchState],
  );
};

export const PmsSearchReducer = { initialState, reducer };
