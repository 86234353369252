import {
  ICompositionComponent,
  ICountry,
  IGlobalProduct,
  ITcc,
  ITccCompositionRestriction,
  ITccRequest,
  ITccRequestHistory,
} from "../types/data.interface";
import { URL } from "../libraries/http/url";
import { toast } from "react-toastify";
import { Predicates } from "../libraries/predicates/predicates";
import {
  CreateTccActionTypes,
  CreateTccState,
} from "../states/create-tcc/data.type";
import { Action } from "../types/state/action";
import { CreateTccActions } from "../states/create-tcc";
import { AxiosInstance } from "axios";
import { NavigateFunction } from "react-router-dom";
import { transformTccCompositionIntoRegionCompositions } from "./tcc-view.helper";
import { PRODUCTION_STATUS_LABEL, SALES_STATUS_LABEL } from "constants/labels";
import { generateNodeId } from "routes/skus/features/sku-create/sku-create.helper";

enum actionsList {
  "CREATED" = "CREATED",
  "SUBMITTED" = "SUBMITTED",
  "REJECTED" = "REJECTED",
  "APPROVED" = "APPROVED",
  "EDITED" = "EDITED",
  "SELECTED" = "SELECTED",
}

export type TccProcessCondition = {
  step: number;
  condition: (state: CreateTccState) => boolean;
  message: string;
  conditionLevel: "minimal" | "full";
};

export const getTccProcessConditions = (): TccProcessCondition[] => {
  return [
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefined(state.globalProduct),
      message: `<strong>Global Product</strong> field is required`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        state.globalProduct?.production_lifecycle_status === "Active",
      message: `<strong>Global Product</strong> field must have an Active ${PRODUCTION_STATUS_LABEL}`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefined(state.materialMarketing),
      message: `<strong>Material Marketing</strong> field is required`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefined(state.channel),
      message: `<strong>Channel</strong> field is required`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefined(state.pcsSellingUnits) &&
        state.pcsSellingUnits > 0,
      message: `<strong>No of Pieces in Selling Units (Sets)</strong> field is required and must be greater than 0`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefined(state.licensor)
          ? Predicates.isNotNullAndNotUndefined(state.licensedProperty)
          : true,
      message: `<strong>Licensed Property</strong> field is required since a <strong>Licensor</strong> was selected`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefined(state.decorationTechnique) &&
        state.decorationTechnique.id !== "0"
          ? Predicates.isNotNullAndNotUndefined(state.artwork)
          : true,
      message: `<strong>Artwork</strong> field is required since a <strong>Decoration Technique</strong> was selected`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(
          state.productionLifecycleStatus,
        ),
      message: `<strong>${PRODUCTION_STATUS_LABEL}</strong> field is required`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(
          state.salesLifecycleStatus,
        ),
      message: `<strong>${SALES_STATUS_LABEL}</strong> field is required`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) => isValidWeek(state.fromUseDate),
      message: `<strong>From</strong> field has to follow the format 20XX-WYY`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) => isValidWeek(state.toUseDate),
      message: `<strong>To</strong> field has to follow the format 20XX-WYY`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(state.tccReason),
      message: `<strong>Reason for TCC</strong> field is required`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        state.tccReason !== "Other" ||
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(state.otherTccReason),
      message: `<strong>Other Reason</strong> field is required since "Other" was selected as the <strong>Reason for TCC</strong>`,
      conditionLevel: "minimal",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefined(state.requestorMarket),
      message: `<strong>Country</strong> field is required`,
      conditionLevel: "minimal",
    },

    {
      step: 0,
      condition: (state: CreateTccState) => state.tccSuffix.length === 4,
      message: `<strong>TCC Code Suffix</strong> field is required and must have 4 characters`,
      conditionLevel: "full",
    },
    {
      step: 0,
      condition: (state: CreateTccState) => state.tccName.trim().length > 0,
      message: `<strong>TCC Name</strong> field is required`,
      conditionLevel: "full",
    },
    {
      step: 0,
      condition: (state: CreateTccState) =>
        Predicates.isNotNullAndNotUndefined(state.productColor),
      message: `<strong>Product Color</strong> field is required`,
      conditionLevel: "full",
    },
  ];
};

export const checkIfConditionsAreMet = (
  conditions: TccProcessCondition[],
  state: CreateTccState,
  levelRequired: "minimal" | "full",
  step: number,
): boolean => {
  return (
    conditions.filter(
      (condition) =>
        (levelRequired === "full"
          ? true
          : condition.conditionLevel === levelRequired) &&
        condition.step === step &&
        !condition.condition(state),
    ).length === 0
  );
};

export const getMissingConditionsMessage = (
  conditions: TccProcessCondition[],
  state: CreateTccState,
  levelRequired: "minimal" | "full",
  step: number,
): string => {
  const missingConditions = conditions.filter(
    (condition) =>
      (levelRequired === "full"
        ? true
        : condition.conditionLevel === levelRequired) &&
      condition.step === step &&
      !condition.condition(state),
  );
  let message =
    "You cannot proceed to the next step. The following requirements are missing <br> <ul class='mb-0'>";
  message += missingConditions
    .map((condition) => `<li>${condition.message}</li>`)
    .join("");
  return message + "</ul>";
};

function processRequestErrorIntoErrorToast(error: any) {
  const errorMessages = Object.values(error.response.data).flat() as string[];
  errorMessages.length > 0 && errorMessages.length < 10
    ? errorMessages.forEach((errorMessage) => toast.error(errorMessage))
    : toast.error("An unknown error occurred. Contact support.");
}

export const isValidWeek = (week: string) => {
  return Predicates.isNotNullAndNotUndefinedAndNotEmpty(week)
    ? /^(\d{4})-W(\d{2})$/.test(week)
    : true;
};

export const isTccValuesPageValid = () => {
  return true;
};

export const fetchRequest = async (axios: AxiosInstance, requestId: string) => {
  try {
    const response = await axios.get<ITccRequest>(`/request/${requestId}/`);

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const fetchRequestHistoryRecords = async (
  axios: AxiosInstance,
  requestId: string,
) => {
  try {
    const response = await axios.get<ITccRequestHistory[]>(
      `/request-history/?request__id=${requestId}`,
    );

    return response.data.map((record) => requestHistoryDTOToModel(record));
  } catch (err) {
    console.error(err);
  }
  return [];
};

export const fillStateWithRequestData = (
  request: ITccRequest | null,
  historyRecords: ITccRequestHistory[],
  tcc: ITcc | null,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
) => {
  if (Predicates.isNotNullAndNotUndefined(request)) {
    dispatch(
      CreateTccActions.setRequestSpecificDetails(
        request.id,
        request.status,
        request.created_by,
        request.revision,
        request.created_at,
        request.current_approver ?? "",
        request.current_approval_level ?? null,
        request.corresponding_object_id ?? null,
      ),
    );

    const requestData: Partial<CreateTccState> =
      Predicates.isNotNullAndNotUndefined(tcc)
        ? buildRequestDataFromTcc(
            tcc,
            request.data.tccReason,
            request.data.otherTccReason,
            request.country ?? request.data.requestorMarket,
          )
        : {
            ...request.data,
            productionLifecycleStatus:
              Predicates.isNotNullAndNotUndefinedAndNotEmpty(
                request.data.productionLifecycleStatus,
              )
                ? request.data.productionLifecycleStatus
                : "Active",
            salesLifecycleStatus:
              Predicates.isNotNullAndNotUndefinedAndNotEmpty(
                request.data.salesLifecycleStatus,
              )
                ? request.data.salesLifecycleStatus
                : "Active",
          };

    dispatch(
      CreateTccActions.setRequestData({
        ...requestData,
      }),
    );
    dispatch(CreateTccActions.addRequestHistoryRecords(historyRecords));
  }
};

const buildRequestDataFromTcc = (
  tcc: ITcc,
  mainTccReason: string | undefined,
  otherTccReason: string | undefined,
  country: ICountry | null | undefined,
): Partial<CreateTccState> => {
  return {
    tccName: tcc.name,
    tccSuffix: tcc.suffix,
    consumerFacingName: tcc.consumer_facing_name,
    upcCode: tcc.upc_12_digits_us,
    gtinCode: tcc.gtin_14_digits_row,
    pcsSellingUnits: tcc.pieces_in_selling_units,
    globalProduct: tcc.global_product,
    category: tcc.global_product.sub_category.parent,
    subCategory: tcc.global_product.sub_category,
    productLine: tcc.global_product.product_line,
    materialMarketing: tcc.marketing_material,
    flavorScent: tcc.flavor_scent,
    channel: tcc.channel,
    licensedProperty: tcc.licensed_property,
    licensor: tcc.licensed_property?.licensor,
    productColor: tcc.product_color,
    artwork: tcc.artwork,
    decorationTechnique: tcc.decoration_tech,
    color1: tcc.color_1,
    color2: tcc.color_2,
    fromUseDate: tcc.use_from,
    toUseDate: tcc.use_to,
    tccReason: mainTccReason,
    otherTccReason: otherTccReason,
    economicalQuantities: Predicates.isNotNullAndNotUndefined(
      tcc.economical_quantity,
    )
      ? String(tcc.economical_quantity)
      : "",
    units: Predicates.isNotNullAndNotUndefined(tcc.units)
      ? String(tcc.units)
      : "",
    sales: Predicates.isNotNullAndNotUndefined(tcc.sales)
      ? String(tcc.sales)
      : "",
    profit: Predicates.isNotNullAndNotUndefined(tcc.profit)
      ? String(tcc.profit)
      : "",
    salesResults: tcc.sales_force_result,
    tccRegionCompositions: transformTccCompositionIntoRegionCompositions(
      tcc.tcc_compositions ?? [],
    ),
    markets: tcc.markets,
    productionLifecycleStatus: tcc.production_lifecycle_status,
    salesLifecycleStatus: tcc.sales_lifecycle_status,
    requestorMarket: country,
  };
};

const requestIdIsPresent = (request: CreateTccState): boolean => {
  return Predicates.isNotNullAndNotUndefinedAndNotEmpty(
    request.requestId.toString(),
  );
};

export const saveRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
) => {
  const requestHasId = requestIdIsPresent(request);
  const method = requestHasId ? "put" : "post";
  const requestUrl = requestHasId
    ? `/request/${request.requestId}/`
    : `/request/`;
  try {
    setIsInProgress(true);

    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.CREATED,
        action_user: username,
        context: 1,
        data: buildRequestData(request),
        country: request.requestorMarket?.id ?? null,
      },
    });

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    if (Predicates.isNotNullAndNotUndefined(response.data.history)) {
      const newHistoryRecord = requestHistoryDTOToModel(response.data.history);
      dispatch(
        CreateTccActions.addRequestHistoryRecords([
          newHistoryRecord,
          ...request.requestHistory,
        ]),
      );
    }

    dispatch(CreateTccActions.setUnsavedChanges(false));

    toast.success(`Request ${response.data.id} saved successfully`);
    setIsInProgress(false);
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const submitRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const requestHasId = requestIdIsPresent(request);
  const method = requestHasId ? "put" : "post";
  const requestUrl = requestHasId
    ? `/request/${request.requestId}/`
    : `/request/`;
  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.SUBMITTED,
        action_user: username,
        context: 1,
        data: buildRequestData(request),
        country: request.requestorMarket?.id ?? null,
        comment: request.submitComment,
      },
    });

    // Requests the BE to send the necessary emails for the request submission. We don't wait for this call to finish to not block the user
    axios({
      method: "post",
      url: `/send-request-mails/`,
      data: {
        action: actionsList.SUBMITTED,
        action_user: username,
        request_id: response.data.id,
      },
    });

    dispatch(CreateTccActions.setUnsavedChanges(false));

    toast.success(`Request ${response.data.id} submitted successfully`);
    setIsInProgress(false);
    navigate("/tccs/create?should_create=true", { replace: true });
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const approveRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const requestHasId = requestIdIsPresent(request);
  const method = requestHasId ? "put" : "post";
  const requestUrl = requestHasId
    ? `/request/${request.requestId}/`
    : `/request/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.APPROVED,
        action_user: username,
        context: 1,
        data: buildRequestData(request),

        country: request.requestorMarket?.id ?? null,
        comment: request.approveComment,
      },
    });

    // Requests the BE to send the necessary emails for the request approval. We don't wait for this call to finish to not block the user
    axios({
      method: "post",
      url: `/send-request-mails/`,
      data: {
        action: actionsList.APPROVED,
        action_user: username,
        request_id: response.data.id,
      },
    });

    dispatch(CreateTccActions.setUnsavedChanges(false));

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    if (Predicates.isNotNullAndNotUndefined(response.data.object_id)) {
      toast.success(
        `Request ${response.data.id} approved successfully. TCC created.`,
      );
      toast.info(`Redirecting to the TCC details page`);
    } else {
      toast.success(`Request ${response.data.id} approved successfully.`);
    }
    setIsInProgress(false);

    navigate(
      Predicates.isNotNullAndNotUndefined(response.data.object_id)
        ? `/tccs/view?id=${response.data.object_id}`
        : "/tccs/approvals",
      { replace: true },
    );
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const rejectRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const method = "put";
  const requestUrl = `/request/${request.requestId}/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.REJECTED,
        action_user: username,
        context: 1,
        data: buildRequestData(request),
        country: request.requestorMarket?.id ?? null,
        reason: request.rejectReason?.id ?? null,
        comment: request.rejectComment,
      },
    });

    // Requests the BE to send the necessary emails for the request rejection. We don't wait for this call to finish to not block the user
    axios({
      method: "post",
      url: `/send-request-mails/`,
      data: {
        action: actionsList.REJECTED,
        action_user: username,
        request_id: response.data.id,
      },
    });

    dispatch(CreateTccActions.setUnsavedChanges(false));

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    toast.success(`Request ${response.data.id} rejected successfully`);
    setIsInProgress(false);
    navigate("/tccs/approvals", { replace: true });
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const selectRequest = async (
  axios: AxiosInstance,
  requestId: string,
  username: string,
  country: ICountry | null,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const method = "patch";
  const requestUrl = `/request/${requestId}/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.SELECTED,
        action_user: username,
        context: 1,
        country: country?.id ?? null,
      },
    });

    toast.success(`Request ${response.data.id} selected successfully`);
    setIsInProgress(false);
    navigate(`/tccs/create?request_id=${response.data.id.toString()}`, {
      replace: true,
    });
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const reOpenRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const method = "put";
  const requestUrl = `/request/${request.requestId}/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.CREATED,
        action_user: username,
        context: 1,
        country: request.requestorMarket?.id ?? null,
      },
    });

    dispatch(CreateTccActions.setTccSuffix(""));

    dispatch(CreateTccActions.setUnsavedChanges(false));

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    if (Predicates.isNotNullAndNotUndefined(response.data.history)) {
      const newHistoryRecord = requestHistoryDTOToModel(response.data.history);
      dispatch(
        CreateTccActions.addRequestHistoryRecords([
          newHistoryRecord,
          ...request.requestHistory,
        ]),
      );
    }

    toast.success("Request re-opened successfully");
    navigate(`/tccs/create?request_id=${response.data.id.toString()}`, {
      replace: true,
    });
    setIsInProgress(false);
  } catch (err) {
    console.error(err);
    processRequestErrorIntoErrorToast(err);
    setIsInProgress(false);
  }
};

export const reOpenRequestFromRequestsTable = async (
  axios: AxiosInstance,
  requestId: number,
  username: string,
  country: ICountry | null,
  navigate: NavigateFunction,
) => {
  const method = "put";
  const requestUrl = `/request/${requestId}/`;

  try {
    await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.CREATED,
        action_user: username,
        context: 1,
        country: country?.id ?? null,
      },
    });

    toast.success("Request re-opened successfully");
    navigate(`/tccs/create?request_id=${requestId.toString()}`);
  } catch (err) {
    console.error(err);
    toast.error("An error occurred while re-opening the request");
  }
};

export const editRequest = async (
  axios: AxiosInstance,
  request: CreateTccState,
  username: string,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  setIsInProgress: (isLoading: boolean) => void,
  navigate: NavigateFunction,
) => {
  const method = "put";
  const requestUrl = `/request/${request.requestId}/`;

  try {
    setIsInProgress(true);
    const response = await axios({
      method: method,
      url: requestUrl,
      data: {
        action: actionsList.EDITED,
        action_user: username,
        context: 1,
        country: request.requestorMarket?.id ?? null,
        data: buildRequestData(request),
        comment: request.approveComment,
      },
    });

    dispatch(CreateTccActions.setTccSuffix(""));

    dispatch(CreateTccActions.setUnsavedChanges(false));

    dispatch(
      CreateTccActions.setRequestDetaislAfterAction(
        response.data.id.toString(),
        response.data.status,
        response.data.created_by,
        response.data.request_date,
      ),
    );

    const newHistoryRecord = requestHistoryDTOToModel(response.data.history);
    dispatch(
      CreateTccActions.addRequestHistoryRecords([
        newHistoryRecord,
        ...request.requestHistory,
      ]),
    );

    toast.success("TCC edited successfully");
    toast.info(`Redirecting to the TCC details page`);
    setIsInProgress(false);
    navigate(`/tccs/view?id=${response.data.object_id}`, { replace: true });
  } catch (err) {
    console.error(err);
    setIsInProgress(false);
    processRequestErrorIntoErrorToast(err);
  }
};

export const fillSuffixWithSuggestion = async (
  globalProduct: IGlobalProduct | null,
  dispatch: (
    action: Action<CreateTccActionTypes, Partial<CreateTccState>>,
  ) => void,
  axios: AxiosInstance,
) => {
  if (Predicates.isNotNullAndNotUndefined(globalProduct)) {
    dispatch(
      CreateTccActions.setTccSuffix(
        await getTccSuffixSuggestion(globalProduct, axios),
      ),
    );
  } else {
    dispatch(CreateTccActions.setTccSuffix(""));
  }
};

export const getTccSuffixSuggestion = async (
  globalProduct: IGlobalProduct,
  axios: AxiosInstance,
): Promise<string> => {
  const searchParams = URL.createSearchParams({
    type: "T",
    global_product_id: globalProduct.id,
  });
  try {
    const response = await axios.get<string>(
      `/new-suffix/?${searchParams.toString()}`,
    );
    const newSuffix: string = response.data;
    if (newSuffix === "-1") {
      toast.warning(
        "There might not exist any more available suffixes for this Global Product",
      );
      return "";
    } else {
      return newSuffix;
    }
  } catch (err) {
    toast.error(
      "Something unexpected happened while retrieving the next suffix value. Proceed normally",
    );
    return "";
  }
};

export const buildRequestData = (request: CreateTccState) => {
  const {
    requestHistory: requestApprovalHistory,
    requestStatus,
    requestId,
    revision,
    requestDate,
    requester,
    approvedDate,
    approver,
    rejectComment,
    rejectReason,
    approveComment,
    currentApprover,
    hasUnsavedChanges,
    submitComment,
    currentApprovalLevel,
    tccId,
    ...relevantData
  } = request;
  const tccReason =
    relevantData.tccReason === "Other"
      ? relevantData.otherTccReason
      : relevantData.tccReason;
  return JSON.stringify({ ...relevantData, reason: tccReason });
};

const requestHistoryDTOToModel = (dto: any): ITccRequestHistory => {
  return {
    status: dto.status.status,
    comment: Predicates.isNotNullAndNotUndefined(dto.comment)
      ? dto.comment
      : "",
    rejectReason: Predicates.isNotNullAndNotUndefined(dto.reason)
      ? dto.reason.reason
      : "",
    timestamp: dto.date,
    user: dto.user.id,
    approved_level: dto.approved_level?.description ?? "",
  };
};

export const hasRepeatedRestrictionId = (
  restrictionId: number,
  currentRestrictions: ITccCompositionRestriction[],
) => {
  return currentRestrictions.some(
    (restriction) => restriction.restrictionId === restrictionId,
  );
};

export const transformSkuCompositionIntoTccCompositionRestrictions = (
  skuComponents: ICompositionComponent[],
): ITccCompositionRestriction[] => {
  return skuComponents.map((component) => {
    return {
      restrictionId: generateNodeId(),
      pcs: String(component.nr_pieces),
      componentType: component.type,
      mold: component.full_objects?.mold,
      nonMold: component.full_objects?.non_mold,
      color: component.full_objects?.color,
      decorTech: component.full_objects?.decoration_technique,
      artwork: component.full_objects?.artwork,
    };
  });
};
