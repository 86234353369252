import { InformationProps } from "./Information";
import { HierarchyInfoSection } from "../../components/hierarchy-info";
import { filterNonDigits, filterNonDigitsDecimal } from "../../../../helpers";

const ProductPackaging = ({
  selectedGP,
  selectedTcc,
  skuData,
  setSkuData,
  setUnsavedChangesToTrue,
}: InformationProps) => {
  const handleStandardPackChange = (e: any) => {
    setSkuData({ ...skuData, stdpk: filterNonDigits(e.target.value) });
    setUnsavedChangesToTrue();
  };

  const handleStandardPackWeightChange = (e: any) => {
    setSkuData({
      ...skuData,
      stdpk_weight: filterNonDigitsDecimal(
        skuData.stdpk_weight,
        e.target.value,
        4,
        2,
      ),
    });
    setUnsavedChangesToTrue();
  };

  const handleStandardPackNetWeightChange = (e: any) => {
    setSkuData({
      ...skuData,
      cntwgt: filterNonDigitsDecimal(skuData.cntwgt, e.target.value, 4, 2),
    });
    setUnsavedChangesToTrue();
  };

  const handleStandardPackLengthChange = (e: any) => {
    setSkuData({ ...skuData, stdpk_length: filterNonDigits(e.target.value) });
    setUnsavedChangesToTrue();
  };

  const handleStandardPackWidthChange = (e: any) => {
    setSkuData({ ...skuData, stdpk_width: filterNonDigits(e.target.value) });
    setUnsavedChangesToTrue();
  };

  const handleStandardPackHeightChange = (e: any) => {
    setSkuData({ ...skuData, stdpk_height: filterNonDigits(e.target.value) });
    setUnsavedChangesToTrue();
  };

  return (
    <>
      <HierarchyInfoSection tcc={selectedTcc} gp={selectedGP} />

      <div className="card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">Carton Information</h6>
      </div>

      <div className=" justify-content-between">
        <div className="row align-items-end">
          <div className="col-2">
            <label className="form-label ">
              Standard Pack <span className="red-text fw-bold">*</span>
            </label>
            <div className="d-flex align-items-center">
              <input
                onChange={handleStandardPackChange}
                value={skuData.stdpk}
                maxLength={6}
                type="text"
                className="form-control d-inline"
              />
            </div>
          </div>
          <div className="col-3">
            <label className="form-label">
              Standard Pack Gross Weight (kg){" "}
              <span className="red-text fw-bold">*</span>
            </label>
            <div className="d-flex align-items-center">
              <input
                onChange={handleStandardPackWeightChange}
                value={skuData.stdpk_weight}
                type="text"
                className="form-control d-inline"
              />
            </div>
          </div>
          <div className="col-3">
            <label className="form-label">Standard Pack Net Weight (kg)</label>
            <div className="d-flex align-items-center">
              <input
                onChange={handleStandardPackNetWeightChange}
                value={skuData.cntwgt}
                type="text"
                className="form-control d-inline"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="row pb-1 ">
              <div className="col label-3 bottom-border ">
                <label className="form-label ">
                  <strong> Standard Pack Size </strong>
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-4 label-3">
                <label className="form-label">
                  L (mm) <span className="red-text fw-bold">*</span>
                </label>
                <div className="d-flex align-items-center">
                  <input
                    onChange={handleStandardPackLengthChange}
                    value={skuData.stdpk_length}
                    maxLength={6}
                    type="text"
                    className="form-control d-inline"
                  />
                </div>
              </div>
              <div className="col-4 label-3">
                <label className="form-label">
                  W (mm) <span className="red-text fw-bold">*</span>
                </label>
                <div className="d-flex align-items-center">
                  <input
                    onChange={handleStandardPackWidthChange}
                    value={skuData.stdpk_width}
                    maxLength={6}
                    type="text"
                    className="form-control d-inline"
                  />
                </div>
              </div>
              <div className="col-4 label-3">
                <label className="form-label ">
                  H (mm) <span className="red-text fw-bold">*</span>
                </label>
                <div className="d-flex align-items-center">
                  <input
                    onChange={handleStandardPackHeightChange}
                    value={skuData.stdpk_height}
                    maxLength={6}
                    type="text"
                    className="form-control d-inline"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPackaging;

//WILL BE USED IN LATER ITERATIONS
/*
<div>
        <div className="card-header py-3 my-3 rounded d-flex ">
          <h6 className="m-0 text-primary fw-bold">Packaging Procedure</h6>
        </div>

        <div className="col-lg-3 form-group">
          <label className="form-label">Associated Active Packaging Procedure</label>
          <Select
            // options={ }
            // onChange={ }
            // formatOptionLabel={OptionFormatter}
            // value={ }
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            // isLoading={isLoading}
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null
            }}
          />
        </div>

      </div>
*/
