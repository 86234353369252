import ButtonIconModal from "components/ButtonIconModal";
import ProductInventoryModal from "./ProductInventoryModal";

const ProductInventoryCellRenderer = ({
  productId,
  productName,
  productStdpk,
}: {
  productId: string;
  productName: string;
  productStdpk: string;
}) => {
  return (
    <ButtonIconModal
      buttonIconClass="d-flex justify-content-between"
      description="INVENTORY"
      title={`Inventory - SKU nº 11 ${productId}`}
      iconClass="me-2"
    >
      <ProductInventoryModal
        productId={productId}
        productName={productName}
        productStdpk={productStdpk}
      />
    </ButtonIconModal>
  );
};

export default ProductInventoryCellRenderer;
