import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Predicates } from "libraries/predicates/predicates";

const LinePlanningStatusCellRenderer = ({ data }: any) => {
  return Predicates.isNotNullAndNotUndefinedAndNotEmpty(
    data.line_planning_status,
  ) ? (
    <div className="d-flex align-items-center">
      <label className="mb-0">{data.line_planning_status}</label>
      <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon ml-2" />
    </div>
  ) : null;
};

export default LinePlanningStatusCellRenderer;
