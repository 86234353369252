import {
  formatGlobalProductIntoOption,
  getGlobalProductCode,
} from "../../../../helpers/global-product.helper";
import {
  formatTccIntoOption,
  getTccCode,
} from "../../../../helpers/tcc.helper";
import { ProductMaster } from "../../../../hooks/useProductMaster";
import {
  IGlobalProduct,
  IPisRequest,
  IRequestorMarket,
  ISkuComposition,
  ISkuInformation,
  ITcc,
  ITccComposition,
  TccCompositionRegionInfo,
} from "../../../../types/data.interface";
import { SkuCompositionTree } from "../../components/sku-composition-tree";
import ButtonIconModal from "../../../../components/ButtonIconModal";
import {
  GlobalProductTemplate,
  TCCTemplate,
} from "../../components/attributes-modal";
import {
  faCircleInfo,
  faCirclePlus,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { PictureModal } from "../../components/modals";
import { numberOfTextAreaRows } from "../../../../helpers";
import { formatSupplierCountryIntoOption } from "../../../../helpers/supplier-country.helper";
import { formatTpsTypeIntoOption } from "../../../../helpers/tps-type.helper";
import { TccValuesImportTable } from "routes/skus/components/tcc-values-import";
import { isSkuCompositionEmpty } from "./sku-create.helper";
import { Predicates } from "libraries/predicates/predicates";
import { useState } from "react";
import { ExpandableSection } from "../../components/expandable-section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { calculateLinePlanningStatus } from "helpers/line-planning-status.helper";
import { Tooltip } from "react-tooltip";
import { useLinePlanningStatusConditionsContext } from "context/LinePlanningStatusConditionsContext";
import {
  LIFECYCLE_STATUS_LABEL,
  PRODUCTION_STATUS_LABEL,
  SALES_STATUS_LABEL,
} from "constants/labels";
import useCompareSkuColorsWithTccColor, {
  getAllSkuComponentColors,
} from "hooks/useCompareSkuColorsWithTccColor";
import Banner from "components/Banner";

export type SummaryProps = {
  selectedPM: ProductMaster | null;
  selectedGP: IGlobalProduct | null;
  selectedTcc: ITcc | null;
  savedSkuComposition: ISkuComposition;
  skuData: ISkuInformation;
  requestorMarket: IRequestorMarket;
  pisRequest: IPisRequest;
  tccComposition: ITccComposition[];
  selectedTccCompositionRegion: TccCompositionRegionInfo | null;
  isFinished: boolean;
};

const Summary = ({
  selectedPM,
  selectedGP,
  selectedTcc,
  savedSkuComposition,
  skuData,
  requestorMarket,
  pisRequest,
  tccComposition,
  selectedTccCompositionRegion,
  isFinished,
}: SummaryProps) => {
  const [compositionHasHighlights, setCompositionHasHighlights] =
    useState<boolean>(false);

  const { data: linePlanningStatusConditions } =
    useLinePlanningStatusConditionsContext();

  const { data: isSkuColorsDifferentFromTccColor } =
    useCompareSkuColorsWithTccColor({
      shouldCheck:
        !isFinished && Predicates.isNotNullAndNotUndefined(selectedTcc),
      tccId: selectedTcc?.id ?? 0,
      skuColorsIds: getAllSkuComponentColors(savedSkuComposition),
    });

  return (
    <>
      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">Summary</h6>
      </div>
      <div className="row">
        <div className="col-4">
          <label className="form-label">Request Status</label>
          <input
            value={pisRequest.status}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-4">
          <label className="form-label">Request nº</label>
          <input
            value={pisRequest.pis_request_id ? pisRequest.pis_request_id : "-"}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-4">
          <label className="form-label">Revision nº</label>
          <input
            value={pisRequest.revision}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-4">
          <label className="form-label">Request Location</label>
          <input
            value={`${requestorMarket.short_name}, ${requestorMarket.full_name}`}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-2">
          <label className="form-label">Request Date</label>
          <input
            value={pisRequest.request_date}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-2">
          <label className="form-label">Requester</label>
          <input
            value={pisRequest.requested_by ?? "-"}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-2">
          <label className="form-label">{`${
            pisRequest.status === "REJECTED" ? "Rejected" : "Approved"
          } Date`}</label>
          <input
            value={
              pisRequest.status === "APPROVED" ||
              pisRequest.status === "REJECTED"
                ? pisRequest.dtchng ?? "-"
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-2">
          <label className="form-label">Approver</label>
          <input
            value={
              pisRequest.status === "APPROVED" ||
              pisRequest.status === "REJECTED"
                ? pisRequest.uidchng ?? "-"
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-2">
          <label className="form-label">SKU nº</label>
          <input
            value={
              pisRequest.finish_goods_no_assigned
                ? `11 ${pisRequest.finish_goods_no_assigned}`
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
        <div className="col-6">
          <label className="form-label">
            SKU Description <span className="red-text fw-bold">*</span>
          </label>
          <textarea
            value={skuData.name}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-4">
          <label className="form-label">Cloned from SKU</label>
          <textarea
            value={
              selectedPM && selectedPM?.id && selectedPM?.description
                ? `11 ${selectedPM?.id} - ${selectedPM?.description?.trim()}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>
      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">SKU Hierarchy</h6>
      </div>
      <div className="row">
        <div className="col-4">
          <label className="form-label">
            Category <span className="red-text fw-bold">*</span>
          </label>
          <textarea
            value={
              selectedGP
                ? `${selectedGP.sub_category.parent?.code} - ${selectedGP.sub_category.parent?.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-4">
          <label className="form-label">
            Sub-Category <span className="red-text fw-bold">*</span>
          </label>
          <textarea
            value={
              selectedGP
                ? `${selectedGP.sub_category.code} - ${selectedGP.sub_category.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
        <div className="col-4">
          <label className="form-label">
            Product Line <span className="red-text fw-bold">*</span>
          </label>
          <textarea
            value={
              selectedGP
                ? `${selectedGP.product_line.code} - ${selectedGP.product_line.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-6">
          <label className="form-label">
            Global Product <span className="red-text fw-bold">*</span>
          </label>
          {selectedGP ? (
            <ButtonIconModal
              buttonIconClass="mx-3 d-inline-block"
              description="show attributes"
              title={`Global Product ${
                formatGlobalProductIntoOption(selectedGP).label
              }`}
              icon={faCirclePlus}
              iconClass="me-2"
            >
              <GlobalProductTemplate model={selectedGP} />
            </ButtonIconModal>
          ) : (
            <></>
          )}
          <textarea
            value={
              selectedGP
                ? `${getGlobalProductCode(selectedGP)} - ${selectedGP.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-6">
          <label className="form-label">
            TCC{" "}
            {pisRequest.status !== "CREATED" && (
              <span className="red-text fw-bold">*</span>
            )}
          </label>
          {selectedTcc ? (
            <>
              <ButtonIconModal
                buttonIconClass="mx-3 d-inline-block"
                description="show attributes"
                title={`TCC ${formatTccIntoOption(selectedTcc).label}`}
                icon={faCirclePlus}
                iconClass="me-2"
              >
                <TCCTemplate tccId={selectedTcc.id} />
              </ButtonIconModal>

              <ButtonIconModal
                buttonIconClass="mx-3 d-inline-block"
                description="show picture"
                title={`TCC ${formatTccIntoOption(selectedTcc).label}`}
                icon={faImage}
                iconClass="me-2"
              >
                <PictureModal src={selectedTcc.product_photo} />
              </ButtonIconModal>
            </>
          ) : (
            <></>
          )}
          <textarea
            value={
              selectedTcc
                ? `${getTccCode(selectedTcc)} - ${selectedTcc.name}`
                : "-"
            }
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>
      <div className="card card-header my-2 rounded d-flex flex-row justify-content-start align-items-center">
        <h6 className="m-0 text-primary fw-bold">{`SKU Composition `}</h6>
        {compositionHasHighlights &&
          !selectedTccCompositionRegion?.is_restrictive && (
            <span className="ml-2 font-italic">
              (The values highlighted in yellow are different from the TCC data)
            </span>
          )}
        {compositionHasHighlights &&
          selectedTccCompositionRegion?.is_restrictive && (
            <span className="ml-2 font-italic">
              (The colors highlighted in yellow are different from the TCC data
              for the respective mold / sequence)
            </span>
          )}
      </div>

      {!isSkuCompositionEmpty(savedSkuComposition) && (
        <SkuCompositionTree
          skuComposition={savedSkuComposition}
          isSummaryPage={true}
          isMainPage={false}
          shouldIncludeSku={false}
          manageSkuInclusion={() => {}}
          manageComponentInclusion={() => {}}
          assertComponentIsIncluded={() => {}}
          tccComposition={
            Predicates.isNotNullAndNotUndefined(selectedTccCompositionRegion)
              ? tccComposition
              : []
          }
          highlightsCheckType={
            selectedTccCompositionRegion?.is_restrictive
              ? "restrictive"
              : "not-restrictive"
          }
          setCompositionHasHighlights={() => setCompositionHasHighlights(true)}
        />
      )}

      {!isFinished && isSkuColorsDifferentFromTccColor ? (
        <Banner
          classname="mt-3"
          message={
            "There are components colors different from the selected TCC product color."
          }
          type="warning"
        />
      ) : (
        <></>
      )}

      <ExpandableSection
        title={`TCC Data ${tccComposition.length > 0 && Predicates.isNotNullAndNotUndefined(selectedTccCompositionRegion) ? `(${selectedTccCompositionRegion.region.name} - ${selectedTccCompositionRegion.is_restrictive ? "Restrictive" : "Not Restrictive"})` : ""}`}
        sectionId={"tcc-values"}
        className="mb-2"
        expandedByDefault
      >
        {tccComposition.length > 0 && (
          <TccValuesImportTable
            tccComposition={tccComposition}
            selectedRowsIds={[]}
            selectedMoldIndexes={[]}
            setSelectedCompositionRows={() => {}}
            setUsedMoldIndexes={() => {}}
            setChangedImportValues={() => {}}
            isInApprovalProcess={true}
            isRevision={Number(pisRequest.revision) > 0}
            isSummaryPage
          />
        )}
      </ExpandableSection>
      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">SKU Information</h6>
      </div>
      <div className="mt-1">
        <div className="row align-items-end">
          <div className="col-3">
            <label className="form-label">
              Release Date <span className="red-text fw-bold">*</span>
            </label>
            <input
              value={skuData.reldt ? skuData.reldt : "-"}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>

          <div className="col-3">
            <label className="form-label ">
              Product Use <span className="red-text fw-bold">*</span>
            </label>
            <input
              value={skuData.product_use}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>

          <div className="col-3">
            <label className="form-label">
              Gift Box <span className="red-text fw-bold">*</span>
            </label>
            <input
              value={skuData.giftbox === "N" ? "No" : "Yes"}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>

          <div className="col-3">
            <label className="form-label">Project ID</label>
            <input
              value={skuData.project_id}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-4">
          <label className="form-label">
            TPS Product <span className="red-text fw-bold">*</span>
          </label>
          <input
            value={skuData.tps_product === "N" ? "No" : "Yes"}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-4">
          <label className="form-label">
            Vendor
            {skuData.tps_product === "Y" && (
              <span className="red-text fw-bold"> *</span>
            )}
          </label>
          <input
            value={
              skuData.tps_loc_id && skuData.tps_loc_id.id !== "0"
                ? formatSupplierCountryIntoOption(skuData.tps_loc_id).label
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-4">
          <label className="form-label">
            TPS Type
            {skuData.tps_product === "Y" && (
              <span className="red-text fw-bold"> *</span>
            )}
          </label>
          <input
            value={
              skuData.tps_type_id && skuData.tps_type_id.res_pis_type_id !== "0"
                ? formatTpsTypeIntoOption(skuData.tps_type_id).label
                : "-"
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-md-4 form-group">
          <label className="form-label">
            {PRODUCTION_STATUS_LABEL}{" "}
            {Number(pisRequest.revision) > 0 && (
              <span className="red-text fw-bold">*</span>
            )}
          </label>
          <input
            value={
              skuData.production_lifecycle_status
                ? skuData.production_lifecycle_status
                : ""
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-md-4 form-group">
          <label className="form-label">
            {SALES_STATUS_LABEL}{" "}
            {Number(pisRequest.revision) > 0 && (
              <span className="red-text fw-bold">*</span>
            )}
          </label>
          <input
            value={
              skuData.sales_lifecycle_status
                ? skuData.sales_lifecycle_status
                : ""
            }
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-md-4 form-group">
          <Tooltip
            id="line-status"
            className="tooltip-text-box"
            place="right"
            noArrow={true}
          />
          <label className="form-label">{LIFECYCLE_STATUS_LABEL}</label>
          <span
            className="tooltip-span"
            data-tooltip-id="line-status"
            data-tooltip-html={`The ${LIFECYCLE_STATUS_LABEL.toLowerCase()} results from the combination of <br/> the ${PRODUCTION_STATUS_LABEL.toLowerCase()} and ${SALES_STATUS_LABEL.toLowerCase()}.`}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon" />
          </span>
          <input
            value={calculateLinePlanningStatus(
              linePlanningStatusConditions,
              skuData.production_lifecycle_status,
              skuData.sales_lifecycle_status,
            )}
            type="text"
            className="form-control"
            disabled={true}
          />
        </div>
      </div>

      <div className="row mt-1 align-items-end">
        <div className="col-4">
          <label className="form-label">Product Gross Weight (g)</label>
          <div className="d-flex align-items-center">
            <input
              value={skuData.pgrwgt}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>
        </div>

        <div className="col-4">
          <label className="form-label">Product Net Weight (g)</label>
          <div className="d-flex align-items-center">
            <input
              value={skuData.pntwgt}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>
        </div>

        <div className="col-4">
          <div className="pb-1">
            <div className="label-3 bottom-border">
              <label className="form-label">
                <strong> Product Dimension</strong>
              </label>
            </div>
          </div>
          <div className="d-flex">
            <div className="col-4 label-3 pl-0">
              <label className="form-label">L (mm)</label>
              <input
                value={skuData.length}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
            <div className="col-4 label-3">
              <label className="form-label">W (mm)</label>
              <input
                value={skuData.width}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
            <div className="col-4 label-3 pr-0">
              <label className="form-label">H (mm)</label>
              <input
                value={skuData.height}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col">
          <label className="form-label">Comment</label>
          <textarea
            value={skuData.comments}
            rows={numberOfTextAreaRows(skuData.comments.split("\n"))}
            className="form-control d-inline"
            disabled={true}
          />
        </div>
      </div>

      <div className="card card-header my-2 rounded d-flex justify-content-between">
        <h6 className="m-0 text-primary fw-bold">Product Packaging</h6>
      </div>
      <div className="row align-items-end mt-1 justify-content-between mb-2">
        <div className="col-2">
          <label className="form-label ">
            Standard Pack <span className="red-text fw-bold">*</span>
          </label>
          <input
            value={skuData.stdpk}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-3">
          <label className="form-label">
            Standard Pack Gross Weight (kg){" "}
            <span className="red-text fw-bold">*</span>
          </label>
          <input
            value={skuData.stdpk_weight}
            type="text"
            className="form-control d-inline"
            disabled={true}
          />
        </div>

        <div className="col-3">
          <label className="form-label">Standard Pack Net Weight (kg)</label>
          <div className="d-flex align-items-center">
            <input
              value={skuData.cntwgt}
              type="text"
              className="form-control d-inline"
              disabled={true}
            />
          </div>
        </div>

        <div className="col-4">
          <div className="row pb-1 ">
            <div className="col label-3 bottom-border">
              <label className="form-label ">
                <strong> Standard Pack Size </strong>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-4 label-3">
              <label className="form-label">
                L (mm) <span className="red-text fw-bold">*</span>
              </label>
              <input
                value={skuData.stdpk_length}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
            <div className="col-4 label-3">
              <label className="form-label">
                W (mm) <span className="red-text fw-bold">*</span>
              </label>
              <input
                value={skuData.stdpk_width}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
            <div className="col-4 label-3">
              <label className="form-label ">
                H (mm) <span className="red-text fw-bold">*</span>
              </label>
              <input
                value={skuData.stdpk_height}
                type="text"
                className="form-control d-inline"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;

// WILL BE USED IN LATER ITERATIONS
/*
<label className="form-label mt-1">Associated Active Packaging Procedure</label>
      <div className="d-flex align-items-center mb-3">
        <input
          // value={ }
          type="text"
          className="form-control d-inline"
          disabled={true}
        />
      </div>
*/
