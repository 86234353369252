import { ListingModalRef } from "components/ButtonModal";
import TextFieldWithLimit from "components/text-fields/TextFieldWithLimit";
import { useCreateTccStore } from "context/CreateTccContext";
import { RefObject } from "react";
import { CreateTccActions } from "states/create-tcc";

const EditConfirmationModal = ({
  onConfirm,
  parentModalRef,
}: {
  onConfirm: (...args: any) => any;
  parentModalRef: RefObject<ListingModalRef>;
}) => {
  const { state, dispatch } = useCreateTccStore();

  const handleEditComment = (item: any) => {
    dispatch(CreateTccActions.setApproveComment(item.target.value));
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: 250 }}>
      <span className="mb-2 text-center black-text">
        Before finalizing the TCC edition you can leave a comment about the edit
        <strong> (optional)</strong>
      </span>
      <div className="row">
        <TextFieldWithLimit
          spacing="col-12"
          label="Comment"
          limit={1000}
          value={state.approveComment}
          onChange={handleEditComment}
          invalidMessage="The comment must have a maximum of 1000 characters"
        />
      </div>

      <div className="d-flex justify-content-end mt-auto gap-3">
        <input
          type="submit"
          onClick={parentModalRef.current?.closeModal}
          className="btn btn-secondary"
          value={"Cancel"}
        />

        <input
          type="submit"
          onClick={onConfirm}
          className="btn btn-primary"
          value={"Finish Edit"}
          disabled={state.approveComment.length > 1000}
        />
      </div>
    </div>
  );
};

export default EditConfirmationModal;
