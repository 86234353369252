import {
  LIFECYCLE_STATUS_LABEL,
  PRODUCTION_STATUS_LABEL,
  SALES_STATUS_LABEL,
} from "constants/labels";
import { IGlobalProduct } from "../../../../types/data.interface";

const GlobalProduct = ({ model }: { model: IGlobalProduct }) => {
  return (
    <>
      <div className="row ml-2 mr-2 mb-3">
        <div className="col-4">
          <label className="form-label">Always on</label>
          <textarea
            value={model?.is_always_on ? "Yes" : "No"}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-4">
          <label className="form-label">Brand</label>
          <textarea
            value={model?.brand?.name ?? ""}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-4">
          <label className="form-label">Product Capacity</label>
          <textarea
            value={`${model?.capacity_value ?? ""} ${model?.capacity_uom}`}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>

      <div className="row ml-2 mr-2 mb-3">
        <div className="col-4">
          <label className="form-label">{PRODUCTION_STATUS_LABEL}</label>
          <textarea
            value={`${model?.production_lifecycle_status ?? ""}`}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-4">
          <label className="form-label">{SALES_STATUS_LABEL}</label>
          <textarea
            value={`${model?.sales_lifecycle_status ?? ""}`}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>

        <div className="col-4">
          <label className="form-label">{LIFECYCLE_STATUS_LABEL}</label>
          <textarea
            value={`${model?.line_planning_status ?? ""}`}
            disabled={true}
            rows={1}
            className="form-control d-inline no-resize horizontal-scroll"
          />
        </div>
      </div>

      {/* <div className="row ml-2 mr-2 mb-3">
                <div className="col-4">
                    <label className="form-label">Sourcing Type</label>
                    <div className="d-flex align-items-center">
                        <input
                            value={model.sourcing_type}
                            type="text"
                            className="form-control d-inline"
                            disabled
                        />
                    </div>
                </div>
                <div className="col-4">
                    <label className="form-label">Vendor</label>
                    <div className="d-flex align-items-center">
                        <input
                            value={model.vendor ? `${model.vendor.location_id} - ${model.vendor.name}` : ""}
                            type="text"
                            className="form-control d-inline"
                            disabled
                        />
                    </div>
                </div>
                <div className="col-4">
                    <label className="form-label">TPS Type</label>
                    <div className="d-flex align-items-center">
                        <input
                            value={model.tps_type ? `${model.tps_type.res_pis_type_id} - ${model.tps_type.name}` : ""}
                            type="text"
                            className="form-control d-inline"
                            disabled
                        />
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default GlobalProduct;
