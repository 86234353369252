import Spinner from "components/Spinner";
import { Predicates } from "libraries/predicates/predicates";
import { useEffect, useState } from "react";
import { ProductInventory } from "types/data.interface";
import useAxios from "utils/useAxios";

export const ProductInventoryTable = ({
  productId,
  hasPermission,
  isInModal,
}: {
  productId: string;
  hasPermission: boolean;
  isInModal?: boolean;
}) => {
  const axios = useAxios();

  const [productInventoryData, setProductInventoryData] = useState<
    ProductInventory[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProductInventoryAsync = async () => {
    setIsLoading(true);
    if (Predicates.isNotNullAndNotUndefinedAndNotEmpty(productId)) {
      try {
        const response = await axios.get<ProductInventory[]>(
          `/product-master/product-inventory?product__id=${productId}`,
        );
        setProductInventoryData(response.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProductInventoryAsync();
  }, []);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <table
            className={`table table-striped mb-1 ${isInModal ? "table-bordered" : ""}`}
          >
            <thead>
              <tr>
                <th rowSpan={2}>Sold By</th>
                <th rowSpan={2}>Country</th>
                <th rowSpan={2}>Sets in stock</th>
                <th rowSpan={2}>3 Month Forecast</th>
                <th colSpan={5} className="text-center">
                  Actual Sales for Month One
                </th>
                <th rowSpan={2} className="pl-4" style={{ minWidth: 150 }}>
                  Sets Available to Promise <br />{" "}
                  <small className="fw-bold">
                    [Sets in Stock - 3 Month Forecast]
                  </small>
                </th>
                <th rowSpan={2}>
                  Reported On <br /> [yyyymmdd]
                </th>
              </tr>
              <tr>
                <th>Week 1</th>
                <th>Week 2</th>
                <th>Week 3</th>
                <th>Week 4</th>
                <th>Week 5</th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {!hasPermission ? (
                <tr className="text-center fw-bold fst-italic">
                  <td colSpan={11}>
                    Sorry, You Have No Rights To See These Data
                  </td>
                </tr>
              ) : productInventoryData.length > 0 ? (
                productInventoryData &&
                productInventoryData.map((record: any) => {
                  return (
                    <>
                      <tr>
                        <td>{record.carton}</td>
                        <td>{record.country_short_name}</td>
                        <td>{record.stock}</td>
                        <td>{record.three_months_forecast}</td>
                        <td>{record.sold_wk1}</td>
                        <td>{record.sold_wk2}</td>
                        <td>{record.sold_wk3}</td>
                        <td>{record.sold_wk4}</td>
                        <td>{record.sold_wk5}</td>
                        <td>{record.available_to_promise}</td>
                        <td>{record.reported_on.replaceAll("-", "")}</td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr className="text-center fw-bold fst-italic">
                  <td colSpan={11}>No Stock Was Reported For This SKU</td>
                </tr>
              )}
            </tbody>
          </table>

          <h6 className="fw-bold red-text mt-3 ml-1">
            The number of the inventory is the number of sets, even if the item
            is sold by carton
          </h6>
        </>
      )}
    </>
  );
};
