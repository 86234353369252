import { Sku } from "../types/data.interface";

export const formatSkuIntoOption = (item: Sku) => ({
  value: item,
  label: `${item.id} - ${item.name}`,
});

export const getSku = (skus: Sku[]) => skus.map(formatSkuIntoOption);

export const getMessageForSimilarSkus = (
  skus: Array<number>,
  isForToast: boolean,
) => {
  let last = null;

  let similarSkus = skus.map((item) =>
    isForToast
      ? `11 ${item}`
      : `<a href=${`${process.env.PUBLIC_URL}/product-information/?productnumber=${String(item).replace(/^0+/, "")}`} target="_blank">11 ${item}</a>`,
  );

  last = similarSkus[similarSkus.length - 1];

  if (isForToast) {
    return `SKU(s) nº ${
      similarSkus.length > 1
        ? `${similarSkus.slice(0, -1).join(", ")} and ${last}`
        : last
    } already exist with the same composition. Please make sure you want to proceed with this SKU creation.`;
  } else {
    return `SKU(s) nº ${
      similarSkus.length > 1
        ? `${similarSkus.slice(0, -1).join(", ")} and ${last}`
        : last
    } <strong>already exist with the same composition</strong>.<br>Please make sure you want to proceed with this SKU creation.`;
  }
};
