const Banner = ({
  message,
  classname,
  type,
}: {
  message: string;
  classname?: string;
  type: "warning" | "info" | "danger";
}) => {
  return (
    <>
      <div
        className={`alert alert-${type} ${classname ?? ""}`}
        role="alert"
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </>
  );
};

export default Banner;
