import { useEffect, useState } from "react";
import { IGlobalProduct } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { URL } from "../libraries/http/url";
import { PageableDTO } from "../types/pageable";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type SearchParams = {
  category?: number;
  sub_category?: number;
  product_line?: number;
  search?: string;
  id?: number;
  production_lifecycle_status?: string;
  sales_lifecycle_status?: string;
};

type GlobalProductResponse = PageableDTO<IGlobalProduct[]> | IGlobalProduct[];

export const fetchGlobalProductById = async ({
  id,
  axios,
}: {
  id: number | null | undefined;
  axios: AxiosInstance;
}) => {
  if (Predicates.isNullOrUndefined(id)) return null;
  try {
    const response = await axios.get<IGlobalProduct>(`/global-products/${id}`);

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const fetchGlobalProduct = async ({
  product_line,
  sub_category,
  category,
  search,
  id,
  production_lifecycle_status,
  sales_lifecycle_status,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      limit: 75,
      product_line,
      sub_category,
      sub_category__parent: category,
      search,
      production_lifecycle_status,
      sales_lifecycle_status,
    });

    const response = await axios.get<GlobalProductResponse>(
      `/global-products/${id ?? ""}?${searchParams.toString()}`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useGlobalProduct({
  product_line,
  sub_category,
  category,
  search,
  id,
  production_lifecycle_status,
  sales_lifecycle_status,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<IGlobalProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchGlobalProduct({
        product_line,
        sub_category,
        category,
        search,
        production_lifecycle_status,
        sales_lifecycle_status,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [
    product_line,
    sub_category,
    category,
    id,
    search,
    production_lifecycle_status,
    sales_lifecycle_status,
  ]);

  return { data, isLoading };
}
