import { ReactNode, createContext } from "react";
import {
  finalizePisRequestApproval,
  sendPisRequestEmails,
} from "../hooks/usePisRequest";
import useAxios from "../utils/useAxios";

export interface SkuCreationApprovalContextInterface {
  finalizeSkuCreationProcess: (
    requestId: string,
    revision: number,
    skuId: string,
    userId: string,
    marketId: number,
  ) => void;
  sendPisRequestEmailsBasedOnAction: (
    action: string,
    requestId: string,
    revision: number,
    userId: string,
    skuId?: string,
  ) => void;
}

const SkuCreationApprovalContext = createContext<
  SkuCreationApprovalContextInterface | any
>(null);
export default SkuCreationApprovalContext;

export const SkuCreationApprovalProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const axios = useAxios();

  const finalizeSkuCreationProcess = async (
    requestId: string,
    revision: number,
    skuId: string,
    userId: string,
    marketId: number,
  ) => {
    await finalizePisRequestApproval({
      requestId,
      revision,
      skuId,
      userId,
      marketId,
      axios,
    });
  };

  const sendPisRequestEmailsBasedOnAction = async (
    action: string,
    requestId: string,
    revision: number,
    userId: string,
    skuId?: string,
  ) => {
    await sendPisRequestEmails({
      action,
      requestId,
      revision,
      userId,
      skuId,
      axios,
    });
  };

  const contextData: SkuCreationApprovalContextInterface = {
    finalizeSkuCreationProcess,
    sendPisRequestEmailsBasedOnAction,
  };

  return (
    <SkuCreationApprovalContext.Provider value={contextData}>
      {children}
    </SkuCreationApprovalContext.Provider>
  );
};
