import Select from "react-select";
import OptionFormatter from "../../../../components/OptionFormatter";
import { useCreateTccStore } from "context/CreateTccContext";
import { CreateTccActions } from "states/create-tcc";
import { Predicates } from "libraries/predicates/predicates";
import {
  formatRequestStatusReasonIntoOption,
  getRequestStatusReason,
} from "helpers/request-status-reason.helper";
import useRequestStatusReasons from "hooks/useRequestStatusReason";
import { RefObject } from "react";
import { ListingModalRef } from "components/ButtonModal";
import TextFieldWithLimit from "components/text-fields/TextFieldWithLimit";

const RejectConfirmationModal = ({
  onConfirm,
  parentModalRef,
}: {
  onConfirm: (...args: any) => any;
  parentModalRef: RefObject<ListingModalRef>;
}) => {
  const { state, dispatch } = useCreateTccStore();
  const { data: reasons, isLoading: isReasonsLoading } =
    useRequestStatusReasons({ context: "TCC", status: "REJECTED" });

  const handleRejectComment = (item: any) => {
    dispatch(CreateTccActions.setRejectComment(item.target.value));
  };
  const handleRejectReason = (item: any) => {
    dispatch(CreateTccActions.setRejectReason(item?.value ?? null));
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: 350 }}>
      <span className="mb-2 text-center black-text">
        Before rejecting you have to specify a reason and leave a comment
        related to the rejection <strong>(mandatory)</strong>
      </span>
      <div className="row">
        <div className="col-4 form-group">
          <label className="form-label">
            Reject Reason <span className="red-text fw-bold">*</span>
          </label>
          <Select
            options={getRequestStatusReason(reasons)}
            onChange={handleRejectReason}
            value={formatRequestStatusReasonIntoOption(state.rejectReason)}
            placeholder=""
            formatOptionLabel={OptionFormatter}
            classNamePrefix="react-select"
            isClearable
            components={{
              IndicatorSeparator: () => null,
            }}
            isLoading={isReasonsLoading}
          />
        </div>
        <TextFieldWithLimit
          spacing="col-8"
          label="Comment"
          limit={1000}
          value={state.rejectComment}
          onChange={handleRejectComment}
          invalidMessage="The comment must have a maximum of 1000 characters"
          isRequired={true}
        />
      </div>

      <div className="d-flex justify-content-end mt-auto gap-3">
        <input
          type="submit"
          onClick={parentModalRef.current?.closeModal}
          className="btn btn-secondary"
          value={"Cancel"}
        />

        <input
          type="submit"
          onClick={onConfirm}
          className="btn btn-danger"
          value={"Reject"}
          disabled={
            state.rejectComment.trim().length === 0 ||
            state.rejectComment.trim().length > 1000 ||
            Predicates.isNullOrUndefined(state.rejectReason)
          }
        />
      </div>
    </div>
  );
};

export default RejectConfirmationModal;
