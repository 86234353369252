import { useEffect, useState } from "react";
import { ILinePlanningStatusCondition } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { PageableDTO } from "../types/pageable";
import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";

type LinePlanningStatusConditionResponse =
  | PageableDTO<ILinePlanningStatusCondition[]>
  | ILinePlanningStatusCondition[];

export const fetchLinePlanningStatusCondition = async ({
  axios,
}: {
  axios: AxiosInstance;
}) => {
  try {
    const response = await axios.get<LinePlanningStatusConditionResponse>(
      `/line-planning-status-conditions/`,
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.error(err);
  }
  return [];
};

export default function useLinePlanningStatusCondition() {
  const axios = useAxios();
  const [data, setData] = useState<ILinePlanningStatusCondition[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchLinePlanningStatusCondition({
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, []);

  return { data, isLoading };
}
