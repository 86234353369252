import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { PipListLayout } from "../components/product-information-page/pip-layouts/PipListLayout";
import { PipTabsLayout } from "../components/product-information-page/pip-layouts/PipTabsLayout";
import AuthContext from "../context/AuthContext";
import ProductContext, {
  ProductContextInterface,
} from "../context/ProductContext";
import { reorderGlobalInfo } from "../helpers";
import { URL } from "../libraries/http/url";
import {
  IBarcode,
  IGlobalInfo,
  ISkuComposition,
} from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { useBeforeUnload } from "../utils/useBeforeUnload";

const ProductInformationPage = () => {
  const axios = useAxios();
  const authCtx = useContext(AuthContext);
  const { setProductDetails, setUnsavedChanges, pipLayout } =
    useContext<ProductContextInterface>(ProductContext);

  const [isLayoutChanging, setIsLayoutChanging] = useState<boolean>(false);
  const user = authCtx?.user;
  const hasPermission = authCtx?.hasPermission;
  const navigate = useNavigate();

  //Product Information Page
  const [isPipLoading, setIsPipLoading] = useState(false);
  const [areOtherTabsLoading, setAreOtherTabsLoading] = useState(false);

  const [prodInfoData, setProdInfoData] = useState<any>();
  const [compListData, setCompListData] = useState<ISkuComposition>({
    id: "",
    name: "",
    skus: [],
    molded_components: [],
    non_molded_components: [],
  });
  const [packingInfoData, setpackingInfoData] = useState<any>();
  const [relatedItemsData, setRelatedItemsData] = useState<any[]>();

  //Global Information Tabs integration
  const [isGITabsLoading, setIsGITabsLoading] = useState(false);
  const [globalInfo, setGlobalInfo] = useState<IGlobalInfo[] | []>([]);
  const [barcodes, setBarcodes] = useState<IBarcode[] | []>([]);
  const [barcodeTypes, setBarcodeTypes] = useState<any[]>([]);
  const [barcodeSavePending, setBarcodeSavePending] = useState<boolean>(false);
  const [infoSavePending, setInfoSavePending] = useState<boolean>(false);

  let [searchParams] = useSearchParams();
  //gets the product number from the URL and removes the leading zeros
  const productNumber =
    searchParams.get("productnumber")?.replace(/^0+/, "") ?? "";

  useEffect(() => {
    getProductInformationData();

    if (hasPermission("api.view_productinformation")) {
      fetchGlobalInfo();
    }
    if (hasPermission("api.view_productbarcode")) {
      fetchBarcodeTypes();
      fetchBarcodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setUnsavedChanges({
      existsUnsavedChanges: infoSavePending || barcodeSavePending,
      message: "You may have unsaved changes. Are you sure you want to leave?",
    });
  }, [infoSavePending, barcodeSavePending]);

  useEffect(() => {
    setIsLayoutChanging(true);
    setTimeout(() => setIsLayoutChanging(false), 500);
  }, [pipLayout]);

  //Product Information Page
  const getProductInformationData = async () => {
    const endpoint = `/product-information?id=${productNumber}`;
    const endpoint2 = `/sku-composition/${productNumber}`;

    try {
      // Product Information Tab Data
      setIsPipLoading(true);
      const [{ data: prodInfoDataRes }, { data: compListDataRes }] =
        await Promise.all([axios.get(endpoint), axios.get(endpoint2)]);
      setProdInfoData(prodInfoDataRes[0]);
      setCompListData(compListDataRes);

      const pp_id = prodInfoDataRes[0]?.pp_id ?? "";
      const globalProductId = prodInfoDataRes[0]?.global_product?.id ?? "";
      const tccId = prodInfoDataRes[0]?.tcc?.id ?? "";

      setProductDetails({
        productId: prodInfoDataRes[0]?.id.replace(/^0+/, ""),
        description: prodInfoDataRes[0]?.description,
        parent_code: prodInfoDataRes[0]?.parent_code,
        stdpk: prodInfoDataRes[0]?.stdpk,
      });
      setIsPipLoading(false);

      //Components Listing and Packing Information Tabs Data
      setAreOtherTabsLoading(true);
      let dataPromises: Promise<AxiosResponse>[] = [];
      if (pp_id) {
        const endpoint3 = `/primary-packing-materials?pp_id=${pp_id}`;
        dataPromises.push(axios.get(endpoint3));
      }

      if (globalProductId || tccId) {
        const searchParams = URL.createSearchParams({
          tcc__global_product__id: globalProductId,
          tcc__id: tccId,
        });
        const endpoint4 = `/related-items?${searchParams.toString()}`;
        dataPromises.push(axios.get(endpoint4));
      }

      const returnedValues = await Promise.all(dataPromises);
      let returnValuesIndex: number = 0;

      let primaryPackingMaterials: any[] = [];
      if (pp_id) {
        const { data: packingInfoDataRes } = returnedValues[returnValuesIndex];
        primaryPackingMaterials = [...packingInfoDataRes];
        returnValuesIndex++;
      }

      if (globalProductId || tccId) {
        const { data: relatedItemsDataRes } = returnedValues[returnValuesIndex];
        setRelatedItemsData([...relatedItemsDataRes]);
        returnValuesIndex++;
      }

      setpackingInfoData({
        primary_packing_materials: primaryPackingMaterials,
        pp_id: prodInfoDataRes[0]?.pp_id,
        product_width: prodInfoDataRes[0]?.width,
        product_length: prodInfoDataRes[0]?.length,
        product_height: prodInfoDataRes[0]?.height,
        stdpk: prodInfoDataRes[0]?.stdpk,
        stdpk_weight: prodInfoDataRes[0]?.stdpk_weight,
        stdpk_length: prodInfoDataRes[0]?.stdpk_length,
        stdpk_width: prodInfoDataRes[0]?.stdpk_width,
        stdpk_height: prodInfoDataRes[0]?.stdpk_height,
        stdpk_net_weight: prodInfoDataRes[0]?.stdpk_net_weight,
        product_net_weight: prodInfoDataRes[0]?.product_net_weight,
        product_gross_weight: prodInfoDataRes[0]?.product_gross_weight,
      });
      setAreOtherTabsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  //Global Information Tabs integration
  const fetchGlobalInfo = async () => {
    setIsGITabsLoading(true);
    try {
      const response = await axios.get(
        `global-information/?ordering=created_at&product=${productNumber}`,
      );
      // Pinns "en-US" to top
      const reordered = reorderGlobalInfo(response.data);
      setGlobalInfo(reordered);
      setIsGITabsLoading(false);
    } catch (error) {
      console.log(error);
      setIsGITabsLoading(false);
    }
  };

  const fetchBarcodes = async () => {
    setIsGITabsLoading(true);
    try {
      const response = await axios.get(
        `global-barcodes/?ordering=location&product_number=${productNumber}`,
      );
      setBarcodes(response.data);
      setIsGITabsLoading(false);
    } catch (error) {
      console.log(error);
      setIsGITabsLoading(false);
    }
  };

  const fetchBarcodeTypes = async () => {
    setIsGITabsLoading(true);
    try {
      const response = await axios.get(`global-barcodes-types/`);
      setBarcodeTypes(response.data);
    } catch (error) {
      console.log(error);
      setIsGITabsLoading(false);
    }
  };

  const submitChanges = async (
    newData: any[],
    actiontype = "global-information",
  ) => {
    try {
      setIsGITabsLoading(true);
      const url =
        actiontype === "barcodes" ? `global-barcodes/` : `global-information/`;
      const key =
        actiontype === "barcodes"
          ? "updated_barcodes"
          : "updated_local_descriptions";
      const updateData =
        actiontype === "barcodes"
          ? newData.map((item) => ({ ...item, full_type: item.full_type.id }))
          : newData;
      const payload = {
        [key]: updateData,
        product_number: productNumber,
      };
      const response = await axios({
        method: "post",
        url,
        data: payload,
      });
      if (response.status === 201) {
        if (actiontype === "barcodes") {
          toast.success(`Successfully saved Barcodes`);
          fetchBarcodes();
          setBarcodeSavePending(false);
        } else {
          toast.success(`Successfully saved Local descriptions`);
          fetchGlobalInfo();
          setInfoSavePending(false);
        }
      }
      setIsGITabsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(
        `Save unsuccessful, ensure that you are not submitting duplicate values`,
      );
      setIsGITabsLoading(false);
    }
  };

  useBeforeUnload({
    condition: barcodeSavePending || infoSavePending,
    message: "You may have unsaved changes. Are you sure you want to leave?",
  });

  return (
    <>
      {isPipLoading || isLayoutChanging ? (
        <Spinner />
      ) : //   <div className="product-information-page-container mt-1">
      //     <PipHeader data={prodInfoData} />

      //     <div className="product-information-tabs-container shadow-sm rounded mb-2">
      //       {true && (
      //         <Tabs
      //           defaultActiveKey="components-listing"
      //           id="productinfoTabs"
      //           className="pip-tabs-header rounded-top border-0 px-4 pt-2"
      //         >
      //           <Tab eventKey="product-information" title="Product Information">
      //             <PipProductInformation data={prodInfoData} />
      //           </Tab>

      //           {(prodInfoData?.tcc?.name ||
      //             prodInfoData?.global_product?.name) && (
      //             <Tab eventKey="related-items" title="Related Items">
      //               {areOtherTabsLoading ? (
      //                 <Spinner />
      //               ) : (
      //                 <PipRelatedItems
      //                   data={relatedItemsData ?? []}
      //                   globalProductName={prodInfoData?.global_product?.name}
      //                   tccName={prodInfoData?.tcc?.name}
      //                 />
      //               )}
      //             </Tab>
      //           )}

      //           <Tab eventKey="components-listing" title="Components Listing">
      //             {isPipLoading ? (
      //               <Spinner />
      //             ) : (
      //               <PipComponentsListing
      //                 prodId={productNumber}
      //                 data={compListData}
      //               />
      //             )}
      //           </Tab>

      //           <Tab eventKey="packing-information" title="Packing Information">
      //             {areOtherTabsLoading ? (
      //               <Spinner />
      //             ) : (
      //               <PipPackingInformation data={packingInfoData} />
      //             )}
      //           </Tab>

      //           <Tab
      //             eventKey="global-information-local-description"
      //             title="Local Description"
      //           >
      //             {isGITabsLoading ? (
      //               <Spinner />
      //             ) : (
      //               <PipLocalDescription
      //                 data={globalInfo}
      //                 submitChanges={submitChanges}
      //                 savePending={infoSavePending}
      //                 setSavePending={setInfoSavePending}
      //               />
      //             )}
      //           </Tab>

      //           <Tab eventKey="global-information-barcode" title="Barcode">
      //             <PipBarcode
      //               data={barcodes}
      //               barcodeTypes={barcodeTypes}
      //               productNumber={productNumber}
      //               submitChanges={submitChanges}
      //               savePending={barcodeSavePending}
      //               setSavePending={setBarcodeSavePending}
      //             />
      //           </Tab>
      //         </Tabs>
      //       )}
      //     </div>
      //   </div>
      // )}
      pipLayout === "tabs" ? (
        <PipTabsLayout
          prodInfoData={prodInfoData}
          compListData={compListData}
          packingInfoData={packingInfoData}
          relatedItemsData={relatedItemsData}
          isPipLoading={isPipLoading}
          areOtherTabsLoading={areOtherTabsLoading}
          isGITabsLoading={isGITabsLoading}
          productNumber={productNumber}
          globalInfo={globalInfo}
          submitChanges={submitChanges}
          infoSavePending={infoSavePending}
          setInfoSavePending={setInfoSavePending}
          barcodes={barcodes}
          barcodeTypes={barcodeTypes}
          barcodeSavePending={barcodeSavePending}
          setBarcodeSavePending={setBarcodeSavePending}
        />
      ) : (
        <PipListLayout
          prodInfoData={prodInfoData}
          compListData={compListData}
          packingInfoData={packingInfoData}
          relatedItemsData={relatedItemsData}
          isPipLoading={isPipLoading}
          areOtherTabsLoading={areOtherTabsLoading}
          isGITabsLoading={isGITabsLoading}
          productNumber={productNumber}
          globalInfo={globalInfo}
          submitChanges={submitChanges}
          infoSavePending={infoSavePending}
          setInfoSavePending={setInfoSavePending}
          barcodes={barcodes}
          barcodeTypes={barcodeTypes}
          barcodeSavePending={barcodeSavePending}
          setBarcodeSavePending={setBarcodeSavePending}
        />
      )}
    </>
  );
};

export default ProductInformationPage;
