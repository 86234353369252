import { useContext, useEffect, useMemo } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import OptionFormatter from "./OptionFormatter";
import useAxios from "../utils/useAxios";

import {
  storeLastFiltersAndResults,
  booleanOptions,
  capacityUnitsOfMeasureOptions,
  lifecycleStatusOptions,
  yesOrNoOptions,
} from "../helpers";
import { ExpandableSection } from "../routes/skus/components/expandable-section";
import { useDatacatalogContext } from "../context/DatacatalogContext";
import {
  formatDatacalogIntoOption,
  getDatacatalog,
} from "../helpers/datacatalog.helper";
import { Predicates } from "../libraries/predicates/predicates";
import {
  formatGlobalProductIntoOption,
  getGlobalProduct,
} from "../helpers/global-product.helper";
import useGlobalProduct, {
  fetchGlobalProduct,
} from "../hooks/useGlobalProduct";
import { formatTccIntoOption, getTcc } from "../helpers/tcc.helper";
import useTcc, { fetchTcc } from "../hooks/useTcc";
import {
  formatDecorTechIntoOption,
  getDecorTechs,
} from "../helpers/decor-tech.helper";
import { fetchDecorTech } from "../hooks/useDecorTech";
import { useMoldContext } from "../context/MoldContext";
import { useNonMoldContext } from "../context/NonMoldContext";
import { useMaterialContext } from "../context/MaterialContext";
import { useColorContext } from "../context/ColorContext";
import { useDecorationTechContext } from "../context/DecorationTechContext";
import { fetchMold } from "../hooks/useMold";
import { fetchNonMold } from "../hooks/useNonMold";
import { fetchMaterial } from "../hooks/useMaterial";
import { fetchColor } from "../hooks/useColor";
import { useBuyerContext } from "../context/BuyerContext";
import { useSupplierCountryContext } from "../context/SupplierCountryContext";
import useDesign, { fetchDesign } from "../hooks/useDesign";
import { formatDesignIntoOption, getDesigns } from "../helpers/design.helper";
import { formatBrandIntoOption, getBrands } from "../helpers/brand.helper";
import useBrand from "../hooks/useBrand";
import {
  formatTpsTypeIntoOption,
  getTpsTypes,
} from "../helpers/tps-type.helper";
import useTpsType from "../hooks/useTpsType";
import useLicensedProperty, {
  fetchLicensedProperty,
} from "../hooks/useLicensedProperty";
import {
  formatFlavorScentIntoOption,
  getFlavorScents,
} from "../helpers/flavor-scent.helper";
import {
  formatLicensorIntoOption,
  getLicensors,
} from "../helpers/licensor.helper";
import {
  formatLicensedPropertyIntoOption,
  getLicensedPropertys,
} from "../helpers/licensed-property.helper";
import {
  formatChannelIntoOption,
  getChannels,
} from "../helpers/channel.helper";
import {
  formatMarketingMaterialIntoOption,
  getMarketingMaterials,
} from "../helpers/marketing-material.helper";
import {
  DebounceContext,
  DebounceContextType,
} from "../context/DebounceContext";
import { Tooltip } from "react-tooltip";
import { PMS_PREFIX } from "../types/persistent-page-filters";
import { useChannelContext } from "context/ChannelContext";
import { useMarketingMaterialContext } from "context/MarketingMaterialContext";
import { useFlavorScentContext } from "context/FlavorScentContext";
import { useLicensorContext } from "context/LicensorContext";
import { usePmsSearchStore } from "context/PmsSearchContext";
import { isStateEqualToInitialState } from "states/pms-filters/Reducer";
import { PmsSearchActions } from "states/pms-filters";
import { getMolds } from "helpers/mold.helper";
import { getMaterials } from "helpers/material.helper";
import { formatColorIntoOption, getColors } from "helpers/color.helper";
import {
  formatSupplierCountryIntoOption,
  getSupplierCountries,
} from "helpers/supplier-country.helper";
import { formatBuyerIntoOption, getBuyers } from "helpers/buyer.helper";
import { PmsSearchState } from "states/pms-filters/data.type";
import {
  buildPmsDropdownFiltersBasedOnLocalStorageValues,
  buildPmsSearchDropdownFiltersObject,
  buildPmsSearchFilterObject,
  buildPmsTextFiltersBasedOnLocalStorage,
} from "helpers/pms-search.helper";
import { useLinePlanningStatusConditionsContext } from "context/LinePlanningStatusConditionsContext";
import { getLinePlanningStatusOptions } from "helpers/line-planning-status.helper";
import {
  LIFECYCLE_STATUS_LABEL,
  PRODUCTION_STATUS_LABEL,
  SALES_STATUS_LABEL,
} from "constants/labels";

const PMSFilters = ({ filterProducts }: { filterProducts: any }) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);
  const { state, dispatch } = usePmsSearchStore();

  const buttonDisabled = useMemo(() => {
    return isStateEqualToInitialState(state);
  }, [state]);

  const { datacatalog, isLoading: isDatacatalogLoading } =
    useDatacatalogContext();

  const { data: globalProducts, isLoading: isGlobalProductsLoading } =
    useGlobalProduct({
      category: state.category?.id,
      sub_category: state.subCategory?.id,
      product_line: state.productLine?.id,
    });

  const { data: tccs, isLoading: isTccsLoading } = useTcc({
    category: state.category?.id,
    sub_category: state.subCategory?.id,
    product_line: state.productLine?.id,
    global_product: state.globalProduct?.id,
  });

  const { data: moldsFetched, isLoading: isMoldsLoading } = useMoldContext();
  const { data: nonMoldedComponentsFetched, isLoading: isNonMoldsLoading } =
    useNonMoldContext();
  const { data: materialsFetched, isLoading: isMaterialsLoading } =
    useMaterialContext();
  const { data: colorsFetched, isLoading: isColorsLoading } = useColorContext();
  const { data: decoTechs, isLoading: isDecoTechsLoading } =
    useDecorationTechContext();
  const { data: designs, isLoading: isDesignsLoading } = useDesign({
    decorationTechniqueId: String(state.decoTech?.id ?? ""),
  });
  const { data: buyers, isLoading: isBuyersLoading } = useBuyerContext();
  const { data: supplierCountries, isLoading: isSupplierCountriesLoading } =
    useSupplierCountryContext();
  const { data: brands, isLoading: isBrandsLoading } = useBrand({});
  const { data: tpsTypes, isLoading: isTpsTypesLoading } = useTpsType({});
  const { data: marketingMaterials, isLoading: isMarketingMaterialsLoading } =
    useMarketingMaterialContext();
  const { data: flavorScents, isLoading: isFlavorScentsLoading } =
    useFlavorScentContext();
  const { data: channels, isLoading: isChannelsLoading } = useChannelContext();
  const { data: licensors, isLoading: isLicensorsLoading } =
    useLicensorContext();
  const { data: licensedProperties, isLoading: isLicensedPropertiesLoading } =
    useLicensedProperty({
      licensorId: String(state.licensor?.id?.toString() ?? ""),
    });
  const {
    data: linePlanningStatusConditions,
    isLoading: isLinePlanningStatusConditionsLoading,
  } = useLinePlanningStatusConditionsContext();

  const globalProductOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchGlobalProduct({
      category: state.category?.id,
      sub_category: state.subCategory?.id,
      product_line: state.productLine?.id,
      search,
      axios,
    });

    callback(
      getGlobalProduct(response, {
        subCategory: state.subCategory?.id,
        productLine: state.productLine?.id,
        id: state.globalProduct?.id,
      }),
    );
  };

  const tccOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchTcc({
      category: state.category?.id,
      sub_category: state.subCategory?.id,
      product_line: state.productLine?.id,
      global_product: state.globalProduct?.id,
      search,
      axios,
    });
    callback(getTcc(response));
  };

  const decoTechOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchDecorTech({
      search,
      axios,
    });
    callback(getDecorTechs(response));
  };

  const designOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs);
    const response = await fetchDesign({
      decorationTechniqueId: String(String(state.decoTech?.id ?? "")),
      search,
      axios,
    });
    callback(getDesigns(response));
  };

  const tccArtworkOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs);
    const response = await fetchDesign({
      decorationTechniqueId: String(""),
      search,
      axios,
    });
    callback(getDesigns(response));
  };

  const licensedPropertiesOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3)
      return getDesigns(designs);
    const response = await fetchLicensedProperty({
      licensorId: String(String(state.licensor?.id?.toString() ?? "")),
      search,
      axios,
    });
    callback(getLicensedPropertys(response));
  };

  const loadMolds = async (input: string, callback: any) => {
    let options: any = getMolds(moldsFetched);
    if (input.length > 2) {
      const response = await fetchMold({ search: input, axios });
      options = getMolds(response);
    }
    callback(options);
  };
  const loadNonMoldedComponents = async (input: string, callback: any) => {
    let options: any = getMolds(nonMoldedComponentsFetched);
    if (input.length > 2) {
      const response = await fetchNonMold({ search: input, axios });
      options = getMolds(response);
    }
    callback(options);
  };
  const loadMaterial = async (input: string, callback: any) => {
    let options: any = getMaterials(materialsFetched);
    if (input.length > 2) {
      const response = await fetchMaterial({ search: input, axios });
      options = getMaterials(response);
    }
    callback(options);
  };
  const loadColors = async (input: string, callback: any) => {
    let options: any = getColors(colorsFetched);
    if (input.length > 2) {
      const response = await fetchColor({ search: input, axios });
      options = getColors(response);
    }
    callback(options);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const dropdownFilters = buildPmsSearchDropdownFiltersObject(state);
    const filterObject = buildPmsSearchFilterObject(state);
    filterProducts(filterObject, dropdownFilters);
  };

  useEffect(() => {
    const textFilters: Partial<PmsSearchState> =
      buildPmsTextFiltersBasedOnLocalStorage();

    const dropdownFilters: Partial<PmsSearchState> =
      buildPmsDropdownFiltersBasedOnLocalStorageValues();

    dispatch(
      PmsSearchActions.setFiltersFromStorage({
        ...textFilters,
        ...dropdownFilters,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAllFilters = () => {
    dispatch(PmsSearchActions.resetAllData());
    storeLastFiltersAndResults({}, {}, PMS_PREFIX);
  };

  return (
    <>
      <form className="gx-3 gy-2" onSubmit={handleSubmit}>
        <div className="row form-row align-items-end">
          <div className="col-lg form-group">
            <label className="form-label">Global Product Code / Name</label>
            <AsyncSelect
              name="globalProduct"
              value={
                state.globalProduct
                  ? formatGlobalProductIntoOption(state.globalProduct)
                  : null
              }
              onChange={(e) =>
                dispatch(PmsSearchActions.setGlobalProduct(e?.value ?? null))
              }
              defaultOptions={getGlobalProduct(globalProducts, {
                subCategory: state.subCategory?.id,
                productLine: state.productLine?.id,
                id: state.globalProduct?.id,
              })}
              loadOptions={(input, callback) => {
                searchDebounce(
                  globalProductOptions,
                  input,
                  getGlobalProduct(globalProducts, {
                    subCategory: state.subCategory?.id,
                    productLine: state.productLine?.id,
                    id: state.globalProduct?.id,
                  }),
                  callback,
                );
              }}
              formatOptionLabel={OptionFormatter}
              isLoading={isGlobalProductsLoading}
              isClearable
              isSearchable
              placeholder="(min 3 characters)"
              classNamePrefix="react-select"
            />
          </div>
          <div className="col-lg-3 form-group">
            <label className="form-label">Category</label>
            <Select
              options={getDatacatalog(datacatalog, { type: "Category" })}
              onChange={(e) =>
                dispatch(PmsSearchActions.setCategory(e?.value ?? null))
              }
              formatOptionLabel={OptionFormatter}
              value={
                state.category
                  ? formatDatacalogIntoOption(state.category)
                  : null
              }
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDatacatalogLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg-3 form-group">
            <label className="form-label">Sub-Category</label>
            <Select
              options={getDatacatalog(datacatalog, {
                type: "Sub-Category",
                categoryId: state.category?.id,
              })}
              onChange={(e) =>
                dispatch(PmsSearchActions.setSubCategory(e?.value ?? null))
              }
              formatOptionLabel={OptionFormatter}
              value={
                state.subCategory
                  ? formatDatacalogIntoOption(state.subCategory)
                  : null
              }
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDatacatalogLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg-3 form-group">
            <label className="form-label">Product Line</label>
            <Select
              options={getDatacatalog(datacatalog, { type: "Product Line" })}
              onChange={(e) =>
                dispatch(PmsSearchActions.setProductLine(e?.value ?? null))
              }
              value={
                state.productLine
                  ? formatDatacalogIntoOption(state.productLine)
                  : null
              }
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDatacatalogLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row align-items-end">
          <div className="col-lg form-group product-number-col">
            <label className="form-label">SKU nº</label>
            <div className="d-flex align-items-center">
              <span className="mr-2">11 </span>
              <input
                onChange={(e) =>
                  dispatch(PmsSearchActions.setProductNumber(e.target.value))
                }
                value={state.productNumber}
                minLength={3}
                placeholder="(min 3 digits)"
                maxLength={6}
                type="text"
                className="form-control d-inline"
              />
            </div>
          </div>

          <div className="col-lg form-group">
            <label className="form-label">SKU Description</label>
            <input
              onChange={(e) =>
                dispatch(PmsSearchActions.setProductDescription(e.target.value))
              }
              value={state.productDescription}
              placeholder="(min 3 characters)"
              minLength={3}
              maxLength={50}
              type="text"
              className="form-control"
            />
          </div>

          <div className="col-lg-6 form-group">
            <label className="form-label">TCC Code / Name</label>
            <AsyncSelect
              name="tcc"
              value={state.tcc ? formatTccIntoOption(state.tcc) : null}
              onChange={(e) =>
                dispatch(PmsSearchActions.setTcc(e?.value ?? null))
              }
              defaultOptions={getTcc(tccs)}
              loadOptions={(input, callback) => {
                searchDebounce(tccOptions, input, getTcc(tccs), callback);
              }}
              formatOptionLabel={OptionFormatter}
              isLoading={isTccsLoading}
              isClearable
              isSearchable
              placeholder="(min 3 characters)"
              classNamePrefix="react-select"
            />
          </div>
        </div>

        <div className="row form-row align-items-end">
          <div className="col-lg form-group">
            <label className="form-label">Molded Components </label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadMolds,
                  input,
                  getMolds(moldsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={getMolds(moldsFetched)}
              onChange={(e) =>
                dispatch(
                  PmsSearchActions.setMolds(
                    e.map((moldOption) => moldOption.value),
                  ),
                )
              }
              value={getMolds(state.molds ?? [])}
              formatOptionLabel={OptionFormatter}
              isOptionDisabled={() =>
                Predicates.isNotNullAndNotUndefined(state.molds) &&
                state.molds.length >= 5
              }
              isMulti
              classNamePrefix="react-select"
              placeholder="(min 3 characters, up to 5 molded components)"
              isClearable
              isLoading={isMoldsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">Non-Molded Components</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadNonMoldedComponents,
                  input,
                  getMolds(nonMoldedComponentsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={getMolds(nonMoldedComponentsFetched)}
              onChange={(e) =>
                dispatch(
                  PmsSearchActions.setNonMolds(
                    e.map((nonMoldOption) => nonMoldOption.value),
                  ),
                )
              }
              value={getMolds(state.nonMolds ?? [])}
              formatOptionLabel={OptionFormatter}
              isOptionDisabled={() =>
                Predicates.isNotNullAndNotUndefined(state.nonMolds) &&
                state.nonMolds.length >= 5
              }
              isMulti
              classNamePrefix="react-select"
              placeholder="(min 3 characters, up to 5 non-molded components)"
              isClearable
              isLoading={isNonMoldsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">Component Material</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadMaterial,
                  input,
                  getMaterials(materialsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={getMaterials(materialsFetched)}
              onChange={(e) =>
                dispatch(
                  PmsSearchActions.setMaterials(
                    e.map((materialOption) => materialOption.value),
                  ),
                )
              }
              value={getMaterials(state.materials ?? [])}
              isOptionDisabled={() =>
                Predicates.isNotNullAndNotUndefined(state.materials) &&
                state.materials.length >= 5
              }
              isMulti
              placeholder="(min 3 characters, up to 5 materials)"
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isMaterialsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row align-items-end">
          <div className="col-lg form-group">
            <label className="form-label">Component Color</label>
            <AsyncSelect
              cacheOptions
              loadOptions={(input, callback) => {
                searchDebounce(
                  loadColors,
                  input,
                  getColors(colorsFetched) ?? [],
                  callback,
                );
              }}
              defaultOptions={getColors(colorsFetched)}
              onChange={(e) =>
                dispatch(
                  PmsSearchActions.setColors(
                    e.map((colorOption) => colorOption.value),
                  ),
                )
              }
              value={getColors(state.colors ?? [])}
              isOptionDisabled={() =>
                Predicates.isNotNullAndNotUndefined(state.colors) &&
                state.colors.length >= 5
              }
              isMulti
              placeholder="(min 3 characters, up to 5 colors)"
              formatOptionLabel={OptionFormatter}
              classNamePrefix="react-select"
              isClearable
              isLoading={isColorsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">
              Component Decoration Technology
            </label>
            <AsyncSelect
              loadOptions={(input, callback) => {
                searchDebounce(
                  decoTechOptions,
                  input,
                  getDecorTechs(decoTechs),
                  callback,
                );
              }}
              defaultOptions={getDecorTechs(decoTechs)}
              onChange={(e) =>
                dispatch(PmsSearchActions.setDecoTech(e?.value ?? null))
              }
              formatOptionLabel={OptionFormatter}
              value={formatDecorTechIntoOption(state.decoTech)}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDecoTechsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-lg form-group">
            <label className="form-label">Component Artwork</label>
            <AsyncSelect
              loadOptions={(input, callback) => {
                searchDebounce(
                  designOptions,
                  input,
                  getDesigns(designs),
                  callback,
                );
              }}
              defaultOptions={getDesigns(designs)}
              onChange={(e) =>
                dispatch(PmsSearchActions.setArtwork(e?.value ?? null))
              }
              formatOptionLabel={OptionFormatter}
              value={formatDesignIntoOption(state.artwork)}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isDesignsLoading}
              isDisabled={Predicates.isNullOrUndefined(state.decoTech)}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row align-items-end">
          <div className="col-md-4 form-group">
            <label className="form-label">{PRODUCTION_STATUS_LABEL}</label>
            <Select
              onChange={(e) =>
                dispatch(PmsSearchActions.setProductionStatus(e))
              }
              formatOptionLabel={OptionFormatter}
              value={state.productionLifecycleStatus}
              options={lifecycleStatusOptions}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-md-4 form-group">
            <label className="form-label">{SALES_STATUS_LABEL}</label>
            <Select
              onChange={(e) => dispatch(PmsSearchActions.setSalesStatus(e))}
              formatOptionLabel={OptionFormatter}
              value={state.salesLifecycleStatus}
              options={lifecycleStatusOptions}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="col-md-4 form-group">
            <label className="form-label">{LIFECYCLE_STATUS_LABEL}</label>
            <Select
              onChange={(e) =>
                dispatch(PmsSearchActions.setLinePlanningStatus(e))
              }
              formatOptionLabel={OptionFormatter}
              value={state.linePlanningStatus}
              options={getLinePlanningStatusOptions(
                linePlanningStatusConditions,
              )}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isLinePlanningStatusConditionsLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <ExpandableSection
          sectionId="advancedFields"
          title="Advanced Search Fields"
          className="mt-1"
        >
          <ExpandableSection
            className="mb-2"
            sectionId="GP"
            title="Global Product Attributes"
          >
            <div className="form-row form-group d-flex justify-content-start mb-0">
              <div className="col-2 form-group">
                <label className="form-label">Always On</label>
                <Select
                  options={booleanOptions}
                  onChange={(e) => dispatch(PmsSearchActions.setAlwaysOn(e))}
                  formatOptionLabel={OptionFormatter}
                  value={state.alwaysOn}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Brand</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setBrand(e?.value ?? null))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={formatBrandIntoOption(state.brand)}
                  options={getBrands(brands)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isBrandsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group ">
                <label className="form-label">Product Capacity Value</label>
                <input
                  onChange={(e) =>
                    dispatch(
                      PmsSearchActions.setProductCapacity(e.target.value),
                    )
                  }
                  value={state.productCapacity}
                  type="text"
                  className="form-control d-inline"
                />
              </div>

              <div className="col-lg form-group ">
                <label className="form-label">
                  Product Capacity Unit of Measure
                </label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setProductCapacityUom(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.productCapacityUom}
                  options={capacityUnitsOfMeasureOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="form-row form-group d-flex justify-content-start mb-0">
              <div className="col-md-4 form-group">
                <label className="form-label">{PRODUCTION_STATUS_LABEL}</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setGpProductionStatus(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.gpProductionLifecycleStatus}
                  options={lifecycleStatusOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-4 form-group">
                <label className="form-label">{SALES_STATUS_LABEL}</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setGpSalesStatus(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.gpSalesLifecycleStatus}
                  options={lifecycleStatusOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-4 form-group">
                <label className="form-label">{LIFECYCLE_STATUS_LABEL}</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setGpLinePlanningStatus(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.gpLinePlanningStatus}
                  options={getLinePlanningStatusOptions(
                    linePlanningStatusConditions,
                  )}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isLinePlanningStatusConditionsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </ExpandableSection>

          <ExpandableSection
            className="mb-2"
            sectionId="TCC"
            title="TCC Attributes"
          >
            <div className="row form-row mb-0">
              <div className="col-md-3 form-group">
                <label className="form-label">Product Color</label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      loadColors,
                      input,
                      getColors(colorsFetched) ?? [],
                      callback,
                    );
                  }}
                  defaultOptions={getColors(colorsFetched)}
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setProductColor(e?.value ?? null))
                  }
                  value={formatColorIntoOption(state.productColor)}
                  placeholder="(min 3 characters)"
                  formatOptionLabel={OptionFormatter}
                  classNamePrefix="react-select"
                  isClearable
                  isLoading={colorsFetched.length == 0}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Material (Marketing) </label>
                <Select
                  onChange={(e) =>
                    dispatch(
                      PmsSearchActions.setMarketingMaterial(e?.value ?? null),
                    )
                  }
                  formatOptionLabel={OptionFormatter}
                  value={formatMarketingMaterialIntoOption(
                    state.marketingMaterial,
                  )}
                  options={getMarketingMaterials(marketingMaterials)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isMarketingMaterialsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Artwork </label>
                <AsyncSelect
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      tccArtworkOptions,
                      input,
                      getDesigns(designs),
                      callback,
                    );
                  }}
                  defaultOptions={getDesigns(designs)}
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setTccArtwork(e?.value ?? null))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={formatDesignIntoOption(state.tccArtwork)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isDesignsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Channel</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setChannel(e?.value ?? null))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={formatChannelIntoOption(state.channel)}
                  options={getChannels(channels)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isChannelsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="row form-row mb-0">
              <div className="col-lg form-group">
                <label className="form-label">
                  No of Pieces in Selling Unit (Sets)
                </label>
                <div className="d-flex align-items-center">
                  <Select
                    options={[
                      { label: "=", value: "pieces_selling_units" },
                      { label: ">", value: "pieces_selling_units__gt" },
                      { label: ">=", value: "pieces_selling_units__gte" },
                      { label: "<", value: "pieces_selling_units__lt" },
                      { label: "<=", value: "pieces_selling_units__lte" },
                    ]}
                    onChange={(e) =>
                      dispatch(
                        PmsSearchActions.setPiecesSellingUnitsOperator(e),
                      )
                    }
                    value={state.piecesSellingUnitsOperator}
                    formatOptionLabel={OptionFormatter}
                    classNamePrefix="react-select"
                    className="mr-1 release-date-operator"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  <input
                    onChange={(e) =>
                      dispatch(
                        PmsSearchActions.setPiecesSellingUnits(e.target.value),
                      )
                    }
                    value={state.piecesSellingUnits}
                    type="text"
                    className="form-control flex-grow-1"
                  />
                </div>
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Consumer Facing Name</label>
                <input
                  onChange={(e) =>
                    dispatch(
                      PmsSearchActions.setConsumerFacingName(e.target.value),
                    )
                  }
                  value={state.consumerFacingName}
                  type="text"
                  className="form-control d-inline"
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Licensor </label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setLicensor(e?.value ?? null))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={formatLicensorIntoOption(state.licensor)}
                  options={getLicensors(licensors)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isLicensorsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Licensed Property </label>
                <AsyncSelect
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      licensedPropertiesOptions,
                      input,
                      getLicensedPropertys(licensedProperties),
                      callback,
                    );
                  }}
                  defaultOptions={getLicensedPropertys(licensedProperties)}
                  onChange={(e) =>
                    dispatch(
                      PmsSearchActions.setLicensedProperty(e?.value ?? null),
                    )
                  }
                  formatOptionLabel={OptionFormatter}
                  value={formatLicensedPropertyIntoOption(
                    state.licensedProperty,
                  )}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isLicensedPropertiesLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="row form-row mb-0">
              <div className="col-md-3 form-group">
                <label className="form-label">Royalty Item </label>
                <Select
                  onChange={(e) => dispatch(PmsSearchActions.setRoyaltyItem(e))}
                  formatOptionLabel={OptionFormatter}
                  value={state.royaltyItem}
                  options={booleanOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Flavor/Scent </label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setFlavorScent(e?.value ?? null))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={formatFlavorScentIntoOption(state.flavorScent)}
                  options={getFlavorScents(flavorScents)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isFlavorScentsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">Global Standard Cost</label>
                <input
                  onChange={(e) =>
                    dispatch(
                      PmsSearchActions.setGlobalStandardCost(e.target.value),
                    )
                  }
                  value={state.globalStandardCost}
                  type="text"
                  className="form-control d-inline"
                />
              </div>

              <div className="col-md-3 form-group">
                <Tooltip
                  id="global-msrp"
                  className="tooltip-text-box"
                  place="top-start"
                  noArrow={true}
                />
                <label
                  className="form-label"
                  data-tooltip-id="global-msrp"
                  data-tooltip-html={`<strong>Global Manufacturer´s Suggested Retail Price</strong>`}
                >
                  Global MSRP
                </label>
                <input
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setGlobalMsrp(e.target.value))
                  }
                  value={state.globalMsrp}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            </div>

            <div className="row form-row mb-0">
              <div className="col-md-3 form-group">
                <label className="form-label">UPC 12 Digits US</label>
                <input
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setUpc(e.target.value))
                  }
                  value={state.upc}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
              <div className="col-md-3 form-group">
                <label className="form-label">GTIN 14 Digits RoW</label>
                <input
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setGtin(e.target.value))
                  }
                  value={state.gtin}
                  type="text"
                  className="form-control d-inline"
                />
              </div>
            </div>

            <div className="row form-row mb-0">
              <div className="col-md-3 form-group">
                <label className="form-label">Item Number</label>
                <input
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setItemNumber(e.target.value))
                  }
                  value={state.itemNumber}
                  placeholder="(enter 8 digits)"
                  minLength={8}
                  maxLength={8}
                  type="text"
                  className="form-control d-inline"
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">{PRODUCTION_STATUS_LABEL}</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setTccProductionStatus(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.tccProductionLifecycleStatus}
                  options={lifecycleStatusOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">{SALES_STATUS_LABEL}</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setTccSalesStatus(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.tccSalesLifecycleStatus}
                  options={lifecycleStatusOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-3 form-group">
                <label className="form-label">{LIFECYCLE_STATUS_LABEL}</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setTccLinePlanningStatus(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.tccLinePlanningStatus}
                  options={getLinePlanningStatusOptions(
                    linePlanningStatusConditions,
                  )}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isLinePlanningStatusConditionsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </ExpandableSection>

          <ExpandableSection sectionId="otherFields" title="More Search Fields">
            <div className="row form-row align-items-end mb-0">
              <div className="col-lg form-group">
                <label className="form-label">TPS Product</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setSourcingType(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.sourcingType}
                  options={yesOrNoOptions}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Vendor</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setVendor(e?.value ?? null))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={
                    state.vendor
                      ? formatSupplierCountryIntoOption(state.vendor)
                      : null
                  }
                  options={getSupplierCountries(supplierCountries)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isSupplierCountriesLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">TPS Type</label>
                <Select
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setTpsType(e?.value ?? null))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={
                    state.tpsType
                      ? formatTpsTypeIntoOption(state.tpsType)
                      : null
                  }
                  options={getTpsTypes(tpsTypes)}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isTpsTypesLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Discontinued</label>
                <Select
                  options={yesOrNoOptions}
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setDiscontinued(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.discontinued}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="row form-row align-items-end mb-0">
              <div className="col-lg form-group">
                <label className="form-label">Buyers</label>
                <Select
                  options={getBuyers(buyers)}
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setBuyer(e?.value ?? null))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={
                    state.buyer ? formatBuyerIntoOption(state.buyer) : null
                  }
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isBuyersLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Release date</label>
                <div className="form-inline">
                  <Select
                    options={[
                      { label: "=", value: "release_date" },
                      { label: ">", value: "release_date__gt" },
                      { label: ">=", value: "release_date__gte" },
                      { label: "<", value: "release_date__lt" },
                      { label: "<=", value: "release_date__lte" },
                    ]}
                    onChange={(e) =>
                      dispatch(PmsSearchActions.setReleaseDateOperator(e))
                    }
                    value={state.releaseDateOperator}
                    formatOptionLabel={OptionFormatter}
                    classNamePrefix="react-select"
                    className="mr-1 release-date-operator"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  <input
                    onChange={(e) =>
                      dispatch(PmsSearchActions.setReleaseDate(e.target.value))
                    }
                    value={state.releaseDate}
                    type="date"
                    min="1993-01-01"
                    className="form-control flex-grow-1"
                  />
                </div>
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Release / Supplier Country</label>
                <Select
                  options={getSupplierCountries(supplierCountries)}
                  onChange={(e) =>
                    dispatch(
                      PmsSearchActions.setSupplierCountry(e?.value ?? null),
                    )
                  }
                  formatOptionLabel={OptionFormatter}
                  value={
                    state.supplierCountry
                      ? formatSupplierCountryIntoOption(state.supplierCountry)
                      : null
                  }
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  isLoading={isSupplierCountriesLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-lg form-group">
                <label className="form-label">Stock Reported</label>
                <Select
                  options={yesOrNoOptions}
                  onChange={(e) =>
                    dispatch(PmsSearchActions.setStockReported(e))
                  }
                  formatOptionLabel={OptionFormatter}
                  value={state.stockReported}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </ExpandableSection>
        </ExpandableSection>

        <div className="d-flex gap-3 form-group align-items-end justify-content-end mt-2">
          <input
            disabled={buttonDisabled}
            type="button"
            onClick={clearAllFilters}
            className="btn btn-outline-primary"
            value="Clear Filters"
          />

          <input
            disabled={buttonDisabled}
            type="submit"
            className="btn btn-primary"
            value="Search"
          />
        </div>
      </form>
    </>
  );
};

export default PMSFilters;
