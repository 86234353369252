import { DateTimeFormat } from "libraries/date-time-format/date-time-format";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { ExpandableSection } from "../../../skus/components/expandable-section";
import {
  TccAdditionalFieldsSection,
  TccAttributesSection,
  TccHierarchySection,
} from "../../components/create-tcc-information";
import { TccRegionsGroup } from "../../components/tcc-region-card";
import { formatCountryIntoOption } from "helpers/country.helper";

export type SummaryProps = { isReadOnlyRequest: boolean; isEdit: boolean };

const Summary = ({ isReadOnlyRequest, isEdit }: SummaryProps) => {
  const { state } = useCreateTccStore();
  return (
    <>
      <ExpandableSection
        title={"Request Summary"}
        sectionId={"request-summary"}
        className="mb-2"
        expandedByDefault
      >
        <>
          <div className="row">
            <div className="col form-group">
              <label className="form-label">Request Status</label>
              <input
                value={state.requestStatus}
                className="form-control"
                type="text"
                disabled
              />
            </div>

            <div className="col form-group">
              <label className="form-label">Request nº</label>
              <input
                value={state.requestId}
                className="form-control"
                type="text"
                disabled
              />
            </div>

            <div className="col form-group">
              <label className="form-label">Request Location</label>
              <input
                value={formatCountryIntoOption(state.requestorMarket)?.label}
                className="form-control"
                type="text"
                disabled
              />
            </div>

            <div className="col form-group">
              <label className="form-label">Request Date</label>
              <input
                value={
                  state.requestDate.length > 0
                    ? DateTimeFormat.formatTimestamp(state.requestDate)
                    : ""
                }
                className="form-control"
                type="text"
                disabled
              />
            </div>

            <div className="col form-group">
              <label className="form-label">Requester</label>
              <input
                value={state.requester}
                className="form-control"
                type="text"
                disabled
              />
            </div>
          </div>
        </>
      </ExpandableSection>

      <TccHierarchySection
        isReadOnlyRequest={isReadOnlyRequest}
        isEdit={isEdit}
        readOnly
        className="mb-2"
      />

      <TccAttributesSection readOnly className="mb-2" isEdit={isEdit} />

      <TccAdditionalFieldsSection readOnly className="mb-2" />

      <ExpandableSection
        title={"TCC Data"}
        sectionId={"tcc-values"}
        expandedByDefault
      >
        <TccRegionsGroup
          regionsCompositions={state.tccRegionCompositions}
          readonly
        />
      </ExpandableSection>
    </>
  );
};

export default Summary;
