import { ListingModalRef } from "components/ButtonModal";
import { RefObject } from "react";
import { useCreateTccStore } from "../../../../context/CreateTccContext";
import { CreateTccActions } from "../../../../states/create-tcc";
import TextFieldWithLimit from "components/text-fields/TextFieldWithLimit";

const SubmitConfirmationModal = ({
  onConfirm,
  parentModalRef,
}: {
  onConfirm: (...args: any) => any;
  parentModalRef: RefObject<ListingModalRef>;
}) => {
  const { state, dispatch } = useCreateTccStore();

  const handleSubmitComment = (item: any) => {
    dispatch(CreateTccActions.setSubmitComment(item.target.value));
  };

  return (
    <div className="d-flex flex-column" style={{ minHeight: 250 }}>
      <span className="mt-2 text-center black-text">
        Do you confirm you want the request to be submitted for approval? Once
        submitted{" "}
        <strong>
          the record will be locked and you will not be able to change it.
        </strong>
      </span>
      <br />
      <span className="my-2 text-center black-text">
        Before submit to approval you can leave a useful comment about this TCC
        request <strong>(optional)</strong>
      </span>
      <div className="row">
        <TextFieldWithLimit
          spacing="col-12"
          label="Comment"
          limit={1000}
          value={state.submitComment}
          onChange={handleSubmitComment}
          invalidMessage="The comment must have a maximum of 1000 characters"
        />
      </div>
      <div className="d-flex justify-content-end mt-auto gap-3">
        <input
          type="submit"
          onClick={parentModalRef.current?.closeModal}
          className="btn btn-secondary"
          value={"Cancel"}
        />

        <input
          type="submit"
          onClick={onConfirm}
          className="btn btn-primary"
          value={"Submit for Approval"}
          disabled={state.submitComment.length > 1000}
        />
      </div>
    </div>
  );
};

export default SubmitConfirmationModal;
