import { RefObject, useContext, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import OptionFormatter from "../../../../components/OptionFormatter";
import Spinner from "../../../../components/Spinner";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { getSku } from "../../../../helpers/sku.helper";
import useSku, { fecthSku } from "../../../../hooks/useSku";
import { fetchSkuComposition } from "../../../../hooks/useSkuComposition";
import { Predicates } from "../../../../libraries/predicates/predicates";
import {
  ComponentType,
  ICompositionComponent,
  ISkuComposition,
  Sku,
} from "../../../../types/data.interface";
import useAxios from "../../../../utils/useAxios";
import {
  fillCompositionNodeIds,
  findNodeIndexByNodeId,
  getNodeByNodeId,
} from "../../features/sku-create/sku-create.helper";
import SkuCompositionTree from "../sku-composition-tree/SkuCompositionTree";
import { ListingModalRef } from "components/ButtonModal";

export type CompositionType = "sku" | "component";

export const COMPOSITION_OPTIONS: { [k: string]: CompositionType } = {
  SKU: "sku",
  COMPONENT: "component",
};

export interface EditCompositionParams {
  compositionEditType: CompositionType;
  compositionEditObject: any;
}

const MAX_DEPTH_ALLOWED = 3;

const AddSkuCompositionModal = ({
  parentModalRef,
  handleAddSku,
  handleAddComponents,
  editModalParams,
  handleCloseModal,
  canAddSku,
  confirmLabel,
  informativeMessage,
}: {
  parentModalRef: RefObject<ListingModalRef>;
  handleAddSku: (sku: ISkuComposition | null, nodeId: number) => void;
  handleAddComponents: (
    moldedComponents: ICompositionComponent[],
    nonMoldedComponents: ICompositionComponent[],
    nodeId: number,
    isNewComponent: boolean,
  ) => void;
  editModalParams?: EditCompositionParams | null;
  handleCloseModal?: () => void;
  canAddSku?: boolean;
  confirmLabel: string;
  informativeMessage?: string;
}) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);
  const [optionSelected, setOptionSelected] = useState<CompositionType>("sku");
  const { data: skus, isLoading: isSkusLoading } = useSku({});

  const [skuSelected, setSkuSelected] = useState<{
    label: string;
    value: Sku;
  } | null>(null);
  const [isLoadingComposition, setIsLoadingComposition] =
    useState<boolean>(false);

  const [component, setComponent] = useState<ICompositionComponent | null>(
    null,
  );
  const [skuComposition, setSkuComposition] = useState<ISkuComposition | null>(
    null,
  );
  const [shouldIncludeSku, setShouldIncludeSku] = useState<boolean>(
    canAddSku ?? true,
  );
  const [compositionToSave, setCompositionToSave] =
    useState<ISkuComposition | null>(null);

  const cancelButtonLabel = "Cancel";

  const addComponentButtonDisabled =
    !component?.mold_id ||
    !component?.material_id ||
    !component?.color_id ||
    (component?.nr_pieces ?? 0) <= 0
      ? true
      : false;

  const addSkuButtonDisabled = editModalParams
    ? shouldIncludeSku ||
      (Predicates.isNotNullAndNotUndefined(compositionToSave) &&
        compositionToSave.molded_components.length === 0 &&
        compositionToSave.non_molded_components.length === 0)
    : Predicates.isNullOrUndefined(compositionToSave) ||
      (compositionToSave.molded_components.length === 0 &&
        compositionToSave.non_molded_components.length === 0);

  const loadSkuOptions = async (search: string, callback: any) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fecthSku({
      search,
      axios,
    });
    callback(getSku(response));
  };

  const addFakeLayerAndSetComposition = (composition: ISkuComposition) => {
    setSkuComposition({
      id: "",
      name: "",
      skus: [composition],
      molded_components: [],
      non_molded_components: [],
    });
  };

  const handleSkuSelected = async (e: any) => {
    if (e) {
      setIsLoadingComposition(true);
      setSkuSelected(e);
      try {
        const formattedSkuId: string = e.value.id.replace(/^0+/, "");
        const retrievedComposition = await fetchSkuComposition({
          id: formattedSkuId.length === 0 ? "0" : formattedSkuId,
          axios,
        });
        const compositionWithNodeIds: ISkuComposition =
          fillCompositionNodeIds(retrievedComposition);
        addFakeLayerAndSetComposition({ ...compositionWithNodeIds });

        if (
          (canAddSku ?? true) ||
          compositionWithNodeIds.molded_components.length > 0 ||
          compositionWithNodeIds.non_molded_components.length > 0
        ) {
          setCompositionToSave({ ...compositionWithNodeIds });
        }

        setIsLoadingComposition(false);
      } catch (err) {
        console.log(err);
        setIsLoadingComposition(false);
      }
    } else {
      setSkuSelected(null);
      setSkuComposition(null);
      setCompositionToSave(null);
    }
  };

  const getCompositionDepth = (
    composition: ISkuComposition,
    currentDepth: number,
  ) => {
    let depthArray: number[] = [];
    if (composition.skus.length === 0) {
      return currentDepth;
    } else {
      composition.skus.forEach((sku: ISkuComposition) => {
        depthArray.push(getCompositionDepth(sku, currentDepth + 1));
      });

      return Math.max(...depthArray);
    }
  };

  const handleAdditionToComposition = () => {
    const nodeId: number = editModalParams
      ? editModalParams.compositionEditObject.node_id
      : -1;

    if (optionSelected === COMPOSITION_OPTIONS.SKU && skuComposition) {
      if (compositionToSave) {
        if (shouldIncludeSku) {
          const compositionDepth: number = getCompositionDepth(
            compositionToSave,
            1,
          );

          if (compositionDepth + 1 > MAX_DEPTH_ALLOWED) {
            toast.error(
              "This SKU exceeds the maximum level of hierarchy allowed",
            );
          } else {
            handleAddSku(compositionToSave, nodeId);
          }
        } else {
          handleAddSku(null, nodeId);
          handleAddComponents(
            compositionToSave.molded_components,
            compositionToSave.non_molded_components,
            -1,
            true,
          );
        }
      }
    }
    // else if (optionSelected === COMPOSITION_OPTIONS.COMPONENT && component) {
    //   const componentQuantity: number =
    //     component.quantity > 0 ? component.quantity : 1;
    //   if (component.type === "NonMolded") {
    //     handleAddComponents(
    //       [],
    //       [{ ...component, quantity: componentQuantity, type: "NonMolded" }],
    //       nodeId,
    //     );
    //   } else {
    //     handleAddComponents(
    //       [{ ...component, quantity: componentQuantity, type: "Molded" }],
    //       [],
    //       nodeId,
    //     );
    //   }
    // }
  };

  const manageSkuInclusion = (isToInclude: boolean, nodeId: number) => {
    setShouldIncludeSku(isToInclude);
  };

  const manageComponentInclusion = (isToInclude: boolean, nodeId: number) => {
    const compositionWithouFakeLayer: ISkuComposition | null =
      skuComposition?.skus.at(0) ?? null;
    if (compositionWithouFakeLayer && compositionToSave) {
      const componentToManage: ICompositionComponent | undefined =
        getNodeByNodeId(
          nodeId,
          compositionWithouFakeLayer,
        ) as ICompositionComponent;
      const componentType: ComponentType = componentToManage.type;
      const componentsList: ICompositionComponent[] =
        componentType === "Molded"
          ? [...compositionToSave.molded_components]
          : [...compositionToSave.non_molded_components];

      if (isToInclude) {
        componentsList.push(componentToManage);
      } else {
        componentsList.splice(findNodeIndexByNodeId(nodeId, componentsList), 1);
      }

      if (compositionToSave && componentType === "Molded") {
        setCompositionToSave({
          ...compositionToSave,
          molded_components: componentsList,
        });
      } else if (compositionToSave && componentType === "NonMolded") {
        setCompositionToSave({
          ...compositionToSave,
          non_molded_components: componentsList,
        });
      }
    }
  };

  const assertComponentIsIncluded = (
    componentType: ComponentType,
    nodeId: number,
  ) => {
    if (compositionToSave) {
      const componentsList: ICompositionComponent[] =
        componentType === "Molded"
          ? compositionToSave.molded_components
          : compositionToSave.non_molded_components;
      return findNodeIndexByNodeId(nodeId, componentsList) !== -1;
    } else {
      return shouldIncludeSku;
    }
  };

  useEffect(() => {
    // setOptionSelected(
    //   editModalParams
    //     ? editModalParams.compositionEditType
    //     : COMPOSITION_OPTIONS.SKU,
    // );

    if (
      editModalParams &&
      editModalParams.compositionEditType === COMPOSITION_OPTIONS.SKU
    ) {
      addFakeLayerAndSetComposition({
        ...editModalParams.compositionEditObject,
      });
      setCompositionToSave({ ...editModalParams.compositionEditObject });
    }
    // else if (
    //   editModalParams &&
    //   editModalParams.compositionEditType === COMPOSITION_OPTIONS.COMPONENT
    // ) {
    //   setComponent(editModalParams.compositionEditObject);
    // }
  }, []);

  useEffect(() => {
    const compositionWithouFakeLayer: ISkuComposition | null =
      skuComposition?.skus.at(0) ?? null;
    if (compositionWithouFakeLayer) {
      setCompositionToSave({ ...compositionWithouFakeLayer });
    } else {
      setCompositionToSave(null);
    }
  }, [shouldIncludeSku]);

  return (
    <div className="p-3 d-flex flex-column" style={{ minHeight: 450 }}>
      {!editModalParams && (
        <div className="row form-row mb-2">
          {/* <form
            className="d-flex justify-content-between align-items-center col-md mr-3"
            style={{ maxWidth: 290 }}
          > */}
          {/* <div className="radio">
              <label className="mr-2 mb-0">Search SKU</label>
              <input
                type="radio"
                onChange={() => setOptionSelected(COMPOSITION_OPTIONS.SKU)}
                value={COMPOSITION_OPTIONS.SKU}
                checked={optionSelected === COMPOSITION_OPTIONS.SKU}
              />
            </div> */}

          {/* <div className="radio">
              <label className="mr-2 mb-0">Add Components</label>
              <input
                type="radio"
                onChange={() =>
                  setOptionSelected(COMPOSITION_OPTIONS.COMPONENT)
                }
                value={COMPOSITION_OPTIONS.COMPONENT}
                checked={optionSelected === COMPOSITION_OPTIONS.COMPONENT}
              />
            </div> */}
          {/* </form> */}

          {optionSelected === COMPOSITION_OPTIONS.SKU && (
            <div className="d-flex align-items-center mb-2">
              <span className="mr-2 black-text">SKU: 11 </span>
              <AsyncSelect
                className="composition-search-sku-field flex-fill"
                cacheOptions
                loadOptions={(input, callback) => {
                  searchDebounce(loadSkuOptions, input, getSku(skus), callback);
                }}
                defaultOptions={getSku(skus)}
                onChange={handleSkuSelected}
                value={skuSelected}
                placeholder="(min 3 digits)"
                formatOptionLabel={OptionFormatter}
                classNamePrefix="react-select"
                isClearable
                isLoading={isSkusLoading}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          )}
        </div>
      )}

      {/* {optionSelected === COMPOSITION_OPTIONS.COMPONENT && (
        <ComponentAdditionForm
          component={component}
          setComponent={setComponent}
        />
      )} */}

      {isLoadingComposition ? (
        <Spinner />
      ) : (
        optionSelected === COMPOSITION_OPTIONS.SKU &&
        skuComposition && (
          <>
            {Predicates.isNotNullAndNotUndefinedAndNotEmpty(
              informativeMessage,
            ) && (
              <div className="d-flex justify-content-between mb-2">
                <p className="font-italic">{informativeMessage}</p>
              </div>
            )}

            <SkuCompositionTree
              skuComposition={skuComposition}
              isMainPage={false}
              shouldIncludeSku={shouldIncludeSku}
              manageSkuInclusion={manageSkuInclusion}
              manageComponentInclusion={manageComponentInclusion}
              assertComponentIsIncluded={assertComponentIsIncluded}
              canAddSku={canAddSku}
            />
          </>
        )
      )}

      <div className="d-flex justify-content-end gap-2 mt-auto pb-3">
        <input
          type="button"
          onClick={
            Predicates.isNotNullAndNotUndefined(handleCloseModal)
              ? handleCloseModal
              : parentModalRef.current?.closeModal
          }
          className="btn btn-secondary"
          value={cancelButtonLabel}
        />

        <input
          type="submit"
          onClick={handleAdditionToComposition}
          className="btn btn-primary"
          value={confirmLabel}
          disabled={
            optionSelected === COMPOSITION_OPTIONS.SKU
              ? addSkuButtonDisabled
              : addComponentButtonDisabled
          }
        />
      </div>
    </div>
  );
};

export default AddSkuCompositionModal;
