import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { ISkuComposition } from "../types/data.interface";
import useAxios from "../utils/useAxios";
import { Predicates } from "../libraries/predicates/predicates";

type SearchParams = {
  skuComposition: ISkuComposition;
  shouldCheck: boolean;
};

type SimilarSkusResponse = Array<number>;

export const fetchSimilarSkus = async ({
  skuComposition,
  shouldCheck,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  if (shouldCheck) {
    try {
      const response = await axios.post<SimilarSkusResponse>(
        `/check-similar-skus/`,
        {
          sku_composition: JSON.stringify(skuComposition),
        },
      );

      return Predicates.parsePageableReponseToType(response);
    } catch (err) {
      console.error(err);
    }
    return [];
  } else {
    return [];
  }
};

export default function useSimilarSkus({
  skuComposition,
  shouldCheck,
}: SearchParams) {
  const axios = useAxios();
  const [data, setData] = useState<Array<number>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await fetchSimilarSkus({
        skuComposition,
        shouldCheck,
        axios,
      });

      setData(response);
      setIsLoading(false);
    };

    getData();
  }, [skuComposition, shouldCheck]);

  return { data, isLoading };
}
