import { PRODUCTION_STATUS_LABEL, SALES_STATUS_LABEL } from "constants/labels";

const LinePlanningStatusBreakdownTooltip = (props: any) => {
  return (
    <div className="d-flex flex-column grid-tooltip">
      <span>
        <b>{PRODUCTION_STATUS_LABEL}: </b>
        {props.data.production_lifecycle_status}
      </span>
      <span>
        <b>{SALES_STATUS_LABEL}: </b>
        {props.data.sales_lifecycle_status}
      </span>
    </div>
  );
};

export default LinePlanningStatusBreakdownTooltip;
