import { AgGridReact } from "ag-grid-react";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { PageableTable } from "../../../../components/table";
import { usePageableTable } from "../../../../context/PageableTableContext";
import {
  retrieveValueFromLocalStorageObject,
  saveObjectsToLocalStorage,
} from "../../../../helpers/local-storage.helper";
import { Predicates } from "../../../../libraries/predicates/predicates";
import { PageableTableActions } from "../../../../states/pageable-table";
import { TablePagePersistentTypes } from "../../../../types/persistent-page-filters";
import useTccSearch from "../../../../hooks/useTccSearch";
import {
  DebounceContext,
  DebounceContextType,
} from "../../../../context/DebounceContext";
import { formatDecorTechIntoOption } from "../../../../helpers/decor-tech.helper";
import { formatMarketingMaterialIntoOption } from "../../../../helpers/marketing-material.helper";
import { formatColorIntoOption } from "../../../../helpers/color.helper";
import { formatLicensorIntoOption } from "../../../../helpers/licensor.helper";
import { formatDesignIntoOption } from "../../../../helpers/design.helper";
import { formatTccIntoOption } from "../../../../helpers/tcc.helper";
import { formatGlobalProductIntoOption } from "../../../../helpers/global-product.helper";
import { TccSearchActionLinks } from "../links";
import LinePlanningStatusCellRenderer from "components/cell-renderers/LinePlanningStatusCellRenderer";
import LinePlanningStatusBreakdownTooltip from "components/cell-renderers/LinePlanningStatusBreakdownTooltip";
import { ITooltipParams } from "ag-grid-community";
import { LIFECYCLE_STATUS_LABEL } from "constants/labels";

type SearchTableProp = {
  localStoragePrefix: TablePagePersistentTypes["prefix"];
  checkUnsaved?: boolean;
  showCustomFields?: Array<string>;
  filters?: {
    globalProductId?: number;
    tccId?: number;
    queryString?: string;
  };
  enableAdvancedFilters?: boolean;
  showClearButton?: boolean;
};

const SearchTable = memo(
  ({
    localStoragePrefix,
    filters,
    showCustomFields,
    enableAdvancedFilters = true,
    showClearButton = true,
  }: SearchTableProp) => {
    const [pageSize, setPageSize] = useState(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "pageSize",
        50,
      );
    });
    const gridRef = useRef<AgGridReact>(null);
    const [search, setSearch] = useState(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "search",
        "",
      );
    });
    const [searchToBeSent, setSearchToBeSent] = useState<string>(() => {
      return retrieveValueFromLocalStorageObject<TablePagePersistentTypes>(
        localStoragePrefix,
        "lastTableTopFilters",
        "search",
        "",
      );
    });

    const { debounce } = useContext<DebounceContextType>(DebounceContext);

    const {
      state: { offset, isFirstRender },
      dispatch,
    } = usePageableTable();

    const defaultColDef = useMemo(
      () => ({
        sortingOrder: ["asc" as const, "desc" as const],
        minWidth: 100,
        flex: 1,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
        autoHeight: true,
        suppressMenu: true,
        suppressMovable: true,
        icons: {
          sortAscending: "<i class='fa fa-sort-up'/>",
          sortDescending: "<i class='fa fa-sort-down'/>",
          sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
          filter: "<i class='fa fa-filter'></i>",
        },
      }),
      [],
    );

    const columnDefs = useMemo(
      () => [
        {
          colId: "name",
          headerName: "TCC Code / Name",
          field: "name",
          valueGetter: (params: any) => {
            return formatTccIntoOption(params.data)?.label;
          },
          minWidth: 325,
          flex: 3,
        },
        {
          colId: "global_product",
          headerName: "GP Code / Name",
          field: "global_product",
          valueGetter: (params: any) => {
            return formatGlobalProductIntoOption(params.data.global_product)
              ?.label;
          },
          minWidth: 175,
          flex: 2,
        },
        {
          colId: "product_color",
          headerName: "Product Color",
          field: "product_color",
          minWidth: 150,
          valueGetter: (params: any) => {
            return formatColorIntoOption(params.data.product_color)?.label;
          },
        },
        {
          colId: "marketing_material ",
          headerName: "Material (Marketing)",
          field: "marketing_material ",
          minWidth: 100,
          maxWidth: 120,
          valueGetter: (params: any) => {
            return formatMarketingMaterialIntoOption(
              params.data.marketing_material,
            )?.label;
          },
        },
        {
          colId: "decoration_tech",
          headerName: "Decoration Technique",
          field: "decoration_tech",
          minWidth: 100,
          maxWidth: 120,
          valueGetter: (params: any) => {
            return formatDecorTechIntoOption(params.data.decoration_tech)
              ?.label;
          },
        },
        {
          colId: "artwork",
          headerName: "Artwork",
          field: "artwork",
          minWidth: 100,
          maxWidth: 120,
          valueGetter: (params: any) => {
            return formatDesignIntoOption(params.data.artwork)?.label;
          },
        },
        {
          colId: "licensed_property",
          headerName: "Licensor",
          field: "licensed_property",
          minWidth: 100,
          maxWidth: 120,
          valueGetter: (params: any) => {
            return formatLicensorIntoOption(
              params.data.licensed_property?.licensor,
            )?.label;
          },
        },
        {
          colId: "line_planning_status",
          field: "line_planning_status",
          headerName: `${LIFECYCLE_STATUS_LABEL}`,
          minWidth: 90,
          maxWidth: 110,
          cellRenderer: LinePlanningStatusCellRenderer,
          tooltipComponent: LinePlanningStatusBreakdownTooltip,
          tooltipValueGetter: (p: ITooltipParams) => p.value,
        },
        {
          field: "select",
          headerName: "Actions",
          maxWidth: 130,
          cellRenderer: TccSearchActionLinks,
          cellStyle: { whiteSpace: "normal" },
        },
      ],
      [],
    );

    const { data: tccs, isLoading: isLoading } = useTccSearch({
      limit: pageSize,
      offset,
      queryString: filters?.queryString,
      search: Predicates.isNotNullAndNotUndefinedAndNotEmpty(showCustomFields)
        ? searchToBeSent
        : undefined,
    });

    const tableProp = useMemo(
      () => ({
        gridRef,
        rowData: tccs?.data ?? [],
        columnDefs,
        defaultColDef,
      }),
      [gridRef, tccs],
    );

    const onPageSizeChanged = useCallback((e: any) => {
      setPageSize(Number(e.target.value));
    }, []);

    const handleTableSearch = (elem: any) => {
      setSearch(elem.target.value.toLowerCase().trimStart());
      debounce(setSearchToBeSent, 500)(elem?.target?.value ?? "");
      saveObjectsToLocalStorage<TablePagePersistentTypes>({
        prefix: localStoragePrefix,
        lastTableTopFilters: {
          search: elem.target.value.toLowerCase().trimStart(),
          pageSize: pageSize,
        },
      });
    };

    const clearAllFilters = () => {
      setSearch("");
      setSearchToBeSent("");
      if (Predicates.isNotNullAndNotUndefined(gridRef.current)) {
        gridRef.current.api?.setFilterModel(null);
      }
      saveObjectsToLocalStorage<TablePagePersistentTypes>({
        prefix: localStoragePrefix,
        lastTableFilters: [],
        lastTableTopFilters: {},
      });
    };

    useEffect(() => {
      dispatch(PageableTableActions.setOffset(0));
    }, [filters?.queryString, dispatch]);

    useEffect(() => {
      dispatch(PageableTableActions.setPageSize(pageSize));
      saveObjectsToLocalStorage<TablePagePersistentTypes>({
        prefix: localStoragePrefix,
        lastTableTopFilters: {
          search: search,
          pageSize: Number(pageSize),
        },
      });
    }, [pageSize, dispatch]);

    useEffect(() => {
      if (!isLoading && Predicates.isNotNullAndNotUndefined(tccs))
        dispatch(
          PageableTableActions.setTotal(tccs.data.length > 0 ? tccs?.total : 0),
        );
    }, [isLoading, tccs, dispatch]);

    useEffect(() => {
      dispatch(PageableTableActions.setLoading(isLoading));
    }, [isLoading, dispatch]);

    useEffect(() => {
      if (
        Predicates.isNotNullAndNotUndefinedAndNotEmpty(filters?.queryString) &&
        isFirstRender
      ) {
        dispatch(PageableTableActions.setFirstRender(false));
      }
    }, [filters, isFirstRender, dispatch]);

    return (
      <>
        {enableAdvancedFilters ? (
          <div className="d-flex justify-content-between table-top-container">
            <div className="d-flex align-items-center">
              <label className="d-inline-block">
                Show
                <select
                  onChange={onPageSizeChanged}
                  className="table-top-input"
                  id="page-size"
                  value={pageSize}
                >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">All</option>
                </select>
                entries
              </label>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <div
                id="pp_search_filter"
                className="dataTables_filter table-top-search"
              >
                <label>
                  Search:
                  <input
                    onChange={handleTableSearch}
                    type="search"
                    className="table-top-input"
                    placeholder=""
                    aria-controls="pp_search"
                    value={search}
                  />
                </label>
              </div>

              {showClearButton ? (
                <button
                  className="icon-button ml-3 mr-3"
                  onClick={clearAllFilters}
                  disabled={Predicates.isNullOrUndefinedOrEmpty(search)}
                >
                  <FontAwesomeIcon size="lg" icon={faFilterCircleXmark} />
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        <PageableTable tableProp={tableProp} />
      </>
    );
  },
);

export default SearchTable;
